import Footer from "./footer";
import Header from "./header";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  requestEmpLogin,
  requestEmpJoblist,
  requestDeleteJob,
} from "../Redux/actions";
import WOW from "wowjs";
import ManageAccount from "../Employee/manageAccount";
import Breadcrumbs from "../Section/breadcrumbsSection";
import Swal from "sweetalert2";

function ManageJobs(props) {
  useEffect(() => {
    new WOW.WOW().init();
    localStorage.removeItem("link");
  }, []);

  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [emp, setEmp] = useState({});





  useEffect(() => {

    let empLoginData = props.employee.empLoginData;
    // let empLoginData = props.employee.empAddJobData;
    if (empLoginData !== undefined) {
      if (empLoginData?.data?.status == "success") {
        setEmp(empLoginData.data.data);
        props.requestEmpJoblist({
          id: empLoginData.data.data.id,
          token: empLoginData.data.data.token,
        });
      } else {
        localStorage.setItem("link", "/manageJobs");
        navigate("/emplogin");
      }
    } else {
      localStorage.setItem("link", "/manageJobs");
      navigate("/emplogin");
    }
  }, [props.employee.empLoginData]);






  useEffect(() => {

    let empJobListData = props.employee.empJobListData;
    // let empJobListData = props.employee.empAddJobData;


 

    if (empJobListData !== undefined) {
      if (empJobListData.data.status == "success") {
        setData(empJobListData.data.data.jobs);
      }
    }
  }, [props.employee.empJobListData]);

  function deleteItem(id) {
    props.requestDeleteJob({
      id: id,
      token: emp.token,
    });
  }

  useEffect(() => {
    let empDeleteJobData = props.employee.empDeleteJobData;
    if (empDeleteJobData !== undefined) {
      if (empDeleteJobData.data.status === "success") {
        Swal.fire("Good job!", "Job deleted successfully.", "success");
        props.employee.empDeleteJobData = undefined;
        props.requestEmpJoblist({
          id: emp.id,
          token: emp.token,
        });
      } else {
        Swal.fire("Error!", "There is some error in deleting job.", "error");
        props.employee.empDeleteJobData = undefined;
      }
    }
  }, [props.employee.empDeleteJobData]);


  console.log(data, "============ Job list page ===========");
  
  return (
    <>
      <Header />
      <Breadcrumbs title="Manage Jobs" />
      <div class="manage-jobs section common-spacing">
        <div class="container">
          <div class="alerts-inner">
            <div class="row">
              <ManageAccount name="Job" />

              <div class="col-lg-8 col-12">
                {data.length > 0 ? (
                  <div class="job-items">
                    <div class="manage-list">
                      <div class="row">
                        <div class="col-lg-3 col-md-3 col-12">
                          <p>Job Title</p>
                        </div>
                        {/* <div class="col-lg-3 col-md-3 col-12">
                          <p>Shift</p>
                        </div> */}
                        <div class="col-lg-3 col-md-3 col-12">
                          <p>Experience</p>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12">
                          <p>Date Posted</p>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12">
                          <p>Action</p>
                        </div>
                      </div>
                    </div>

                    {data.map((item, index) => {
                      
                      return (
                        <div class="manage-content" key={index}>
                          <div class="row align-items-center justify-content-center">
                            <div class="col-lg-3 col-md-3 col-12">
                              <Link to={`/empViewJob/${item.id}`}>
                                {" "}
                                <h3>{item.title}</h3>
                              </Link>
                            </div>
                            {/* <div class="col-lg-3 col-md-3 col-12">
                              <p>
                                <span class="time">{item.shift_name}</span>
                              </p>
                            </div> */}
                            <div class="col-lg-3 col-md-3 col-12">
                              <p>
                                {/* {item.currency_name} {item.salary_from}-
                                {item.salary_to} */}
                                
                                {item.experience}
                              </p>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12">
                              <p>
                                {new Date(item.created_at).toLocaleDateString()}

                              </p>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12 manageJobIcon">
                              <Link to={`/empViewJob/${item.id}`}>
                                {" "}
                                <button className=" viewicon">
                                  <i class="fa fa-eye"></i>
                                </button>
                              </Link>
                              <Link to={`/editJob/${item.id}`} className="mx-2">
                                <button className="editIcon"> <i class="fa fa-pencil-square-o"></i></button>
                              </Link>
                          
                              <button className="delteicon twoh"
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you really want to delete?"
                                    )
                                  ) {
                                    deleteItem(item.id);
                                  }
                                }}
                              >
                                <i class="fa fa-times"></i>
                              </button>
                       
                          

                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div class="job-items">
                    <h6>No jobs are posted.</h6>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return { employee: state.employee };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { requestEmpLogin, requestEmpJoblist, requestDeleteJob },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ManageJobs);

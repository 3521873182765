import { useEffect } from "react";

import WOW from "wowjs";

function BreadcrumbsSection(props) {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <div class="breadcrumbs overlay">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumbs-content">
              <h1 class="page-title">{props.title}</h1>
              {/* {
                props?.page === "about" && (
                    <p>
                    Hired 60 enables business growth by bringing right talent pool at the doorsteps of the employers & in the process acts as a catalyst in transforming career dreams into realities for the job seekers.
                    In today's digital age all of us are looking at instant solutions to complex challenges. This is true for staffing needs of tech driven new age Enterprises as well. The line manager here more often than not is looking for placement of ever increasing positions as of yesterday. At Hired we enable such instant recruitment process, which is a song for both the hirer and hired. All things being constant, we can enable recruitment in 60 square minutes.
                    </p>

                ) 
              } */}
            </div>
            <ul class="breadcrumb-nav">
              <li>
                <a href="/home">Home</a>
              </li>
              <li>{props.title}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BreadcrumbsSection;

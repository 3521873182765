import Footer from "./footer";
import Header from "./header";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  requestEmpLogin,
  requestEmpJoblist,
  requestCandidateForJob,
} from "../Redux/actions";
import WOW from "wowjs";
import ManageAccount from "../Employee/manageAccount";
import Breadcrumbs from "../Section/breadcrumbsSection";
import Modal from "./Modals/Modal";

function ManageApplication(props) {
  useEffect(() => {
    new WOW.WOW().init();
    localStorage.removeItem("link");
  }, []);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [emp, setEmp] = useState({});

  const [show, setShow] = useState(false);

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  useEffect(() => {
    let empLoginData = props.employee.empLoginData;
    if (empLoginData !== undefined) {
      if (empLoginData?.data?.status == "success") {
        setEmp(empLoginData.data.data);
        props.requestEmpJoblist({
          id: empLoginData.data.data.id,
          token: empLoginData.data.data.token,
        });
      } else {
        localStorage.setItem("link", "/manageApplications");
        navigate("/emplogin");
      }
    } else {
      localStorage.setItem("link", "/manageApplications");
      navigate("/emplogin");
    }
  }, [props.employee.empLoginData]);

  useEffect(() => {
    let empJobListData = props.employee.empJobListData;
    if (empJobListData !== undefined) {
      if (empJobListData.data.status == "success") {
        setData(empJobListData.data.data.jobs);
      }
    }
  }, [props.employee.empJobListData]);

  const handleCandidates = (id) => {
    // props.requestCandidateForJob({
    //   id: id,
    // });
    console.log("first");
  };

  return (
    <>
      <Header />
      <Breadcrumbs title="Manage Applications" />

      <div class="manage-jobs section common-spacing">
        <div class="container">
          <div class="alerts-inner">
            <div class="row">
              <ManageAccount name="Application" />

              <div class="col-lg-8 col-12">
                {data.length > 0 ? ( 
                  <div class="job-items">
                    <div class="manage-list">
                      <div class="row">
                        <div class="col-lg-3 col-md-3 col-12">
                          <p>Job Title</p>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12">
                          <p>Shift</p>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12">
                          <p>Salary</p>
                        </div>
                        <div class="col-lg-3 col-md-3 col-12">
                          <p>Action</p>
                        </div>
                      </div>
                    </div> 

                    {data.map((item, index) => {
                      return (
                        <div class="manage-content" key={index}>
                          <div class="row align-items-center justify-content-center">
                            <div class="col-lg-3 col-md-3 col-12">
                              <Link to={`/empViewApplication/${item.id}`}>
                                {" "}
                                <h3>{item.title}</h3>
                              </Link>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12">
                              <p>
                                <span class="time">{item.shift_name}</span>
                              </p>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12">
                              <p>
                                {item.currency_name} {item.salary_from}-
                                {item.salary_to}
                              </p>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12 d-flex align-baseline">
                              <Link to={`/empViewApplication/${item.id}`} className="manageJobIcon me-2">
                                {" "}
                                <button className="viewicon">
                                  <i class="fa fa-eye"></i>
                                </button>
                              </Link>
                              
                            <Link to = '/ViewCandidateListOnJobType' className="button">
                            <button className="btn seconday-btn small second lowbtn"
                                onClick={() => handleCandidates(item.id)}>
                                Candidates
                              </button>
                            </Link>

                              
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div class="job-items">
                    <h6>No jobs are posted.</h6>
                  </div>
                )}

                
              </div>
              {/* <Modal show={show} hideModal={hideModal}>
                  <p>Modal</p>
                </Modal> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return { employee: state.employee };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { requestEmpLogin, requestCandidateForJob, requestEmpJoblist },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ManageApplication);

import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; // Import the styles for the circular progress bar
import UserProgres from './profileProges';
import { Link } from 'react-router-dom';
import { MdOutlineLocationOn } from 'react-icons/md';
import { BsBriefcase } from 'react-icons/bs';
import { TbReportMoney } from 'react-icons/tb';
import { AiOutlineCalendar } from 'react-icons/ai';
import { IoCallOutline } from 'react-icons/io5';
import { AiOutlineMail } from 'react-icons/ai';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { PiNoteDuotone } from 'react-icons/pi';
import { useEffect } from 'react';
import progresstype from "./progressData";
import { useState } from 'react';
import Checkscore from '../DashboardComponent/checkscore';
import { useRef } from 'react';



function Topdashboard({ 
  profileComplete,
  userPhoto, 
  name,
  lastname ,
  totalpersentage,
  useraddress,
  useraddress2,
  phone, email, 
  experience,
  notice_period,
  pincode,
  city,
  state,
 country, 
 jobtitle,
 current_salary,
  }) 
    
    {

  

const [update, setUpdate]= useState(progresstype);


useEffect(()=> {

    totalpersentage(update);

}, [totalpersentage]);


const first = useRef(null)


    return (
      <>
        <div className='profile-dashboard mb-3'>
        <div className="row ">
       {/* Start Left Side */}
   <div className="col-8 "   >
   <div className="row">
       <div className="col-4">
       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className='img-Profilebar' >
        <CircularProgressbar 
          value={profileComplete}
          text={`${profileComplete}%`}
          styles={buildStyles({textSize: '16px',textColor: '#000',pathColor: '#fff',trailColor: '#fff', strokeLinecap: 'butt',})}
        />

{/* <div className='profileComplete-number'>{`${profileComplete}%`}</div> */}
        <div className='userPhoto' >
        <img src={userPhoto} alt={userPhoto}/>
        </div>
      </div>
    </div>
       </div>
       <div className="col-8">
         <div className="userDetail-dashboard">
            <div className='userName-time'>
            <div className="username"><h2>{name} {lastname}</h2></div>
            <div className="profiel-updateTime">Profile last updated - <span>Yesterday</span></div>
            </div>
            <div className='other-detail'>
                  <div className="row">
                  <div className="col-6 ps-0">
                    <ul className='ps-0 userLocation'>
                       
                        <li><span><BsBriefcase /></span>  Job Title: {jobtitle} </li>
                        <li><span><BsBriefcase /></span>  Experience: {experience} Years</li>
                        <li><span> <TbReportMoney /> </span>  CTC: {current_salary} </li>
                        <li><span><AiOutlineCalendar /></span>Notice Period: {notice_period} Months</li>
                    </ul>
                  </div>
                  <div className="col-6 ps-0">
                  <ul className='ps-0 userContact'>
                        <li><span><IoCallOutline /></span>  <Link to = {`tel:${phone}`}>{phone}</Link> </li>
                        <li><span><AiOutlineMail /></span>  <Link to= {`mailto:${email}`} style={{ wordBreak: "break-all" }}  > {email}</Link> </li> 
                        <li className='d-flex'><span><MdOutlineLocationOn />
                        </span>  <Link to = " " style={{ lineHeight: '1.5' }}>{useraddress} , {useraddress2} , {pincode} , {city} , {state} , {country} </Link>
                         </li>                     
                    </ul>
                  </div>
                  </div>
            </div>
         </div>
       </div>
   </div> 
   </div>
 {/* End Left Side */}

{/* Start Right Side */}
   <div className="col-4">
    <div className="pending-action">
           <div className="pending-list">
            <ul className='ps-0 number'>
            <Link to = "/">
            <li>
                <div className="pendingicon"> <span><IoCallOutline /></span> </div>
                <div className="pending-text">Verify mobile number</div>
                <div className="pending-persentage">  <AiOutlineArrowUp />  10%</div>
                </li>
            </Link>
                
            </ul>
           </div>
           <div className="pending-list">
            <ul className='ps-0 number'>
            <Link to = "/">
            <li>
                <div className="pendingicon"> <span><MdOutlineLocationOn /></span> </div>
                <div className="pending-text">Add preferred location</div>
                <div className="pending-persentage">  <AiOutlineArrowUp />  20%</div>
                </li>
            </Link>
               
            </ul>
           </div>
           <div className="pending-list">
            <ul className='ps-0 number'>
            <Link to = "/">
            <li>
                <div className="pendingicon"> <span><PiNoteDuotone /></span> </div>
                <div className="pending-text">Add resume</div>
                <div className="pending-persentage">  <AiOutlineArrowUp /> 5%</div>
                </li>   
            </Link>
            </ul>
           </div>
           <div class="button text-center"><Link to = " "><button type="submit" class="btn "> Add 5 missing detail</button></Link> </div>
    </div>
   </div>
   {/* End Right Side */}


</div>
</div>

<Checkscore />

   <UserProgres />

      </>
    );
  }
  
  export default Topdashboard;
import Footer from "../Components/footer";
import Header from "../Components/header";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import 
{
  requestFetchCandidates,
  requestFormField,
  requestCountry,
  requestState,
  requestCity,
} from "../Redux/actions";

import WOW from "wowjs";
import ManageAccount from "./manageAccount";
import profile1 from "../images/profile.png";
import Swal from "sweetalert2";
import Breadcrumbs from "../Section/breadcrumbsSection";

import image from "../images/profile.png";
import Searchbar from "../Components/searchbar";

import { IoIosCall } from 'react-icons/io';
import { HiMail } from 'react-icons/hi';
import { IoLocationSharp } from 'react-icons/io5';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; 
import progresstype from "../Components/progressData";
import Checkscore from "../DashboardComponent/checkscore";



function CandidatesList(props) {
  const params = useParams();

  // console.log("brj==", params);

  const [candidates, setcandidates] = useState([]);
  const [pagination, setpagination] = useState({});
  const navigate = useNavigate();
  const [pages, setpages] = useState([]);
  const [page_size, setpage_size] = useState(0);

  const [skill, setskill] = useState([]);
  const [career_level, setcareer_level] = useState([]);

  const [industry, setindustry] = useState([]);
  const [functional_area, setfunctional_area] = useState([]);
  const [currency, setcurrency] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [cities, setcities] = useState([]);
  const [states, setstates] = useState([]);

  useEffect(() => {
    props.requestCountry();
    props.requestState({
      id: 101,
    });
  }, []);

  useEffect(() => {
    let cityData = props.candidate.cityData;
    if (cityData !== undefined) {
      setcities(cityData.data.data.cities);
    }
  }, [props.candidate.cityData]);

  useEffect(() => {
    let stateData = props.candidate.stateData;
    if (stateData !== undefined) {
      if (stateData?.data?.status == "success") {
        setstates(stateData.data.data.states);
      }
    }
  }, [props.candidate.stateData]);

  //
  useEffect(() => {
    props.requestFormField({
      token: props.employee.empLoginData.data.data.token,
    });

    props.requestFetchCandidates({
      data: {
        title: search[0].title ?? search[0].title,
        location: search[0].location ?? search[0].location,
        page: params.page,
        page_size: params.page_size,
      },
    });

    setpage_size(params.page_size);
  }, [params.page, params.page_size]);

  function onChangeState(e) {
    console.log("state0000");
    // setstateId(e.target.value);
    props.requestCity({
      id: e.target.value,
    });
  }

  function paginationChange(v, size) {
    console.log(v);
    setpage_size(size);
    if (size > 0) {
      navigate(v);
    }
  }

  function onChangePageSize(e) {
    console.log("wel");
    setpage_size(e.target.value);
    if (e.target.value > 0) {
      navigate(`/employer/candidates/1/${e.target.value}`);
    }
  }

  console.log("candidates >>>>>> " , page_size);

  const [search, setSearch] = useState([
    {
      title: "",
      location: "",
      designation: "",
      career_level: "",
      employment_type: "",
      functional_area: "",
      industry: "",
      max_exp: "",
      min_exp: "",
      min_sal: "",
      min_sal: "",
      notice_period: "",
    },
  ]);

  const handleInputSearch = (e) => {
    const { name, value } = e.target;
    const list = [...search];
    list[0][name] = value;
    setSearch(list);
  };

  const [show, setShow] = useState(false);

  const showFilterBar = (e) => {
    setShow(true);
  };

  const clearFilterBar = (e) => {
    
    setSearch([
      {
        title: "",
        location: "",
        designation: "",
        career_level: "",
        employment_type: "",
        functional_area: "",
        industry: "",
        max_exp: "",
        min_exp: "",
        min_sal: "",
        min_sal: "",
        notice_period: "",
      },
    ])


  };

  const hideFilterBar = (e) => {
    console.log("Hide");
    setShow(false);
  };

  const searchSubmit = (e) => {
    // console.log(search);

    props.requestFetchCandidates({
      data: {
        designation: search[0].designation,
        title: search[0].title,
        location: search[0].location,
        career_level:
          search[0].career_level == "0" ? "" : search[0].career_level,
        employment_type:
          search[0].employment_type == "0" ? "" : search[0].employment_type,
        functional_area:
          search[0].functional_area == "0" ? "" : search[0].functional_area,
        industry: search[0].industry == "0" ? "" : search[0].industry,
        max_exp: search[0].max_exp,
        max_sal: search[0].max_sal,
        min_exp: search[0].min_exp,
        min_sal: search[0].min_sal,
        notice_period:
          search[0].notice_period == "0" ? "" : search[0].notice_period,
        page: params.page,
        page_size: params.page_size,
      },
    });

    setShow(false);
  };

  useEffect(() => {
    let formfieldData = props.employee.formfieldData;
    if (formfieldData !== undefined) {
      if (formfieldData?.data?.status === "success") 
      {
        setindustry(formfieldData.data.data.industries);
        setLanguages(formfieldData.data.data.languages);
        setskill(formfieldData.data.data.skills);
        setcareer_level(formfieldData.data.data.career_levels);
        setfunctional_area(formfieldData.data.data.functional_areas);
        setcurrency(formfieldData.data.data.currencies);
      }
    }
  }, [props.employee.formfieldData]);

  useEffect(() => {
    let fetchCandidatesData = props.employee?.fetchCandidatesData?.data;
    console.log("fetch==", fetchCandidatesData?.meta);

    if (fetchCandidatesData !== undefined) {
      if (fetchCandidatesData) {
        setcandidates(fetchCandidatesData.data);
        setpagination(fetchCandidatesData.meta);
        if (fetchCandidatesData.meta?.last_page < 5) {
          let array = [];
          Array.from(Array(fetchCandidatesData.meta.last_page), (e, i) => {
            array.push(i + 1);
          });
          setpages(array);
        } else {
          let array = [];
          if (fetchCandidatesData.meta?.current_page <= 3) {
            array.push(1, 2, 3, 4, 5);
            setpages(array);
          } else if (
            fetchCandidatesData.meta?.last_page -
              fetchCandidatesData.meta?.current_page <=
            2
          ) {
            array.push(
              fetchCandidatesData.meta.last_page - 4,
              fetchCandidatesData.meta.last_page - 3,
              fetchCandidatesData.meta.last_page - 2,
              fetchCandidatesData.meta.last_page - 1,
              fetchCandidatesData.meta.last_page
            );
            setpages(array);
          } else {
            array.push(
              Number(fetchCandidatesData.meta?.current_page) - 2,
              Number(fetchCandidatesData.meta?.current_page) - 1,
              fetchCandidatesData.meta?.current_page,
              Number(fetchCandidatesData.meta?.current_page) + 1,
              Number(fetchCandidatesData.meta?.current_page) + 2
            );
            setpages(array);
          }
        }
      }
    }
  }, [props.employee.fetchCandidatesData?.data?.data]);





  const showChild = (id) => {
    console.log(id);
    console.log(document.querySelector("#" + id).style.display);

    if (
      document.querySelector("#" + id).style.display == "none" ||
      document.querySelector("#" + id).style.display == ""
    )
      document.querySelector("#" + id).style.display = "block";
    else document.querySelector("#" + id).style.display = "none";
  };


  const[JobseekerProgress, setJobseekerProgress ] = useState(progresstype);




  return (
    <>
      <Header />
      <Breadcrumbs title="Manage Interviews" />
      <div class="manage-jobs section common-spacing">
        <div class="container">
          <div class="alerts-inner">
            <div class="row">
              <ManageAccount name="Candidates" />

              <div class="col-lg-8 col-12">


              {/* Start JobSeeker Score */}
               <div className="jobseekerScore-info"   >
              
                  </div>
            {/* End JobSeeker Score */}

{/* Start Filter Section */}
                {/* <div class="filtersBar">
                <button class="buttonFilter" onClick={showFilterBar}>
                      Filters
                    </button>
                <button class="buttonFilter" onClick={clearFilterBar}>
                      Clear Filter
                    </button>
                </div> */}
              
                <div class="search_candidates">
                  <Searchbar
                    onChangeState={onChangeState}
                    states={states}
                    cities={cities}
                    industry={industry}
                    functional_area={functional_area}
                    career_level={career_level}
                    show={show}
                    hideFilterBar={hideFilterBar}
                    handleInputSearch={handleInputSearch}
                    searchSubmit={searchSubmit}
                    search={search}
                  />

                  <div class="form-group mb-2">
                    <label>Job Title</label>
                    <input
                      class="form-control"
                      type="text"
                      name="designation"
                      value={search[0].designation ?? search[0].designation}
                     
                      onChange={(e) => handleInputSearch(e)}
                      
                    />
                  </div>
                  <div class="form-group mb-2">
                    <label> Professional Skills</label>
                    <input
                      class="form-control"
                      type="text"
                      name="title"
                      value={search[0].title ?? search[0].title}
                      
                      onChange={(e) => handleInputSearch(e)}
                      
                    />
                  </div>
                  <div class="form-group mb-2">
                    <label>Location</label>
                    <input
                      class="form-control"
                      type="text"
                      name="location"
                      value={search[0].location ?? search[0].location}
                      
                      onChange={(e) => handleInputSearch(e)}
                     
                    />
                  </div>
                  <div class="form-group button mb-2">
                    <button class=" btn small"  onClick={searchSubmit} 
                    style={{ padding: "0 14px", height: "2.2rem" }}
                    >
                      Search
                    </button>
                  
                  </div>
                </div>
{/* End Filter Section */}

{/* Start JobSeeker Card */}
<div className="AllJobseeker">

  {
   candidates && candidates.length > 0  && candidates.map((item, index)=> {

      console.log(item)

         var userskills = item.skill1;
          var skillsArray = JSON.parse(userskills);
          
          let skNew = '';


          let skillArr = (educationArray && educationArray.length > 0)  &&  educationArray.map(editem => editem.degree )
          skNew = (skillArr && skillArr.length > 0) && skillArr.join(', ')
          
          // skillsArray && skillsArray.length > 0  &&  skillsArray.map((skitem, i) => {
          
          //   skNew += skitem.skill;
           
          //   if (i < skillsArray.length - 1) {
          //     skNew += ', ';
          //   }
          //   return null; 
          // });
      

         var usereducation = item.education
         var educationArray = JSON.parse(usereducation)

         console.log(educationArray)

         let edNew = '';

        
         let edArr = (educationArray && educationArray.length > 0)  &&  educationArray.map(editem => editem.degree )
          edNew = (edArr && edArr.length > 0) && edArr.join(', ')
       


    {/* console.log("user details >>>>>>>>>>>>>>>>>",  skillsArray.skill) */}

      const child = "child-" + index;
      const img = item.profile
      ? process.env.REACT_APP_API_HOST + item.profile
      : image;

      return (
        <>
           
        <div className="jobSeeker-Card">
    <div className="align-items-stat jobSeeker-contactdetail d-flex justify-content-between ">
      <div className="jobSeekerName">
     
      <a href={`/admin/viewCandidate/${item.id}`}>{" "}<img src={img} alt= {img} /> </a>

      <div className="d-flex gap-2 jobNameType">
      <div>
      <h2 className="mb-1">{item.first_name} {item.last_name} </h2>
      <p> {item.designation}</p>
      
      {/* <p className="">Web Developer</p> */}
{/* <div className="JobSeeker-ph-Email d-flex">
        <Link to = {`tel:${item.phone}`} className="me-2"><span> <IoIosCall/> </span></Link>
        <Link to = {`mailto: ${item.email}`}  className="me-2"><span><HiMail/> </span></Link>
        <Link to= '' ><span><IoLocationSharp/></span> </Link>
        
      </div> */}
      </div>
{/* <div>
<Link to='' className="button"><span className="btn small" >Appointment</span></Link>
</div> */}
      
      </div>

    
      
      {/* <p className="JobSeeker-Location mt-1">
      Job Title
      <div className="JobSeeker-ph-Email d-flex">
        <Link to = {`tel:${item.phone}`} className="me-2"><span> <IoIosCall/> </span></Link>
        <Link to = {`mailto: ${item.email}`}  className="me-2"><span><HiMail/> </span></Link>
        <Link to= '' ><span><IoLocationSharp/></span> </Link>
      </div>
      </p>  */}


      </div>
      <div className="JobSeeker-ph-Email d-flex">
        <div className="jobseekerScore mb-0">
<Checkscore JobseekerPage = "JobseekerPage"/>
</div>
      </div>
    </div>

  <div className="jobSeeker-littlesummery">
    <h5 className="mb-3">
    {item.about}
      {/* Technical Lead with 11+ years of experience in software design and development. Programming knowledge in PHP, Laravel, Nodejs, Graphql, Api  */}
    </h5>
  </div>
    
<div className="align-items-baseline d-flex justify-content-between">
<div className="jobSeeker-detials ">
      <ul className="ps-0">
        <li>
          <span>Experience</span>
        </li>
        <li>
          <span>Education</span> 
          
        </li>
        <li>
          <span>Professional Skills</span>
          
        </li>
        
      </ul>
      <ul>
        <li>
          <span>
         
          {item.experience} Years
          {/* {item.experience} */}
          </span>
        </li>
        <li>
          <span>
          {/* {item.education}  */}
         {edNew}
          </span> 
          
        </li>
        <li>
          <span>
          {skNew} 
          </span>
          
        </li>
      </ul>
    </div>

    <div className="jobSeeker-detials ">
      <ul className="ps-0">
        <li>
          <span>CTC</span>
        </li>
        {/* <li>
          <span>Designation</span> 
          
        </li> */}
        <li>
          <span>Industry</span> 
          
        </li>
        <li>
          <span>Notice Period</span>
          
        </li>
        
      </ul>
      <ul>
        <li>
          <span>
          {item.current_salary}
          {/* {item.functional_area_name} */}

          </span>
        </li>
        <li>  
          <span>
            
              {/* {item.designation} */}
              {item.functional_area_name}
              
            {/* {item.industry_name} */}
          </span> 
          
        </li>
        <li>
          <span>
         {item.notice_period} Months
          {/* {item.designation} */}
          </span>
          
        </li>
      </ul>
    </div>

</div>

    


    


<div className="jobSeekerProgressRecord mt-2">
 <div className="row">
{
  JobseekerProgress?.map((item)=> {
        return (
            <>           
<div className="col px-2">
    
    <div className='additional-progressbar' style={{ width: '50px', height: '50px', position: 'relative' , margin: "0 auto"}}>
                
        <CircularProgressbar  className="multiple-cricularbar thick-stroke"
          value= {item.percentage}
          text={`${item.percentage}%`}
          strokeWidth={12}
          styles={buildStyles({
            textSize: '22px',
            textColor: '#000',
            pathColor: item.pathcolor,
            trailColor: item.trailcolor,
            
          })}
        /> 
</div>

<h5 className='text-center userProgress mt-2'>{item.name} </h5>
    </div>
            </>
        )
    })
}
</div>
</div>


<div className="align-items-center d-flex gap-2 justify-content-between mt-3 Appoint-ViewMore">


<div className="JobSeeker-ph-Email">
<a href = {`tel:${item.phone}`} className="me-2"><span> <IoIosCall/> </span></a>
<a href = {`mailto: ${item.email}`}  className="me-2"><span><HiMail/> </span></a>
<a href= '' ><span><IoLocationSharp/></span> </a>
</div>
 
 <div className="d-flex">
 <div>
<Link to='' className="" style={{ borderRight: "1px solid #b3b3b3" ,  paddingRight: "10px"}}><span className="" >Schedule Appointment</span></Link>
</div>
<div>
<Link to= {`/employer/showCandidate/${item.id}`} className="" style={{ paddingLeft: "10px" }}><span className="" >View More Details</span></Link>
</div>
 </div>



</div>


{/* <div className="JobSeeker-ph-Email d-flex">

        <Link to = {`tel:${item.phone}`} className="me-2"><span> <IoIosCall/> </span></Link>
        <Link to = {`mailto: ${item.email}`}  className="me-2"><span><HiMail/> </span></Link>
        <Link to= '' ><span><IoLocationSharp/></span> </Link>
        <div className="ms-2">
      <Link to='' className="button"><span className="btn small" >Schedule Appointment</span></Link>
      </div>
      </div> */}
     
      {/* <div>
      <Link to='' className="button"><span className="btn small" >View More Details</span></Link>
      </div> */}


  </div>

        </>
      )
    })
  }

  




</div>
{/* End JobSeeker Card */}


{/* 
                <table class="table select-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Notice Period</th>
                      <th>Current CTC</th>
                      <th>Skills</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {candidates.map((item, index) => {
                      const child = "child-" + index;
                      const img = item.profile
                        ? process.env.REACT_APP_API_HOST + item.profile
                        : image;
                      return (
                        <>
                          <tr key={index} className="jobSeekerInfo" 
                          
                          >

                            <td>
                              <button
                                onClick={() => showChild(child)}
                                class="info_button"
                              >+
                                
                              </button>
                            </td>
                            <td>
                              <div class="d-flex gap-2">
                                <a href={`/admin/viewCandidate/${item.id}`}>
                                  {" "}
                                  <img src={img} alt="" />
                                </a>
                                <div>
                                  <a href={`/admin/viewCandidate/${item.id}`}>
                                    {" "}
                                    <h6>
                                      {item.first_name} {item.last_name}
                                    </h6>
                                  </a>
                                  <p>
                                    <i class="fa fa-map-marker"></i>{" "}
                                    {item.city_name}, {item.state_name},{" "}
                                    {item.country_name}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                             {(item.notice_period =="" || item.notice_period==undefined || item.notice_period==null)?'N/A':(item.notice_period=="1"?"Immediate":(item.notice_period=="2"?"1 Month":(item.notice_period=="3"?"2 Month":(item.notice_period=="4"?"3 Month":"")))  ) }
                            </td>
                            <td>
                             {item.current_salary}
                            </td>
                            <td>
                              <h6>
                                <ol>
                                  {typeof item.skill1 == "string" ? (
                                    JSON.parse(item.skill1).map(
                                      (item, index) => {
                                        return (
                                          <li>
                                            {index + 1} : {item.skill}
                                          </li>
                                        );
                                      }
                                    )
                                  ) : (
                                    <h4>--</h4>
                                  )}
                                </ol>
                              </h6>
                             
                            </td>
                            <td>
                              <a href={`/employer/showCandidate/${item.id}`}>
                                <button
                                  class="badge badge-opacity-primary"
                                  style={{ border: "0px" }}
                                >
                                  <i class="fa fa-eye"></i>
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                         
                            <td
                              colspan="6"
                              style={{ border: "0px solid green" }}
                            >
                              <div class="hideChild" id={child}>
                                <div class="childParent mb-4">
                                <div className="button mb-2">
                                    <button class="notification btn">
                                      Send Notification
                                    </button>
                                  </div>
                                  <table class="childTable">
                                    <tr>
                                      <td class="textBold">About</td>
                                      <td>{item.about}</td>
                                      <td className="textBold">Languages</td>
                                      <td>{item.languages_name}</td>
                                    </tr>
                                    <tr>
                                      <td class="textBold">Email</td>
                                      <td>{item.email}</td>
                                      <td className="textBold">Mobile</td>
                                      <td>{item.phone}</td>
                                    </tr>

                                    <tr>
                                      <td class="textBold">Designation</td>
                                      <td>{item.designation}</td>
                                      <td class="textBold">
                                        Immediate Available
                                      </td>
                                      <td>
                                        {item.immediate_available === 1
                                          ? "Yes"
                                          : "No"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="textBold">Career Level</td>
                                      <td>{item.career_level_name}</td>
                                      <td class="textBold">Experience</td>
                                      <td>{item.experience} Years</td>
                                    </tr>

                                    <tr>
                                      <td class="textBold">Functional Area</td>
                                      <td>{item.functional_area_name}</td>
                                      <td class="textBold">Gender</td>
                                      <td>
                                        {item.gender === 1 ? "Male" : "Female"}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td class="textBold">Industry Name</td>
                                      <td>{item.industry_name}</td>
                                      <td class="textBold">Marital Status</td>
                                      <td>{item.marital_status}</td>
                                    </tr>
                                  </table>
                              
                                </div>
                              </div>
                            </td>
                            
                          </tr>

                        </>
                      );
                    })}
                  </tbody>
                </table> */}

                <div class="row">
                  <div class="col-10">
                    <div class="pagination center">
                      <ul class="pagination-list">
                        {pagination?.current_page !== 1 && (
                          <li class="pagination_left_right">
                            <a
                              onClick={() =>
                                paginationChange(
                                  `/employer/candidates/${params.page - 1}/${
                                    params.page_size
                                  }`,
                                  `${params.page_size}`
                                )
                              }
                              href="javascript:void(0);"
                            >
                              <i class="fa fa-long-arrow-left"></i>
                            </a>
                          </li>
                        )}
                        {pages.map((i) => {
                          return pagination?.current_page === i ? (
                            <li class="pagination_left_right">
                              <a
                                href="javascript:void(0);"
                                onClick={() =>
                                  paginationChange(
                                    `/employer/candidates/${i}/${params.page_size}`,
                                    `${params.page_size}`
                                  )
                                }
                              >
                                {i}
                              </a>
                            </li>
                          ) : (
                            <li class="pagination_left_right">
                              <a
                                href="javascript:void(0);"
                                onClick={() =>
                                  paginationChange(
                                    `/employer/candidates/${i}/${params.page_size}`,
                                    `${params.page_size}`
                                  )
                                }
                              >
                                {i}
                              </a>
                            </li>
                          );
                        })}
                        {pagination?.current_page !== pagination?.last_page && (
                          <li class="pagination_left_right">
                            <a
                              href="javascript:void(0);"
                              onClick={() =>
                                paginationChange(
                                  `/employer/candidates/${
                                    Number(params.page) + 1
                                  }/${params.page_size}`,
                                  `${params.page_size}`
                                )
                              }
                            >
                              <i class="fa fa-long-arrow-right"></i>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="pagination center">
                      <form onSubmit={onChangePageSize}>
                        <div class="form-group">
                          <input
                            type="number"
                            class="form-control"
                            placeholder={page_size}
                            name="page_size"
                            value={page_size}
                            onChange={onChangePageSize}
                            style={{ width: "100px" }}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return { employee: state.employee, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestFetchCandidates,
      requestFormField,
      requestCountry,
      requestState,
      requestCity,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CandidatesList);

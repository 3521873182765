import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { userLogout } from "../Redux/actions";

function ManageAccount(props) {
  const navigate = useNavigate();
  function logout() {
    props.userLogout();
    navigate("/home");
  }

  const [show, setShow] = useState(props.from == "submenu" ? true : false);
  const [show2, setShow2] = useState(props.from == "submenu2" ? true : false);

  return (
    <div class="col-lg-4 col-12">
      <div class="dashbord-sidebar">
        <ul>
          <li class="heading">Manage Account </li>
          {/* <li>
            <Link
              to="/empLogo"
              class={`nav-item ${props.name === "emplogo" && "active"} `}
            >
              <i class="fa fa-plus"></i>Employer Logo
            </Link>
          </li> */}

          {/* <li>
            <Link
              to="/empProfile"
              class={`nav-item ${props.name === "empprofile" && "active"} `}
            >
              <i class="fa fa-plus"></i>Employer Profile
            </Link>
          </li> */}

          <li>
            <Link
              to="/postJob"
              class={`nav-item ${props.name === "PostJob" && "active"} `}
            >
             <i class="fa fa-file-o"></i>   Post a Job
            </Link>
          </li>
          <li>
            <Link
              class={`nav-item ${props.name === "Job" && "active"} `}
              to="/manageJobs"
            >
              <i class="fa fa-file-o"></i> Manage Jobs
            </Link>
          </li>

          <li>
          {/* ${show == true ? "showSubmenuStyle" : ""} */}
            <Link
              class={`nav-item ${props.name === "Candidates" && "active"}   `}
              to="/employer/candidates/1/10"
             
            >
              <i class="fa fa-file-o"></i> Smart Match
            </Link>
          </li>

          <li
            // onClick={displayOption}
            onClick={() => setShow((prev) => !prev)}
            class={`nav-item ${props.name === "Options77" && "active"} `}
            
            
          >
            <Link to="#"  style={{ backgroundColor: `${show == true ? "#015e9a" : ""}`,   color: `${show == true ? "#fff" : ""}`  }}  >
              <i class="fa fa-file-o"></i> Job Seeker Report
            </Link>
          </li>
          <li>
            <div
              class="collapse-subitem ml-3"
              id="form-elements"
              style={{ display: `${show == true ? "block" : "none"}` }}
            >
              
              <ul className="pl-0 ">
                <li>
                  <Link class={`nav-item ${ props.name === "preassesments" && "active" } `} to="/preassesments" >
                    <i class="fa fa-handshake-o"></i>Pre-Emp Assessment
                  </Link>
                </li>
                <li>
                  <Link
                    class={`nav-item ${
                      props.name === "jobseekerinterviewreports" && "active"
                    } `}
                    to="/jobseekerInterviewReports"
                  >
                    <i class="fa fa-handshake-o"></i>Interview Reports
                  </Link>
                </li>
                {/* <li>
                  <Link   class={`nav-item ${
                      props.name === "videopitchcandidate" && "active"
                    } `} to="#">
                    <i class="fa fa-file-o"></i>Video Pitch
                  </Link>
                </li> */}
                <li>
                  <Link   class={`nav-item ${
                      props.name === "skill-rating" && "active"
                    } `} to="/skill-rating">
                    <i class="fa fa-file-o"></i>Skill Rating
                  </Link>
                </li>
                <li>
                  {/* <Link   class={`nav-item ${z
                      props.name === "WellnessReport" && "active"
                    } `} to="#">
                    <i class="fa fa-file-o"></i>Wellness Report
                  </Link> */}
                </li>
                <li>
                  <Link   class={`nav-item ${ props.name === "referal-check-report" && "active" } `} to="/referal-check-report">
                    <i class="fa fa-file-o"></i>Referral Check Report
                  </Link>
                </li>
              </ul>
            </div>
          </li>

          <li>
            <Link
              class={`nav-item ${props.name === "Application" && "active"} `}
              to="/manageApplications"
            >
              <i class="fa fa-handshake-o"></i> Manage Applications
            </Link>
          </li>
          <li>
            <Link
              class={`nav-item ${props.name === "Interview" && "active"} `}
              to="/interviews"
            >
              <i class="fa fa-black-tie"></i> Interviews
            </Link>
          </li>
          {/* <li>
            <Link
              class={`nav-item ${props.name === "Messages" && "active"} `}
              to="/empMessages"
            >
              <i class="fa fa-comments-o"></i> Messages
            </Link>
          </li> */}
          <li>
            <Link
              class={`nav-item ${props.name === "Settings" && "active"} `}
              to="/empsettings"
            >
              <i class="fa fa-cog"></i> Settings
            </Link>
          </li>
          <li>
            <Link to="/home" onClick={logout}>
              <i class="fa fa-sign-out"></i> Sign Out
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ userLogout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManageAccount);

const progresstype = [
    {
        id: "1",
        name : "Personality Score",
        percentage : "60",
        pathcolor: "#DE166A", 
        trailcolor: "#d6d6d6", 
        childData: {
            "Agreeableness":60,
            "Conscientiousness":50,
            "Extroversion":40,
            "Openness":30,
            "Stress Tolerance":40,
        }
    },
    {
        id: "2",
        name : "Psychometric Test",
        percentage : "60",
        pathcolor: "#FE32D8", 
        trailcolor: "#d6d6d6", 
        childData: {
            "Interests":80,
            "Personality ":40,
            "Aptitude":60,
        }
    },
    {
        id: "3",
        name : "Skill Test",
        percentage : "75",
        pathcolor: "#06B3C9", 
        trailcolor: "#d6d6d6", 
        childData: {
            "Ability or Skill Level":80,
            " Desire or Interest Level":40,
            "Knowledge or Qualifications":50,
        }
    },
    {
        id: "4",
        name : "Wellness Report",
        percentage : "75",
        pathcolor: "#01DBEC",
        trailcolor: "#d6d6d6",
        childData: {
            "Emotional":50,
            "Physical":80,
            "Occupational":40,
            "Social":50,
            "Spiritual":30,
            "Intellectual":20,
            "Environmental":60,
            "Financial": 50,
            
        }
    },
    {
        id: "5",
        name : "Trust Score",
        percentage : "50",
        pathcolor: "#00C26F",
        trailcolor: "#d6d6d6",
        childData: {
            "Benevolence":30,
            "Reliability": 60,
            "Competence": 40,
            "Honesty":  50,
            "Openness": 70,
        }
    },
    
    // {
    //     id: "6",
    //     name : "References ",
    //     percentage : "45",
    //     pathcolor: "#FFC123",
    //     trailcolor: "#d6d6d6",
    //     childData: {
    //         "x":10,
    //         "Y":10,
    //         "z":10,
    //     }
    // },
   
    
];

export default progresstype;

import React, { useState, useRef, useEffect } from 'react';
import { FiUpload } from 'react-icons/fi';

function TrustUploadVideo({ uploadFile, handleRemovefile, handleSavefile }) {
  const fileRef = useRef();
  const [file, setFile] = useState();



  const onFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };
  


  const handleUploadfile = (e) => {
    e.preventDefault();
    fileRef.current.click();
  
  };

  return (
    <>
      {file ? (
        <div className='uploadFile'>
          <p>{file.name}</p>
          <div className="d-flex gap-3 mt-5">
            <div className="button mb-2">
              <button type="button" className="btn" onClick={() => { setFile(null); handleRemovefile(); }}>Remove</button>
            </div>
            <div className="button mb-2">
              <button type="button" className="btn" onClick={() => handleSavefile(file)}>Save</button>
            </div>
          </div>
        </div>
      ) : (
        <div className="uploadFile">
          <input type="file" ref={fileRef} onChange={onFileChange} accept="application/pdf,.doc,.docx" />
          <div className="uploadfile-textBtn fileUploadAlign" >
            <div className="d-flex align-items-center">
     
            <div className=''>
              <FiUpload size={30} color='#0d93e5' />
            </div>

              <div className='mr-2'>
                <p>Upload your Trust Score Report</p>
              </div>






              <div className="button mb-2">
                <button type="button" className="btn small" onClick={handleUploadfile}  
                
          
                >Upload</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TrustUploadVideo;
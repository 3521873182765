import { useEffect } from "react";
import WOW from "wowjs";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ClientLogoSection() {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    slideMargin: 20,
    // autoplay: true,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]


  };



  const clientLogos = [
    {
      img: "assets/images/clients/client1.png"
    },
    {
      img: "assets/images/clients/client2.png"
    },
    {
      img: "assets/images/clients/Infosys.png"
    },
    
    {
      img: "assets/images/clients/client4.png"
    },
    {
      img: "assets/images/clients/client5.png"
    },
    {
      img: "assets/images/clients/google-logo.png"
    },
    {
      img: "assets/images/clients/mahendra.png"
    },
    {
      img: "assets/images/clients/itc.png"
    },
    {
      img: "assets/images/clients/client6.png"
    },
    {
      img: "assets/images/clients/aster.png"
    },
    {
      img: "assets/images/clients/client111.png"
    },
    {
      img: "assets/images/clients/tplay.png"
    },
    {
      img: "assets/images/clients/gokwik.png"
    },
    {
      img: "assets/images/clients/Kotak_Life.png"
    },
    {
      img: "assets/images/clients/STARTEK.png"
    },

  ]


  return (
    <div className="client-logo-section common-spacing">
      <div className="container">
        
      {/* <div class="section-title noline mb-4 ">
        <h2 className="text-center">Hire the Resource in 60 minutes </h2>
      <h4>Be amongst the organizations... way to hire resources</h4>
      </div> */}

      <div class="section-title ">
        <h2 class="wow fadeInUp" data-wow-delay=".4s" >Hire the  <span class="headingHighLight"> Resource </span> in 60 minutes 
        <p>
        Be amongst the organizations... way to hire resources
        </p>
        </h2>
        
      </div>



      <Slider {...settings}>
        {
          clientLogos.map((item) => {
            return (
              <>
              <div className="client_slider" >
            <div className="client-logo">
              <img src= {item.img} alt= {item.img} />
            </div>
             </div>
              </>
            )
          })
        }
          
          {/* Add more divs with different images for other slides */}
        </Slider>
      </div>
    </div>
  );
}
export default ClientLogoSection;

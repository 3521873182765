import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestCountLastweekJob } from "../Redux/actions";
import WOW from "wowjs";
import { FaChevronRight } from "react-icons/fa6";
import { Link } from "react-router-dom";


function AboutUsSection(props) {

  useEffect(() => {
    new WOW.WOW().init();
  }, []);
  const [jobcount, setjobcount] = useState(0);

  useEffect(() => {
    props.requestCountLastweekJob();
  }, []);

  useEffect(() => {
    let lastweekjob = props.candidate.lastWeekJobCount;
    if (lastweekjob !== undefined) {
      if (lastweekjob?.data?.status == "success") {
        setjobcount(lastweekjob.data.data);
      }
    }
  }, [props.candidate.lastWeekJobCount]);

  return (
    <>



{
  props?.page === "about" && (
  
    <section className="section common-spacing">
    <div className="container">
    <div className="row">
     <div className="col-lg-6 col-md-12 col-12 mb-4 mb-lg-0">

     <div class="section-title ">
        <h2 class="wow fadeInUp text-left" data-wow-delay=".4s" >About <span class="headingHighLight "> Us </span> 
        </h2>
      </div>
     {/* <div className="subheadingTwo">
      <h2 >About Us  
        <br />
        <h4>
        Job Seeker and Employer pairing is our experience of hiring.
        </h4>
      </h2>
    
     </div> */}




        <h5 style={{ lineHeight: '1.4' }}>
                    Hired 60 enables business growth by bringing right talent pool at the doorsteps of the employers & in the process acts as a catalyst in transforming career dreams into realities for the job seekers.
                    In today's digital age all of us are looking at instant solutions to complex challenges. This is true for staffing needs of tech driven new age Enterprises as well. The line manager here more often than not is looking for placement of ever increasing positions as of yesterday. At Hired 60 we enable such instant recruitment process, which is a song for both the hirer and hired. All things being constant, we can enable recruitment in 60 square minutes.
                    </h5>
                 </div>


                 <div className="col-lg-6 col-12 aboutSection-imgnew">
                    <img src= {process.env.PUBLIC_URL + "/assets/images/public-newimages/get-hired-aboutImg.png"}  
                  className="img-fluid" alt="" />
                  </div>
     </div>
    </div>
    </section>
  
  )
}

    </>
  );
} 

const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestCountLastweekJob,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AboutUsSection);

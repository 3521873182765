// eslint-disable-next-line no-unused-vars
import React from "react";
import { Link } from "react-router-dom";
import { LiaSearchPlusSolid } from 'react-icons/lia';

function Resumepartner (){
   return (
    <>
       <div className="col-lg-12 col-12">
       <div className="partner-component ">
           <div className="row">
            <div className="col-2  w-auto">
                <div className="partner-icon">
                    <LiaSearchPlusSolid/>
                </div>
            </div>
            <div className="col-10 ps-0">
                <div className="partner-description">
                     <h5 className="mb-2">Do you want your Resume to leave an impression?</h5>
                     <p>Ask for professional advice/help for getting a perfect resume made. <br/> Connect with trusted professional partners.
                     </p>

                     <span className="button ms-2 "><Link to = "" className="btn small"  >Connect</Link></span>

                </div>
            </div>
            {/* <div className="col-3">
                <div className="text-end partner-link"><Link to = "" className="color-theme">Create Video</Link></div>
            </div> */}
           </div>
        </div>
       </div>
    </>
   )
}

export default Resumepartner;

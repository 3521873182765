import Footer from "./footer";
import Header from "./header";
import ManageAccount from "./manageAccount";
import React, { useEffect, useState,useRef } from "react";
import { BsCameraFill } from 'react-icons/bs';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import ReactPlayer from "react-player";
import JoditEditor from "jodit-react";

import {
  requestLogin,
  requestCandidateLogo,
  requestCandidateVideo,
  requestGetCandidate,
} from "../Redux/actions";
import WOW from "wowjs";
import camera from "../images/camera.png";
import img1 from "../images/profile.png";
import Swal from "sweetalert2";
import Breadcrumbs from "../Section/breadcrumbsSection";

const VideoPitch = (props) => {
  useEffect(() => {
    new WOW.WOW().init();
    // window.scrollTo(0, 0);
    localStorage.removeItem("link");
  }, []);

  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [emp, setEmp] = useState({});
  const [img, setimg] = useState("");
  const [video, setvideo] = useState("");
  const [selectedFile, setSelectedFile] = useState();

  const editor2 = useRef(null);

  
  const [content2, setContent2] = useState("");

  const config = {
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "ul",
      "ol",
      "|",
      "center",
      "left",
      "right",
      "justify",
      "|",
      "link",
      "image",
    ],
    uploader: { insertImageAsBase64URI: true },
    removeButtons: ["brush", "file"],
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: false,
  };

  const handleUpdateeditor2 = (event) => {
    // dispatch({ type: PRODUCT_ADD_DATA_REQUEST });
    // dispatch({
    //   type: PRODUCT_ADD_DATA_SUCCESS,
    //   payload: { ...product_add_data,"descriptions":event},
    // });
  };

  useEffect(() => {
    let loginData = props.candidate.loginData;
    if (loginData !== undefined) {
      if (loginData?.data?.status == "success") {
        setEmp(loginData.data.data);
        props.requestGetCandidate({
          id: loginData.data.data.id,
          token: loginData.data.data.token,
        });
      } else {
        localStorage.setItem("link", "/videoresume");
        navigate("/login");
      }
    } else {
      localStorage.setItem("link", "/videoresume");
      navigate("/login");
    }
  }, [props.candidate.loginData]);

  const onSelectFile = (e) => {
    setimg(URL.createObjectURL(e.target.files[0]));
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
    } else {
      setSelectedFile(e.target.files[0]);
    }
  };

  function submitForm(e) {

    e.preventDefault();
    let desp =  editor2.current.value;
    // console.log(desp)

    const formData = new FormData();
    formData.append("profile", selectedFile);
    formData.append("description", desp);
    props.requestCandidateVideo({
      id: emp.id,
      data: formData,
      token: emp.token      
    });    
  }

  useEffect(() => {
    let getCandidateData = props.candidate.getCandidateData;
    if (getCandidateData !== undefined) {
      if (getCandidateData?.data?.status == "success") {
        setData(getCandidateData.data.data);
        if (getCandidateData.data.data.video_description) {

          setContent2(getCandidateData.data.data.video_description);
        }
        if (getCandidateData.data.data.video_resume) {
          setvideo(
            process.env.REACT_APP_API_HOST +
              getCandidateData.data.data.video_resume
          );
        } else {
          setvideo(img1);
        }
      }
    }
  }, [props.candidate.getCandidateData]);

  useEffect(() => {
    let candidatelogo = props.candidate.candidateVideoData;
    if (candidatelogo !== undefined) {
      console.log("cand===",props.candidate);
      if (
        candidatelogo.data != undefined &&
        candidatelogo.data.status == "success"
      ) {
        Swal.fire("Good job!", "Video45 updated Successfully.", "success");
        props.candidate.candidateVideoData = undefined;
        props.requestGetCandidate({
          id: emp.id,
          token: emp.token,
        });
        navigate("/videoresume");
      } else {
        Swal.fire("Error!", "Please select mp4 format for video.", "error");

        props.candidate.candidateVideoData = undefined;
        props.requestGetCandidate({
          id: emp.id,
          token: emp.token,
        });
        navigate("/videoresume");
      }
    }
  }, [props.candidate.candidateVideoData]);

  return (
    <>
      <Header />
      <Breadcrumbs title="Upload Your Video " />
      <div class="resume section">
        <div class="container">
          <div class="resume-inner">
            <div class="row">
              <ManageAccount name="VideoResume" from="submenu" />

              <div class="col-lg-8 col-12">
                <div class="inner-content">
                  <div class="alerts-inner">
                    <div class="row">
                      <div class="col-lg-12 col-12">
                        <div class="job-items">
                          <div class="row">
                            {/* <div class="col-md-4 col-12"></div> */}
                            <div class="col-md-12 col-12">
                              <form class="forms-sample" onSubmit={submitForm}>
                                <div class="form-group">
                                  <label><h2 className="titleDashPage">Upload Your Video &nbsp;&nbsp;</h2>
                                  <div className="cameraicon">
                                     <label for="file-input" className="mb-0"><BsCameraFill/></label></div>
                                  </label>
                                  <br />
                                  <br />
                                  <div
                                    style={{
                                      position: "relative",
                                      left: "0px",
                                      top: "0px",
                                    }}
                                  >
                                   

                                    <ReactPlayer
                                      url={video}
                                      width="100%"
                                      height="100%"
                                      controls={true}
                                    />
                                  
                                    {/* <label for="file-input">
                                      <img
                                        src={camera}
                                        height="35"
                                        width="auto"
                                        style={{
                                          position: "absolute",
                                          top: "-12px",
                                          left: "230px",
                                          backgroundColor: "white",
                                          borderRadius: "25px",
                                          border: "1px solid black",
                                          padding: "3px",
                                        }}
                                      />
                                    </label> */}

                                    <input
                                      id="file-input"
                                      type="file"
                                      style={{ display: "none" }}
                                      name="profile"
                                      onChange={onSelectFile}
                                    />
                                  </div>

                                  <div>
                                    <label>
                                     <h5> Description ( Why should I be hired)&nbsp;&nbsp;</h5>
                                    </label>{" "}
                                    <br />
                                    <JoditEditor
        ref={editor2}
        value={content2}
        config={config}
        onBlur={handleUpdateeditor2}
        // onChange={(newContent) => {}}
      />

                                  </div>
                                </div>
                                <br />
                               <div className="button">
                               <button 
                                  type="submit"
                                  class="btn "
                                
                                >
                                  Save
                                </button>
                               </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestLogin,
      requestCandidateLogo,
      requestCandidateVideo,
      requestGetCandidate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(VideoPitch);

import { useEffect } from "react";

import WOW from "wowjs";
import { Link } from "react-router-dom";

function ApplyProcessSection() {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (


    <>
    <section className="common-spacing bg-SectionClr">
       <div className="container">
        <div className=" justify-content-center row">
          <div className="col-12 col-md-6 col-lg-4 mb-4 ">
          <div class="pxp-services-1-item text-center pxp-animate-icon"><div class="pxp-services-1-item-icon">
            <img decoding="async" src= {process.env.PUBLIC_URL + "/assets/images/public-newimages/service-1-icon-1.png"} alt="I am a candidate" class="" />
              </div><div class="pxp-services-1-item-title">
              Upload Your Resume
                 </div>
              <div class="pxp-services-1-item-text pxp-text-light">
              Upload your resume and other collaterals
              </div>
              <div class="pxp-services-1-item-cta">
              <div class="button  "><a href="#0"> <button type="submit" class="btn ">Upload Resume</button></a></div>
                    </div>
           </div>
          </div>
          {/* End card upload resume  */}
          <div className="col-12 col-md-6 col-lg-4 mb-4 ">
          <div class="pxp-services-1-item text-center pxp-animate-icon"><div class="pxp-services-1-item-icon">
            <img decoding="async" src= {process.env.PUBLIC_URL + "/assets/images/public-newimages/service-1-icon-3.png"} alt="I am a candidate" class="" />
              </div><div class="pxp-services-1-item-title">
              Get Hired By Top Employers
                 </div>
              <div class="pxp-services-1-item-text pxp-text-light">
              Get hired by your Dream Employer to get your dream job
              </div>
              <div class="pxp-services-1-item-cta">
              <div class="button  "><a href="#0"> <button type="submit" class="btn ">Click Here</button></a></div>
                    </div>
           </div>
          </div>
          {/* End card upload resume  */}
        </div>
       </div>
    </section>

    
      {/* Start upload resume and hired by top employer Section hide this section  */}
      <section className="common-spacing bg-SectionClr d-none">
        <div className="container">

          {/* Start Resume Card */}
          <div className="resumeCard mb-5">

            <div className="row">

              <div className="col-12 col-md-12 col-lg-5 bgimgCircle">
                <div className="bgcircleshape">

                </div>

                <img src={process.env.PUBLIC_URL + "/assets/images/testimonial/new-uploadform.png"} alt="" />

              </div>

              <div className="col-12 col-md-12 col-lg-7">

                <div className="section-title leftHeading" >
                  <h2 class="wow fadeInUp" data-wow-delay=".4s">Upload <span className="headingHighLight"> Your</span> Resume</h2>
                </div>
                <h3>Upload your resume and other collaterals</h3>

                <div class="button  "><a href="#0"> <button type="submit" class="btn ">
                  Upload Resume
                </button></a></div>
              </div>
            </div>
          </div>
          {/* End Resume Card */}
          {/* Start Resume Card */}
          <div className="resumeCard">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-7">

                <div className="section-title leftHeading" >
                  <h2 class="wow fadeInUp" data-wow-delay=".4s">Get Hired <span className="headingHighLight"> By Top </span> Employers</h2>
                </div>
                <h3>
                  Get hired by your Dream Employer. To get  your dream job
                </h3>

                <div class="button  "><a href="#0"> <button type="submit" class="btn ">
                  Click Here
                </button></a></div>
              </div>

              <div className="col-12 col-md-12 col-lg-5 bgimgCircle">
                <div className="bgcircleshape two">
                </div>
                <img src={process.env.PUBLIC_URL + " /assets/images/testimonial/hiring-image.png"} alt="" />
              </div>
            </div>
          </div>

          {/* End Resume Card */}

        </div>
      </section>
      {/* End upload resume and hired by top employer Section hide this section  */}

    </>

  );
}
export default ApplyProcessSection;

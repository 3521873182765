import Footer from "./footer";
import Header from "./header";
import ManageAccount from "./manageAccount";
import React, { useEffect, useState } from "react";
import { BsCameraFill } from 'react-icons/bs';
import { AiTwotoneEdit } from 'react-icons/ai';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  requestLogin,
  requestCandidateLogo,
  requestGetCandidate,
} from "../Redux/actions";
import WOW from "wowjs";
import camera from "../images/camera.png";
import img1 from "../images/profile.png";
import Swal from "sweetalert2";
import Breadcrumbs from "../Section/breadcrumbsSection";
import MainTopComponent from "../DashboardComponent/topdashboard";

function Picture(props) {

  useEffect(() => {
    // window.scroll(0, 100)
    new WOW.WOW().init();
    // window.scrollTo(0, 0);
    localStorage.removeItem("link");
  }, []);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [emp, setEmp] = useState({});
  const [img, setimg] = useState("");
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    let loginData = props.candidate.loginData;
    if (loginData !== undefined) {
      if (loginData?.data?.status == "success") {
        setEmp(loginData.data.data);
        props.requestGetCandidate({
          id: loginData.data.data.id,
          token: loginData.data.data.token,
        });
      } else {
        localStorage.setItem("link", "/picture");
        navigate("/login");
      }
    } else {
      localStorage.setItem("link", "/picture");
      navigate("/login");
    }
  }, [props.candidate.loginData]);

  const onSelectFile = (e) => {
    setimg(URL.createObjectURL(e.target.files[0]));
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
    } else {
      setSelectedFile(e.target.files[0]);
    }
  };

  function submitForm(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("profile", selectedFile);

    props.requestCandidateLogo({
      id: emp.id,
      data: formData,
      token: emp.token,
    });
  }

  useEffect(() => {
    let getCandidateData = props.candidate.getCandidateData;
    if (getCandidateData !== undefined) {
      if (getCandidateData?.data?.status == "success") {
        setData(getCandidateData.data.data);
        if (getCandidateData.data.data.profile) {
          setimg(
            process.env.REACT_APP_API_HOST + getCandidateData.data.data.profile
          );
        } else {
          setimg(img1);
        }
      }
    }
  }, [props.candidate.getCandidateData]);

  useEffect(() => {
    let candidatelogo = props.candidate.candidatePictureData;
    if (candidatelogo !== undefined) {
      if (candidatelogo.data.status == "success") {
        Swal.fire(
          "Good job!",
          "Profile picture updated Successfully.",
          "success"
        );
        props.candidate.candidatePictureData = undefined;
        props.requestGetCandidate({
          id: emp.id,
          token: emp.token,
        });
        navigate("/settings");
      } else {
        Swal.fire(
          "Error!",
          "Please select png or jpg or jpeg file for resume.",
          "error"
        );
        props.candidate.candidatePictureData = undefined;
        props.requestGetCandidate({
          id: emp.id,
          token: emp.token,
        });
      }
    }
  }, [props.candidate.candidatePictureData]);



console.log("all data >>>>>>>>>>", data)



  return (
    <>
      <Header />
      <Breadcrumbs title="Profile Picture" />
      <div class="resume section">
        <div class="container">

        <MainTopComponent 
      username = {data.first_name}
      lastname = {data.last_name}
      userimage = {data.profile}
      useraddress = {data.address}
      useraddress2 = {data.address2}
      phone = {data.phone}
      email = {data.email}
      experience = {data.experience}
      notice_period = {data.notice_period}
      pincode = {data.pincode}
      city = {data.city}
      state = {data.state}
      country = {data.country}
      jobtitle = {data.designation}
      current_salary = {data.current_salary}
     
      />

          <div class="resume-inner">
            <div class="row">
              <ManageAccount name="picture" from="submenu" />

              <div class="col-lg-8 col-12">
                <div class="inner-content">
                  <div class="alerts-inner">
                    <div class="row">
                      <div class="col-lg-12 col-12">
                        <div class="job-items">
                          <div class="row">
                            
                            <div class="col-12">
                               <form class="forms-sample" onSubmit={submitForm}>
                                <div class="form-group">
                                <label><h2 className="titleDashPage">Profile &nbsp;&nbsp;</h2> </label>
                                <div>
                                    <div className="empPicture"><img src={img}/>
                                    <div className="cameraicon">
                                    {
                                      img ? (
                                        <label for="file-input" className="mb-0"><AiTwotoneEdit/></label>
                                      ):  <label for="file-input" className="mb-0"><BsCameraFill/></label>
                                        
                                    }
                                    
                                     </div>
                                    </div>
                                    <div>
                                    <input
                                      id="file-input"
                                      type="file"
                                      style={{ display: "none" }}
                                      name="profile"
                                      onChange={onSelectFile}
                                    />
                                 </div>

                                 </div>

                                </div>
                             
                                <div className="button">
                                <button
                                  type="submit"
                                  class="btn btn-primary me-2"
                                  style={{ color: "white" }}
                                >
                                  Save
                                </button>
                                </div>

                                {/* <button
                                  type="submit"
                                  class="btn btn-primary me-2"
                                  style={{ color: "white" }}
                                >
                                  Save
                                </button> */}



                              </form>

                          </div>




                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { requestLogin, requestCandidateLogo, requestGetCandidate },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Picture);

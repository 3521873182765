import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { requestEmpLogin, userLogout } from "../Redux/actions";
import WOW from "wowjs";
import Header from "../Components/header";
import Footer from "../Components/footer";
import Testimonials from "../Section/Testimonials";


function LandingPage(props) {
  useEffect(() => {
    new WOW.WOW().init();
    window.scrollTo(0, 0);
  }, []);
  

  const {
    loginData
  } = useSelector(state=>state.candidate)
  const [emp, setEmp] = useState({});

  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [erroremail, seterroremail] = useState("");
  const [errorpassword, seterrorpassword] = useState("");

  const mystyle = {
    color: "#D10000",
    backgroundColor: "#FFD2D2",
    padding: "3px 10px",
    border: "1px solid red",
    borderRadius: "5px",
    marginTop: "5px",
  };

  function onChangeData(e) {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  function validateEmail() {
    let formIsValid = false;
    if (!data["email"]) {
      formIsValid = false;
      seterroremail("*Enter your E-mail ID.");
    } else if (typeof data["email"] !== "undefined") {
      if (
        !data["email"].match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
      ) {
        formIsValid = false;
        seterroremail("*Please enter valid E-mail ID.");
      } else {
        formIsValid = true;
        seterroremail("");
      }
    } else {
      formIsValid = true;
      seterroremail("");
    }
    return formIsValid;
  }
  function validatePassword() {
    let formIsValid = false;
    if (!data["password"]) {
      seterrorpassword("*Enter your password.");
      formIsValid = false;
    } else if (data["password"].length < 6) {
      seterrorpassword("*Enter atleast 6 character long password.");
      formIsValid = false;
    } else {
      seterrorpassword("");
      formIsValid = true;
    }
    return formIsValid;
  }
  function validateForm() {
    let email = validateEmail();
    let pass = validatePassword();
    let valid = email && pass;
    return valid;
  }

  function onSubmitForm(e) {
    e.preventDefault();
    props.userLogout();
    if (validateForm()) {
      props.requestEmpLogin({
        data: {
          email: data.email,
          password: data.password,
        },
      });
    }
  }

  useEffect(() => {
    let empLoginData = props.employee.empLoginData;
    if (empLoginData !== undefined) {
      if (empLoginData?.data?.status == "success") {
        // if (localStorage.getItem("link")) {
        //   navigate(localStorage.getItem("link"));
        // } else {
        //   navigate("/home");
        // }
        navigate("/home");
      } else {
        seterrorpassword(empLoginData?.data?.message);
      }
    }
  }, [props.employee.empLoginData]);
  return (
    <>
      <div className=" modal form-modal" style={{ display: "block" }}>
        <Header name="Home" />
        <div className="modal-dialog max-width-px-840 position-relative"></div>

        {/* <div className="login-modal-main">
          <div className="row no-gutters">
            <div className="col-lg-4 col-md-3"></div>
            <div className="col-lg-4 col-md-6">
              <div className="row">
                <div className="heading">
                  <h3>Login For Employers</h3>
                  <p>
                    Log in to continue your account <br /> and create new jobs.
                  </p>
                  <br />
                  <br />
                </div>

                <form onSubmit={onSubmitForm}>
                  <div className="form-group">
                    <label for="email" className="label">
                      E-mail
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="example@gmail.com"
                      id="email"
                      name="email"
                      value={data.email}
                      onChange={onChangeData}
                      onBlur={validateEmail}
                    />
                    {erroremail && <div style={mystyle}>{erroremail}</div>}
                  </div>
                  <div className="form-group">
                    <label for="password" className="label">
                      Password
                    </label>
                    <div className="position-relative">
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Enter password"
                        name="password"
                        value={data.password}
                        onChange={onChangeData}
                        onBlur={validatePassword}
                      />
                      {errorpassword && (
                        <div style={mystyle}>{errorpassword}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-group d-flex flex-wrap justify-content-between">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        style={{marginLeft:"10px"}}
                                          type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label className="form-check-label" for="flexCheckDefault">
                        Remember password
                      </label>
                    </div>
                    <a
                      href="/empforgotpass1"
                      className="font-size-3 text-dodger line-height-reset"
                    >
                      Forget Password
                    </a>
                  </div>
                  <div className="form-group mb-8 button">
                    <button className="btn ">Log in</button>
                  </div>
                  <p className="text-center create-new-account">
                    Don’t have an account?{" "}
                    <a href="/empregister">Create a free account</a>
                  </p>
                </form>
              </div>
            </div>
            <div className="col-lg-12"></div>
          </div>
        </div> */}






        <section
          className="project-section  pb-5 hiredResource-Section pt-5"
          style={{
            backgroundImage: `url('/assets/images/testimonial/e-1.png'  )`,
          }}
        >
          <div className="container">
            <div
              className="row align-items-start align-items-md-center"
              style={{
                minHeight: "300px",
              }}
            >
              <div className="col-sm-6 col-12">
                <div className="">
                  <h3 className="newTitle">
                   {/* Hire The Resource That Best Fits Your Needs In 5 Minutes. */}
                   Hire the resource in 60 minutes
                   </h3>
                  <p>
                  Join the growing list of organisation who aspire to hire pre screened and sanitized candidates in an unbelievable time frame of 60 minutes.
                    {/* Finding the right manpower at the right time can help a
                    business grow exponentially. Apart from right age, skills,
                    experience and aptitude, we pre filter the candidates for
                    you on more than 30 dynamic parameters to offer you the best
                    fit. */}
                  </p>
                </div>
                <div className="pt-3 button"  >
                  {
                    loginData?.data?.data?.token
                    ?
                    <></>
                    :
                    <Link to={'/emplogin'} data-wow-delay=".3s"
                    className="btn btn-primary "
                    >
                        Post Your Requirement
                    </Link>
                  }
                </div>
                {/* <div className="pt-3">
                  <button
                    className="btn btn-primary wow fadeInUp"
                    style={{ width: "100%" }}
                    data-wow-delay=".3s"
                  >
                    Search Profiles
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </section>





   <section className="common-spacing floading-appSection">
    <div className="container">
      <div className="align-items-center row">


      <div className="col-md-6 col-12 mobile-section">
      <div className="mobile-floatOne">
      <img src= {process.env.PUBLIC_URL + "/assets/images/testimonial/mob-1.png" }  />
      </div>
      <div className="mobile-floatwo">
      <img src= {process.env.PUBLIC_URL + "/assets/images/public-newimages/mobileimage1.png" }  />
      </div>
      
    </div>



      <div className="col-md-6 col-10 rightside" >
          <h4 className="heading-color newTitle text-white">
                    Join The Growing List Of Organizations Discovering A More Efficient And Productive Way To Hire Resources
                  </h4>
          </div>

     
    

      </div>
    </div>
   

   </section>



        {/* <section className="project-sec  common-spacing">
          <div className="container">
            <div className="row custom-row">
              <div className="col-12 col-lg-7" style={{ 
                justifyContent:'end'
               }}>
                <div className="cutom-border">
                  <h4 className="heading-color newTitle">
                    JOIN THE GROWING LIST OF
                  
                    ORGANIZATIONS
                   
                    DISCOVERING
                 
                    A MORE EFFICIENT AND
                   
                    PRODUCTIVE WAY TO HIRE
                  RESOURCES
                  </h4>
                </div>
              </div>
              <div className="col-12 col-lg-5">
                <div className="img-width">
                  <img src= {process.env.PUBLIC_URL + } "/assets/images/testimonial/mob-1.png" />
                </div>
              </div>
            </div>
          </div>
        </section> */}


        <section className="featured-joblix-block common-spacing">
            <div className="container">
            <div className="section-title"><h2 className="">Fast And Seamless  <span className="headingHighLight"> 
              Talent Acquisition
              </span> With In 60 Minutes</h2>
              
              <p>Finding the right manpower at the right time can help a business grow exponentially. Apart from right age, skills, experience and aptitude, we pre filter the candidates for you on more than 30 dynamic parameters to offer you the best fit.</p></div>


             <div className="align-items-center mt-5 row">
             <div className="col-12 col-lg-6 mb-15 mb-md-30 mb-lg-0">
							<div className="video-box-wrap mb-20 mb-lg-0">
								<div className="video-box ">
                {/* class =  video-box ratio ratio-16x10 */}
                {/* <img src= {process.env.PUBLIC_URL + "/assets/images/testimonial/img-video-dummy.jpg"}  /> */}
                <img src= {process.env.PUBLIC_URL + "/assets/images/testimonial/img-video-dummy.png"}  />
									
									<a href="#" className="button-play"></a>
								</div>
							</div>
						</div>
            {/*  */}

            <div className="col-12 col-lg-6 mb-15 mb-md-30 mb-lg-0">
							<ul className="joblix-list">

								<li>
									<div className="box">
										<div className="icon-box">
                    <i className="fa fa-user" aria-hidden="true"></i>
										</div>
										<div className="textbox">
											<strong className="h5">Pre Screened Profile</strong>
											{/* <p>Sit amet, consectetur adipiscing elit, sed do ei usmod tempor incididunt. Labore et.</p> */}
										</div>
									</div>
								</li>

								<li>
									<div className="box">
										<div className="icon-box">
                    <i className="fa fa-video-camera" aria-hidden="true"></i>
										</div>
										<div className="textbox">
											<strong className="h5">Video Profiles</strong>
									
										</div>
									</div>
								</li>

								<li>
									<div className="box">
										<div className="icon-box">
										<i className="fa fa-cogs" aria-hidden="true"></i>
										</div>
										<div className="textbox">
											<strong className="h5">Innovative Assessment Tools</strong>
									
										</div>
									</div>
								</li>

                <li>
									<div className="box">
										<div className="icon-box">
                    <i className="fa fa-laptop" aria-hidden="true"></i>
										</div>
										<div className="textbox">
											<strong className="h5">Virtual Interview</strong>
									
										</div>
									</div>
								</li>

                <li>
									<div className="box">
										<div className="icon-box">
                    <i className="fa fa-handshake-o" aria-hidden="true"></i>

										</div>
										<div className="textbox">
											<strong className="h5">Quick Recruitment</strong>
									
										</div>
									</div>
								</li>

							</ul>
						</div>

             </div>

              
            
            </div>
          </section>
          {/* End Fast And Seamless Talent Acquisition With In 60 Minutes */}
       

          {/* Start   Perfect Way To Find The */}
     <section className="common-spacing bg-SectionClr perfectway-section">

    <div className="container-fluid">
      <div className="resumeCard shortlistCard ">
      <div className="row">
      <div className="col-12 col-md-12 col-lg-8">
        <div className="section-title leftHeading">
        <h2 className= "pr-3" >
         Perfect Way To Find  <span className="headingHighLight"> The Right Candidate </span> At The Faster Pace</h2>
        </div>
          <h3>
          With the help of technology, human insights and faster  
          Innovative processes, find fully sanitized candidates inclusive of reports like Personality Score, Psychometric Test, Wellness Score, Trust Score, Skill Test and many more.
          </h3>
          <div className="button  ">
            <Link to = "#0"> 
            <button type="submit" className="btn ">Click Here</button>
            </Link>
            </div>
            </div>


            <div className="col-12 col-md-12 col-lg-4 bgimgCircle">
                <div className="bgcircleshape two three">
                <img src={process.env.PUBLIC_URL + " /assets/images/public-newimages/right-candidate.png"} alt="" />
                </div>
                {/* <img src={process.env.PUBLIC_URL + " /assets/images/public-newimages/right-candidate.png"} alt="" /> */}
              </div>

      </div>
      </div>
      
    </div>

   </section>
          {/* End   Perfect Way To Find The */}

          {/* Start  New Perfect Way To Find The */}
          {/* <section className="perfectway-Section">
           <div className="container">
           <div className="align-items-center row">
            <div className="col-12 col-lg-8">
            <div className="section-title leftHeading">
            <h2 className= "pr-3" >
            Perfect Way To Find  <span className="headingHighLight"> The Right Candidate </span> At The Faster Pace</h2>
           </div>
          <p>
          With the help of technology, human insights and faster  
          Innovative processes, find fully sanitized candidates inclusive of reports like Personality Score, Psychometric Test, Wellness Score, Trust Score, Skill Test and many more.
          </p>
            </div>
            <div className="col-12 col-md-4">
            <div class="button text-center "><a href="#0"> <button type="submit" class="btn ">Upload Resume</button></a></div>
            </div>
           </div>
           </div>
          </section> */}
          {/* End  New Perfect Way To Find The */}

          <Testimonials />
          

        <Footer />
    </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return { employee: state.employee };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestEmpLogin, userLogout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);

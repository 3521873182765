import React, { useEffect, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import ManageAccount from "./manageAccount";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  requestLogin,
  requestEmpGetCandidate,
  requestAddResume,
  requestFormField,
  requestCandidateResume,
  requestEditReferences,
  requestEditExperienceCertificates,
  requestEditProfAcadCertificates,
  requestEditAwards,
  requestEditTestimonials,
  requestEditAccolades,
} from "../Redux/actions";
import { useNavigate } from "react-router-dom";
import image from "../images/profile.png";
import WOW from "wowjs";
import Swal from "sweetalert2";
import { Hint } from "react-autocomplete-hint";
import Breadcrumbs from "../Section/breadcrumbsSection";
import MainTopComponent from "../DashboardComponent/topdashboard";

function SupportingDocuments(props) {
  const printRef = React.useRef();
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [data, setData] = useState({});
  const [skill, setskill] = useState([]);
  const [language, setlanguage] = useState([]);
  const [img, setImg] = useState("");
  const [about, setAbout] = useState("");

  const [experience, setExperience] = useState([
    {
      uploadpath: "",
      name: "",
      description: "",
      year: "",
    },
  ]);

  const [
    academic_professional_certificates,
    setAcademic_professional_certificates,
  ] = useState([
    {
      prof_acad_uploadpath: "",
      prof_acad_name: "",
      prof_acad_description: "",
      prof_acad_year: "",
    },
  ]);

  const [
    erroracademic_professional_certificates,
    setErrorAcademic_professional_certificates,
  ] = useState([
    {
      prof_acad_uploadpath: "",
      prof_acad_name: "",
      prof_acad_description: "",
      prof_acad_year: "",
    },
  ]);

  // STATE AND ERRORS FOR AWARDS SECTION

  const [awards_certificates, setAwards_certificates] = useState([
    {
      awards_uploadpath: "",
      awards_name: "",
      awards_description: "",
      awards_year: "",
    },
  ]);

  const [error_awards_certificates, setError_awards_certificates] = useState([
    {
      awards_uploadpath: "",
      awards_name: "",
      awards_description: "",
      awards_year: "",
    },
  ]);

  // STATE AND ERRORS FOR TESTIMONIALs SECTION

  const [testimonials_certificates, setTestimonials_certificates] = useState([
    {
      testimonials_uploadpath: "",
      testimonials_name: "",
      testimonials_description: "",
      testimonials_year: "",
    },
  ]);

  const [error_testimonials_certificates, setError_testimonials_certificates] =
    useState([
      {
        testimonials_uploadpath: "",
        testimonials_name: "",
        testimonials_description: "",
        testimonials_year: "",
      },
    ]);

  // STATE AND ERRORS FOR ACCOLADES SECTION

  const [accolades_certificates, setAccolades_certificates] = useState([
    {
      accolades_uploadpath: "",
      accolades_name: "",
      accolades_description: "",
      accolades_year: "",
    },
  ]);

  const [error_accolades_certificates, setError_accolades_certificates] =
    useState([
      {
        accolades_uploadpath: "",
        accolades_name: "",
        accolades_description: "",
        accolades_year: "",
      },
    ]);

  const [education, setEducation] = useState([
    {
      degree: "",
      university: "",
      start: "",
      leave: "",
      achieve: "",
    },
  ]);
  const [errorabout, setErrorabout] = useState("");
  const [errorskill, setErrorskill] = useState([]);

  const [errorexperience, setErrorexperience] = useState([
    {
      uploadpath: "",
      name: "",
      description: "",
      year: "",
    },
  ]);

  const [medicalreports, setMedicalreports] = useState([
    {
      person_name: "",
      company_name: "",
      email: "",
      phone: "",
    },
  ]);

  const [references, setReferences] = useState([
    {
      person_name: "",
      company_name: "",
      email: "",
      phone: "",
    },
  ]);
  const [errorreferences, setErrorreferences] = useState([
    {
      person_name: "",
      company_name: "",
      email: "",
      phone: "",
    },
  ]);

  const [erroreducation, setErroreducation] = useState([
    {
      degree: "",
      university: "",
      start: "",
      leave: "",
      achieve: "",
    },
  ]);
  const [skills, setSkills] = useState([
    {
      skill: "",
    },
  ]);

  const mystyle = {
    color: "#D10000",
    backgroundColor: "#FFD2D2",
    padding: "3px 10px",
    border: "1px solid red",
    borderRadius: "5px",
    marginTop: "5px",
  };

  useEffect(() => {
    new WOW.WOW().init();
    // window.scrollTo(0, 0);
    localStorage.removeItem("link");
  }, []);

  useEffect(() => {
    let loginData = props.candidate.loginData;
    if (loginData !== undefined) {
      if (loginData?.data?.status == "success") {
        setUser(loginData.data.data);
        props.requestEmpGetCandidate({
          id: loginData.data.data.id,
        });
        props.requestFormField({
          token: loginData.data.data.token,
        });
      } else {
        localStorage.setItem("link", "/addResumeForm");
        navigate("/login");
      }
    } else {
      localStorage.setItem("link", "/addResumeForm");
      navigate("/login");
    }
  }, [props.candidate.loginData]);

  useEffect(() => {
    let empGetCandidateData = props.employee.empGetCandidateData;
    if (empGetCandidateData !== undefined) {
      if (empGetCandidateData?.data?.status == "success") {
        setData(empGetCandidateData.data.data[0]);
        if (empGetCandidateData.data.data[0].skill1) {
          setSkills(JSON.parse(empGetCandidateData.data.data[0].skill1));
        }
        if (empGetCandidateData.data.data[0].education) {
          setEducation(JSON.parse(empGetCandidateData.data.data[0].education));
        }
        if (empGetCandidateData.data.data[0].education === null) {
          erroreducation.push({
            degree: "",
            university: "",
            start: "",
            leave: "",
            achieve: "",
          });
        } else {
          Array.from(
            Array(
              JSON.parse(empGetCandidateData.data.data[0].education)?.length
            ),
            (e, i) => {
              erroreducation.push({
                degree: "",
                university: "",
                start: "",
                leave: "",
                achieve: "",
              });
            }
          );
        }

        if (
          empGetCandidateData.data.data[0].experience_certificates !=
            undefined &&
          empGetCandidateData.data.data[0].experience_certificates != "" &&
          empGetCandidateData.data.data[0].experience_certificates != "null" &&
          empGetCandidateData.data.data[0].experience_certificates != null
        ) {
          setExperience(
            JSON.parse(empGetCandidateData.data.data[0].experience_certificates)
          );
        } else {
          setExperience([
            {
              uploadpath: "",
              name: "",
              description: "",
              year: "",
            },
          ]);
        }
        if (empGetCandidateData.data.data[0].experience_certificates === null) {
          errorexperience.push({
            uploadpath: "",
            name: "",
            description: "",
            year: "",
          });
        } else {
          Array.from(
            Array(
              JSON.parse(
                empGetCandidateData.data.data[0].experience_certificates
              )?.length
            ),
            (e, i) => {
              errorexperience.push({
                uploadpath: "",
                name: "",
                description: "",
                year: "",
              });
            }
          );
        }

        if (
          empGetCandidateData.data.data[0].academic_professional_certificates !=
            undefined &&
          empGetCandidateData.data.data[0].academic_professional_certificates !=
            "" &&
          empGetCandidateData.data.data[0].academic_professional_certificates !=
            "null" &&
          empGetCandidateData.data.data[0].academic_professional_certificates !=
            null
        ) {
          setAcademic_professional_certificates(
            JSON.parse(
              empGetCandidateData.data.data[0]
                .academic_professional_certificates
            )
          );
        } else {
          setAcademic_professional_certificates([
            {
              prof_acad_uploadpath: "",
              prof_acad_name: "",
              prof_acad_description: "",
              prof_acad_year: "",
            },
          ]);
        }

        if (
          empGetCandidateData.data.data[0]
            .academic_professional_certificates === null
        ) {
          erroracademic_professional_certificates.push({
            prof_acad_uploadpath: "",
            prof_acad_name: "",
            prof_acad_description: "",
            prof_acad_year: "",
          });
        } else {
          Array.from(
            Array(
              JSON.parse(
                empGetCandidateData.data.data[0]
                  .academic_professional_certificates
              )?.length
            ),
            (e, i) => {
              erroracademic_professional_certificates.push({
                prof_acad_uploadpath: "",
                prof_acad_name: "",
                prof_acad_description: "",
                prof_acad_year: "",
              });
            }
          );
        }

        //Condition for Awards
        if (empGetCandidateData.data.data[0].awards) {
          if (
            empGetCandidateData.data.data[0].awards != undefined &&
            empGetCandidateData.data.data[0].awards != ""
          )
            setAwards_certificates(
              JSON.parse(empGetCandidateData.data.data[0].awards)
            );
          else {
            setAwards_certificates({
              awards_uploadpath: "",
              awards_name: "",
              awards_description: "",
              awards_year: "",
            });
          }
        }

        if (empGetCandidateData.data.data[0].awards === null) {
          error_awards_certificates.push({
            awards_uploadpath: "",
            awards_name: "",
            awards_description: "",
            awards_year: "",
          });
        } else {
          Array.from(
            Array(JSON.parse(empGetCandidateData.data.data[0].awards)?.length),
            (e, i) => {
              error_awards_certificates.push({
                awards_uploadpath: "",
                awards_name: "",
                awards_description: "",
                awards_year: "",
              });
            }
          );
        }

        //Condition for Testimonials

        if (
          empGetCandidateData.data.data[0].testimonials != undefined &&
          empGetCandidateData.data.data[0].testimonials != "" &&
          empGetCandidateData.data.data[0].testimonials != "null" &&
          empGetCandidateData.data.data[0].testimonials != null
        ) {
          console.log("Testimonials123");
          setTestimonials_certificates(
            JSON.parse(empGetCandidateData.data.data[0].testimonials)
          );
        } else {
          console.log("Testimonials ELSE ");
          setTestimonials_certificates([
            {
              testimonials_uploadpath: "",
              testimonials_name: "",
              testimonials_description: "",
              testimonials_year: "",
            },
          ]);
        }

        if (empGetCandidateData.data.data[0].testimonials === null) {
          error_testimonials_certificates.push({
            testimonials_uploadpath: "",
            testimonials_name: "",
            testimonials_description: "",
            testimonials_year: "",
          });
        } else {
          Array.from(
            Array(
              JSON.parse(empGetCandidateData.data.data[0].testimonials)?.length
            ),
            (e, i) => {
              error_testimonials_certificates.push({
                testimonials_uploadpath: "",
                testimonials_name: "",
                testimonials_description: "",
                testimonials_year: "",
              });
            }
          );
        }
        //Condition for Accolades

        if (
          empGetCandidateData.data.data[0].accolades != undefined &&
          empGetCandidateData.data.data[0].accolades != "" &&
          empGetCandidateData.data.data[0].accolades != "null" &&
          empGetCandidateData.data.data[0].accolades != null
        ) {
          setAccolades_certificates(
            JSON.parse(empGetCandidateData.data.data[0].accolades)
          );
        } else {
          setAccolades_certificates([
            {
              accolades_uploadpath: "",
              accolades_name: "",
              accolades_description: "",
              accolades_year: "",
            },
          ]);
        }

        if (empGetCandidateData.data.data[0].accolades === null) {
          error_accolades_certificates.push({
            accolades_uploadpath: "",
            accolades_name: "",
            accolades_description: "",
            accolades_year: "",
          });
        } else {
          Array.from(
            Array(
              JSON.parse(empGetCandidateData.data.data[0].accolades)?.length
            ),
            (e, i) => {
              error_accolades_certificates.push({
                accolades_uploadpath: "",
                accolades_name: "",
                accolades_description: "",
                accolades_year: "",
              });
            }
          );
        }

        if (empGetCandidateData.data.data[0].cand_references) {
          setReferences(
            JSON.parse(empGetCandidateData.data.data[0].cand_references)
          );
        }

        if (empGetCandidateData.data.data[0].cand_references === null) {
          errorreferences.push({
            person_name: "",
            company_name: "",
            email: "",
            phone: "",
          });
        } else {
          Array.from(
            Array(
              JSON.parse(empGetCandidateData.data.data[0].cand_references)
                ?.length
            ),
            (e, i) => {
              errorreferences.push({
                person_name: "",
                company_name: "",
                email: "",
                phone: "",
              });
            }
          );
        }

        setAbout(empGetCandidateData.data.data[0].about);
        // setlanguage(empGetCandidateData.data.data[0].languages.split(","));
        setImg(
          process.env.REACT_APP_API_HOST +
            empGetCandidateData.data.data[0].profile
        );
      }
    }
  }, [props.employee.empGetCandidateData]);

  useEffect(() => {
    let formfieldData = props.employee.formfieldData;
    if (formfieldData !== undefined) {
      if (formfieldData?.data?.status == "success") {
        formfieldData.data.data.skills.map((a) => skill.push(a.name));
      }
    }
  }, [props.employee.formfieldData]);

  const onChangeAbout = (e) => {
    setAbout(e.target.value);
  };

  const handleInputExperience = (e, index) => {
    const { name, value } = e.target;
    const list = [...experience];
    list[index][name] = value;
    setExperience(list);
  };

  const handleInputReferences = (e, index) => {
    // console.log("References==",references)
    // console.log("index==",index)
    // console.log("e.target",e.target)
    const { name, value } = e.target;

    // console.log(name)

    const list = [...references];
    list[index][name] = value;
    setReferences(list);
  };

  const handleRemoveExperience = (index) => {
    const list = [...experience];
    list.splice(index, 1);
    setExperience(list);
    const errorlist = [...errorexperience];
    errorlist.splice(index, 1);
    setErrorexperience(errorlist);
  };

  const handleRemoveReferences = (index) => {
    const list = [...medicalreports];
    list.splice(index, 1);
    setMedicalreports(list);
    // const errorlist = [...errorreferences];
    // errorlist.splice(index, 1);
    // setErrorreferences(errorlist);
  };
  const handleAddExperience = () => {
    let length = experience.length;
    if (length === 0) {
      setExperience([
        ...experience,
        {
          uploadpath: "",
          name: "",
          description: "",
          year: "",
        },
      ]);
      setErrorexperience([
        ...errorexperience,
        {
          uploadpath: "",
          name: "",
          description: "",
          year: "",
        },
      ]);
    } else if (
      // experience[length - 1].uploadpath === "" ||
      experience[length - 1].name === "" ||
      experience[length - 1].description === "" ||
      experience[length - 1].year === ""
    ) {
      Swal.fire(
        "Error!",
        "After completing previous one, You can add new one.",
        "error"
      );
    } else {
      setExperience([
        ...experience,
        {
          uploadpath: "",
          name: "",
          description: "",
          year: "",
        },
      ]);
      setErrorexperience([
        ...errorexperience,
        {
          uploadpath: "",
          name: "",
          description: "",
          year: "",
        },
      ]);
    }
  };

  const handleAddReferences = () => {
    let length = references.length;

    setMedicalreports([
      ...medicalreports,
      {
        person_name: "",
        company_name: "",
        email: "",
        phone: "",
      },
    ]);

    // if (length === 0) {
    //   setReferences([
    //     ...references,
    //     {
    //       person_name: "",
    //       company_name: "",
    //       email: "",
    //       phone: "",
    //     },
    //   ]);
    //   setErrorreferences([
    //     ...errorreferences,
    //     {
    //       person_name: "",
    //       company_name: "",
    //       email: "",
    //       phone: "",
    //     },
    //   ]);
    // } else if (
    //   references[length - 1].person_name === "" ||
    //   references[length - 1].company_name === "" ||
    //   references[length - 1].email === "" ||
    //   references[length - 1].phone === ""
    // ) {
    //   Swal.fire(
    //     "Error!",
    //     "After completing previous one, You can add new one.",
    //     "error"
    //   );
    // } else {
    //   setReferences([
    //     ...references,
    //     {
    //       person_name: "",
    //       company_name: "",
    //       email: "",
    //       phone: "",
    //     },
    //   ]);
    //   setErrorreferences([
    //     ...errorreferences,
    //     {
    //       person_name: "",
    //       company_name: "",
    //       email: "",
    //       phone: "",
    //     },
    //   ]);
    // }
  };

  const handleInputEducation = (e, index) => {
    const { name, value } = e.target;
    const list = [...education];
    list[index][name] = value;
    setEducation(list);
  };
  const handleRemoveEducation = (index) => {
    const list = [...education];
    list.splice(index, 1);
    setEducation(list);
    const errorlist = [...erroreducation];
    errorlist.splice(index, 1);
    setErroreducation(errorlist);
  };
  const handleAddEducation = () => {
    let length = education.length;
    if (length === 0) {
      setEducation([
        ...education,
        {
          degree: "",
          university: "",
          start: "",
          leave: "",
        },
      ]);
      setErroreducation([
        ...erroreducation,
        {
          degree: "",
          university: "",
          start: "",
          leave: "",
        },
      ]);
    } else if (
      education[length - 1].degree === "" ||
      education[length - 1].university === "" ||
      education[length - 1].start === "" ||
      education[length - 1].leave === "" ||
      education[length - 1].achieve === ""
    ) {
      Swal.fire(
        "Error!",
        "After completing previous one, You can add new one.",
        "error"
      );
    } else {
      setEducation([
        ...education,
        {
          degree: "",
          university: "",
          start: "",
          leave: "",
          achieve: "",
        },
      ]);
      setErroreducation([
        ...erroreducation,
        {
          degree: "",
          university: "",
          start: "",
          leave: "",
          achieve: "",
        },
      ]);
    }
  };

  const handleInputSkills = (e, index) => {
    const { name, value } = e.target;
    const list = [...skills];
    list[index][name] = value;
    setSkills(list);
  };
  const handleRemoveSkills = (index) => {
    const list = [...skills];
    list.splice(index, 1);
    setSkills(list);
    const errorlist = [...errorskill];
    errorlist.splice(index, 1);
    setErrorskill(errorlist);
  };
  const handleAddSkills = () => {
    let length = skills.length;
    if (length === 0) {
      setSkills([
        ...skills,
        {
          skill: "",
        },
      ]);
    } else if (skills[length - 1].skill === "") {
      Swal.fire(
        "Error!",
        "After completing previous one, You can add new one.",
        "error"
      );
    } else {
      setSkills([
        ...skills,
        {
          skill: "",
        },
      ]);
    }
  };

  function validateAbout() {
    let formIsValid = false;
    if (!about) {
      formIsValid = false;
      setErrorabout("*Enter something about you.");
    } else if (typeof about === "undefined") {
      formIsValid = false;
      setErrorabout("*Enter something about you.");
    } else {
      formIsValid = true;
      setErrorabout("");
    }
    return formIsValid;
  }
  function validateSkill(i) {
    let formIsValid = false;
    if (!skills[i].skill) {
      formIsValid = false;
      const list = [...errorskill];
      list[i] = "*Enter skill or delete textbox.";
      setErrorskill(list);
    } else if (typeof skills[i].skill === "undefined") {
      formIsValid = false;
      const list = [...errorskill];
      list[i] = "*Enter skill or delete textbox.";
      setErrorskill(list);
    } else {
      formIsValid = true;
      const list = [...errorskill];
      list[i] = "";
      setErrorskill(list);
    }
    return formIsValid;
  }
  function validateExperienceTitle(i) {
    let formIsValid = false;
    if (!experience[i].title) {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].title = "*Enter title of your experience.";
      setErrorexperience(list);
    } else if (typeof experience[i].title === "undefined") {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].title = "*Enter title of your experience.";
      setErrorexperience(list);
    } else {
      formIsValid = true;
      const list = [...errorexperience];
      list[i].title = "";
      setErrorexperience(list);
    }
    return formIsValid;
  }

  function validateReferencesPersonName(i) {
    // console.log("references", references);
    let formIsValid = false;
    if (!references[i].person_name) {
      formIsValid = false;
      const list = [...errorreferences];
      list[i].person_name = "*Enter person_name of your references.";
      setErrorreferences(list);
    } else if (typeof references[i].person_name === "undefined") {
      formIsValid = false;
      const list = [...errorreferences];
      list[i].person_name = "*Enter person_name of your references.";
      setErrorreferences(list);
    } else {
      formIsValid = true;
      const list = [...errorreferences];
      list[i].person_name = "";
      setErrorreferences(list);
    }
    return formIsValid;
  }

  function validateReferencesCompanyName(i) {
    let formIsValid = false;
    if (!references[i].company_name) {
      formIsValid = false;
      const list = [...errorreferences];
      list[i].company_name = "*Enter company_name of your references.";
      setErrorreferences(list);
    } else if (typeof references[i].company_name === "undefined") {
      formIsValid = false;
      const list = [...errorreferences];
      list[i].company_name = "*Enter company_name of your references.";
      setErrorreferences(list);
    } else {
      formIsValid = true;
      const list = [...errorreferences];
      list[i].company_name = "";
      setErrorreferences(list);
    }
    return formIsValid;
  }

  function validateReferencesEmail(i) {
    let formIsValid = false;
    if (!references[i].email) {
      formIsValid = false;
      const list = [...errorreferences];
      list[i].email = "*Enter email of your references.";
      setErrorreferences(list);
    } else if (typeof references[i].email === "undefined") {
      formIsValid = false;
      const list = [...errorreferences];
      list[i].email = "*Enter email of your references.";
      setErrorreferences(list);
    } else {
      formIsValid = true;
      const list = [...errorreferences];
      list[i].email = "";
      setErrorreferences(list);
    }
    return formIsValid;
  }

  function validateReferencesPhone(i) {
    let formIsValid = false;
    if (!references[i].phone) {
      formIsValid = false;
      const list = [...errorreferences];
      list[i].phone = "*Enter phone of your references.";
      setErrorreferences(list);
    } else if (typeof references[i].phone === "undefined") {
      formIsValid = false;
      const list = [...errorreferences];
      list[i].phone = "*Enter phone of your references.";
      setErrorreferences(list);
    } else {
      formIsValid = true;
      const list = [...errorreferences];
      list[i].phone = "";
      setErrorreferences(list);
    }
    return formIsValid;
  }

  function validateExperienceCompany(i) {
    let formIsValid = false;
    if (!experience[i].company) {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].company = "*Enter company of your experience.";
      setErrorexperience(list);
    } else if (typeof experience[i].company === "undefined") {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].company = "*Enter company of your experience.";
      setErrorexperience(list);
    } else {
      formIsValid = true;
      const list = [...errorexperience];
      list[i].company = "";
      setErrorexperience(list);
    }
    return formIsValid;
  }
  function validateExperienceStart(i) {
    let formIsValid = false;
    var Today = new Date();
    if (!experience[i].start) {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].start = "*Enter start month of your experience.";
      setErrorexperience(list);
    } else if (typeof experience[i].start === "undefined") {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].start = "*Enter start month of your experience.";
      setErrorexperience(list);
    } else if (new Date(experience[i].start).getTime() >= Today.getTime()) {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].start = "*Joining time should be in past.";
      setErrorexperience(list);
    } else {
      formIsValid = true;
      const list = [...errorexperience];
      list[i].start = "";
      setErrorexperience(list);
    }
    return formIsValid;
  }
  function validateExperienceLeave(i) {
    let formIsValid = false;
    var Today = new Date();
    if (!experience[i].leave) {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].leave = "*Enter leave month of your experience.";
      setErrorexperience(list);
    } else if (typeof experience[i].leave === "undefined") {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].leave = "*Enter leave month of your experience.";
      setErrorexperience(list);
    } else if (new Date(experience[i].leave).getTime() >= Today.getTime()) {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].leave = "*Leaving time should be in past or current month.";
      setErrorexperience(list);
    } else if (
      new Date(experience[i].leave).getTime() <=
      new Date(experience[i].start).getTime()
    ) {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].leave = "*Leaving time should be longer than joining time.";
      setErrorexperience(list);
    } else {
      formIsValid = true;
      const list = [...errorexperience];
      list[i].leave = "";
      setErrorexperience(list);
    }
    return formIsValid;
  }
  function validateExperienceAchieve(i) {
    let formIsValid = false;
    if (!experience[i].achieve) {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].achieve = "*Enter achievement of your experience.";
      setErrorexperience(list);
    } else if (typeof experience[i].achieve === "undefined") {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].achieve = "*Enter achievement of your experience.";
      setErrorexperience(list);
    } else {
      formIsValid = true;
      const list = [...errorexperience];
      list[i].achieve = "";
      setErrorexperience(list);
    }
    return formIsValid;
  }
  function validateEducationDegree(i) {
    let formIsValid = false;
    if (!education[i].degree) {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].degree = "*Enter degree of your education.";
      setErroreducation(list);
    } else if (typeof education[i].degree === "undefined") {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].degree = "*Enter degree of your education.";
      setErroreducation(list);
    } else {
      formIsValid = true;
      const list = [...erroreducation];
      list[i].degree = "";
      setErroreducation(list);
    }
    return formIsValid;
  }
  function validateEducationUniversity(i) {
    let formIsValid = false;
    if (!education[i].university) {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].university = "*Enter university of your education.";
      setErroreducation(list);
    } else if (typeof education[i].university === "undefined") {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].university = "*Enter university of your education.";
      setErroreducation(list);
    } else {
      formIsValid = true;
      const list = [...erroreducation];
      list[i].university = "";
      setErroreducation(list);
    }
    return formIsValid;
  }
  function validateEducationStart(i) {
    let formIsValid = false;
    var Today = new Date();
    if (!education[i].start) {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].start = "*Enter start month of your education.";
      setErroreducation(list);
    } else if (typeof education[i].start === "undefined") {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].start = "*Enter start month of your education.";
      setErroreducation(list);
    } else if (new Date(education[i].start).getTime() >= Today.getTime()) {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].start = "*Joining time should be in past.";
      setErroreducation(list);
    } else {
      formIsValid = true;
      const list = [...erroreducation];
      list[i].start = "";
      setErroreducation(list);
    }
    return formIsValid;
  }
  function validateEducationLeave(i) {
    let formIsValid = false;
    var Today = new Date();
    if (!education[i].leave) {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].leave = "*Enter leave month of your education.";
      setErroreducation(list);
    } else if (typeof education[i].leave === "undefined") {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].leave = "*Enter leave month of your education.";
      setErroreducation(list);
    } else if (new Date(education[i].leave).getTime() >= Today.getTime()) {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].leave = "*Leaving time should be in past or current month.";
      setErroreducation(list);
    } else if (
      new Date(education[i].leave).getTime() <=
      new Date(education[i].start).getTime()
    ) {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].leave = "*Leaving time should be longer than joining time.";
      setErroreducation(list);
    } else {
      formIsValid = true;
      const list = [...erroreducation];
      list[i].leave = "";
      setErroreducation(list);
    }
    return formIsValid;
  }
  function validateEducationAchieve(i) {
    let formIsValid = false;
    if (!education[i].achieve) {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].achieve = "*Enter achievement of your education.";
      setErroreducation(list);
    } else if (typeof education[i].achieve === "undefined") {
      formIsValid = false;
      const list = [...erroreducation];
      list[i].achieve = "*Enter achievement of your education.";
      setErroreducation(list);
    } else {
      formIsValid = true;
      const list = [...erroreducation];
      list[i].achieve = "";
      setErroreducation(list);
    }
    return formIsValid;
  }

  function validateFormold() {
    const validabout = validateAbout();

    let validskill = true;
    for (var i = 0; i < skills.length; i++) {
      validskill = validskill && validateSkill(i);
    }

    let validexperiencetitle = true;
    for (var i = 0; i < experience.length; i++) {
      validexperiencetitle = validexperiencetitle && validateExperienceTitle(i);
    }
    let validexperiencecompany = true;
    for (var i = 0; i < experience.length; i++) {
      validexperiencecompany =
        validexperiencecompany && validateExperienceCompany(i);
    }
    let validexperiencestart = true;
    for (var i = 0; i < experience.length; i++) {
      validexperiencestart = validexperiencestart && validateExperienceStart(i);
    }
    let validexperienceleave = true;
    for (var i = 0; i < experience.length; i++) {
      validexperienceleave = validexperienceleave && validateExperienceLeave(i);
    }
    let validexperienceachieve = true;
    for (var i = 0; i < experience.length; i++) {
      validexperienceachieve =
        validexperienceachieve && validateExperienceAchieve(i);
    }

    let valideducationdegree = true;
    for (var i = 0; i < education.length; i++) {
      valideducationdegree = valideducationdegree && validateEducationDegree(i);
    }
    let valideducationuniversity = true;
    for (var i = 0; i < education.length; i++) {
      valideducationuniversity =
        valideducationuniversity && validateEducationUniversity(i);
    }
    let valideducationstart = true;
    for (var i = 0; i < education.length; i++) {
      valideducationstart = valideducationstart && validateEducationStart(i);
    }
    let valideducationleave = true;
    for (var i = 0; i < education.length; i++) {
      valideducationleave = valideducationleave && validateEducationLeave(i);
    }
    let valideducationachieve = true;
    for (var i = 0; i < education.length; i++) {
      valideducationachieve =
        valideducationachieve && validateEducationAchieve(i);
    }

    let valid =
      validabout &&
      validskill &&
      validexperiencetitle &&
      validexperiencecompany &&
      validexperiencestart &&
      validexperienceleave &&
      validexperienceachieve &&
      valideducationuniversity &&
      valideducationstart &&
      valideducationleave &&
      valideducationachieve &&
      valideducationdegree;
    return valid;
  }

  function validateForm() {
    let validexperienceName = true;
    for (var i = 0; i < experience.length; i++) {
      validexperienceName = validexperienceName && validateExperienceName(i);
    }

    let validateexperienceDescription = true;
    for (var i = 0; i < experience.length; i++) {
      validateexperienceDescription =
        validateexperienceDescription && validateExperienceDescription(i);
    }

    let validateexperienceYear = true;
    for (var i = 0; i < experience.length; i++) {
      validateexperienceYear =
        validateexperienceYear && validateExperienceYear(i);
    }

    let valid =
      validexperienceName &&
      validateexperienceDescription &&
      validateexperienceYear;
    return valid;
  }

  const handleExpCertificates = async (e) => {
    console.log("welcome");

    if (validateForm()) {
      console.log({ experience });
      props.requestEditExperienceCertificates({
        id: user.id,
        data: {
          experience: experience,
        },
        token: user.token,
      });

      // props.requestAddResume({
      //   token: user.token,
      //   id: user.id,
      //   data: formData,
      // });

      // document.querySelector("#printme").style.display = "none";
    } else {
      console.log("Not valid");
    }

    e.preventDefault();
  };

  useEffect(() => {
    let exp_certificate_data = props.candidate.editExperienceCertificatesData;
    if (exp_certificate_data !== undefined) {
      if (exp_certificate_data?.data?.status == "success") {
        Swal.fire(
          "Good job!",
          "Experience Certificates Uploaded successfully.",
          "success"
        );
        props.candidate.editExperienceCertificatesData = undefined;
        props.candidate.resumeData = undefined;
        navigate("/supportingdocuments");
      } else {
        Swal.fire(
          "Error!",
          "There is some error in uploading Certificate.",
          "error"
        );
        props.candidate.editExperienceCertificatesData = undefined;
        // props.candidate.resumeData = undefined;
      }
    }
  }, [props.candidate.editExperienceCertificatesData]);

  // Success message for Professional Academic

  useEffect(() => {
    let academic_certificate_data =
      props.candidate.editProfAcadCertificatesData;
    console.log("academic_certificate_data>>", academic_certificate_data);
    if (academic_certificate_data !== undefined) {
      if (academic_certificate_data?.data?.status == "success") {
        Swal.fire(
          "Good job!",
          "Academic Certificates Uploaded successfully.",
          "success"
        );
        props.candidate.editProfAcadCertificatesData = undefined;
        props.candidate.resumeData = undefined;
        navigate("/supportingdocuments");
      } else {
        Swal.fire(
          "Error!",
          "There is some error in uploading Certificate.",
          "error"
        );
        props.candidate.editProfAcadCertificatesData = undefined;
        // props.candidate.resumeData = undefined;
      }
    }
  }, [props.candidate.editProfAcadCertificatesData]);

  // Success message for Awards

  useEffect(() => {
    let awards = props.candidate.editAwardsData;
    console.log("awards>>", awards);
    if (awards !== undefined) {
      if (awards?.data?.status == "success") {
        Swal.fire(
          "Good job!",
          "Awards Certificates Uploaded successfully.",
          "success"
        );
        props.candidate.editAwardsData = undefined;
        props.candidate.resumeData = undefined;
        navigate("/supportingdocuments");
      } else {
        Swal.fire(
          "Error!",
          "There is some error in uploading Certificate.",
          "error"
        );
        props.candidate.editAwardsData = undefined;
        // props.candidate.resumeData = undefined;
      }
    }
  }, [props.candidate.editAwardsData]);

  // Success message for Awards

  useEffect(() => {
    let testimonials = props.candidate.editTestimonialData;
    console.log("testimonials>>", testimonials);
    if (testimonials !== undefined) {
      if (testimonials?.data?.status == "success") {
        Swal.fire(
          "Good job!",
          "Testimonials Certificates Uploaded successfully.",
          "success"
        );
        props.candidate.editTestimonialData = undefined;
        // props.candidate.resumeData = undefined;
        navigate("/supportingdocuments");
      } else {
        Swal.fire(
          "Error!",
          "There is some error in uploading Certificate.",
          "error"
        );
        props.candidate.editTestimonialData = undefined;
        // props.candidate.resumeData = undefined;
      }
    }
  }, [props.candidate.editTestimonialData]);

  // Success message for Awards

  useEffect(() => {
    let accolades = props.candidate.editAccoladesData;
    console.log("accolades>>", accolades);
    if (accolades !== undefined) {
      if (accolades?.data?.status == "success") {
        Swal.fire(
          "Good job!",
          "Accolades Certificates Uploaded successfully.",
          "success"
        );
        props.candidate.editAccoladesData = undefined;
        // props.candidate.resumeData = undefined;
        navigate("/supportingdocuments");
      } else {
        Swal.fire(
          "Error!",
          "There is some error in uploading Certificate.",
          "error"
        );
        props.candidate.editAccoladesData = undefined;
        // props.candidate.resumeData = undefined;
      }
    }
  }, [props.candidate.editAccoladesData]);

  // EXPERINECE CERTIFICATE TAB VALIDATION

  function validateExperienceName(i) {
    let formIsValid = false;
    if (!experience[i].name) {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].name = "*Enter name of your experience.";
      setErrorexperience(list);
    } else if (typeof experience[i].name === "undefined") {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].name = "*Enter name of your experience.";
      setErrorexperience(list);
    } else {
      formIsValid = true;
      let list = [...errorexperience];
      list[i].name = "";
      setErrorexperience(list);
    }
    return formIsValid;
  }

  function validateExperienceDescription(i) {
    // console.log({ i });
    // console.log(experience[i].description);

    let formIsValid = false;
    if (!experience[i].description) {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].description = "*Enter description of your experience.";
      setErrorexperience(list);
    } else if (typeof experience[i].description === "undefined") {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].description = "*Enter description of your experience.";
      setErrorexperience(list);
    } else {
      formIsValid = true;
      const list = [...errorexperience];
      list[i].description = "";
      setErrorexperience(list);
    }
    return formIsValid;
  }
  function validateExperienceYear(i) {
    let formIsValid = false;
    if (!experience[i].year) {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].year = "*Enter year of your experience.";
      setErrorexperience(list);
    } else if (typeof experience[i].year === "undefined") {
      formIsValid = false;
      const list = [...errorexperience];
      list[i].year = "*Enter year of your experience.";
      setErrorexperience(list);
    } else {
      formIsValid = true;
      const list = [...errorexperience];
      list[i].year = "";
      setErrorexperience(list);
    }
    return formIsValid;
  }

  //Handle Academic

  const handleInputProfessionalAcademic = (e, index) => {
    const { name, value } = e.target;
    const list = [...academic_professional_certificates];
    list[index][name] = value;
    setAcademic_professional_certificates(list);
  };

  //Handle Input Accolades

  const handleInputAccolades = (e, index) => {
    const { name, value } = e.target;
    const list = [...accolades_certificates];
    list[index][name] = value;
    setAccolades_certificates(list);
  };

  //Handle Input Testimonials

  const handleInputTestimonials = (e, index) => {
    const { name, value } = e.target;
    const list = [...testimonials_certificates];
    list[index][name] = value;
    setTestimonials_certificates(list);
  };

  //Handle Awards

  const handleInputAwards = (e, index) => {
    const { name, value } = e.target;
    const list = [...awards_certificates];
    list[index][name] = value;
    setAwards_certificates(list);
  };

  // Accolades TAB VALIDATION

  function validateAccoladesYear(i) {
    let formIsValid = false;
    if (!accolades_certificates[i].accolades_year) {
      formIsValid = false;
      const list = [...error_accolades_certificates];
      list[i].accolades_year =
        "*Enter accolades_year of your awards certificates.";
      setError_accolades_certificates(list);
    } else if (
      typeof accolades_certificates[i].accolades_year === "undefined"
    ) {
      formIsValid = false;
      const list = [...error_accolades_certificates];
      list[i].accolades_year =
        "*Enter accolades_year of your awards certificates.";
      setError_accolades_certificates(list);
    } else {
      formIsValid = true;
      let list = [...error_accolades_certificates];
      list[i].accolades_year = "";
      setError_accolades_certificates(list);
    }
    return formIsValid;
  }

  function validateAccoladesName(i) {
    let formIsValid = false;
    if (!accolades_certificates[i].accolades_name) {
      formIsValid = false;
      const list = [...error_accolades_certificates];
      list[i].accolades_name =
        "*Enter accolades_name of your awards certificates.";
      setError_accolades_certificates(list);
    } else if (
      typeof accolades_certificates[i].accolades_name === "undefined"
    ) {
      formIsValid = false;
      const list = [...error_accolades_certificates];
      list[i].accolades_name =
        "*Enter accolades_name of your awards certificates.";
      setError_accolades_certificates(list);
    } else {
      formIsValid = true;
      let list = [...error_accolades_certificates];
      list[i].accolades_name = "";
      setError_accolades_certificates(list);
    }
    return formIsValid;
  }
  function validateAccoladesDescription(i) {
    let formIsValid = false;
    if (!accolades_certificates[i].accolades_description) {
      formIsValid = false;
      const list = [...error_accolades_certificates];
      list[i].accolades_description =
        "*Enter accolades_description of your awards certificates.";
      setError_accolades_certificates(list);
    } else if (
      typeof accolades_certificates[i].accolades_description === "undefined"
    ) {
      formIsValid = false;
      const list = [...error_accolades_certificates];
      list[i].accolades_description =
        "*Enter accolades_description of your awards certificates.";
      setError_accolades_certificates(list);
    } else {
      formIsValid = true;
      let list = [...error_accolades_certificates];
      list[i].accolades_description = "";
      setError_accolades_certificates(list);
    }
    return formIsValid;
  }

  // Testimonials TAB VALIDATION

  function validateTestimonialsYear(i) {
    let formIsValid = false;
    if (!testimonials_certificates[i].testimonials_year) {
      formIsValid = false;
      const list = [...error_testimonials_certificates];
      list[i].testimonials_year =
        "*Enter testimonials_year of your awards certificates.";
      setError_testimonials_certificates(list);
    } else if (
      typeof testimonials_certificates[i].testimonials_year === "undefined"
    ) {
      formIsValid = false;
      const list = [...error_testimonials_certificates];
      list[i].testimonials_year =
        "*Enter testimonials_year of your awards certificates.";
      setError_testimonials_certificates(list);
    } else {
      formIsValid = true;
      let list = [...error_testimonials_certificates];
      list[i].testimonials_year = "";
      setError_testimonials_certificates(list);
    }
    return formIsValid;
  }

  function validateTestimonialsDescription(i) {
    let formIsValid = false;
    if (!testimonials_certificates[i].testimonials_description) {
      formIsValid = false;
      const list = [...error_testimonials_certificates];
      list[i].testimonials_description =
        "*Enter testimonials_description of your awards certificates.";
      setError_testimonials_certificates(list);
    } else if (
      typeof testimonials_certificates[i].testimonials_description ===
      "undefined"
    ) {
      formIsValid = false;
      const list = [...error_testimonials_certificates];
      list[i].testimonials_description =
        "*Enter testimonials_description of your awards certificates.";
      setError_testimonials_certificates(list);
    } else {
      formIsValid = true;
      let list = [...error_testimonials_certificates];
      list[i].testimonials_description = "";
      setError_testimonials_certificates(list);
    }
    return formIsValid;
  }

  function validateTestimonialsName(i) {
    let formIsValid = false;
    if (!testimonials_certificates[i].testimonials_name) {
      formIsValid = false;
      const list = [...error_testimonials_certificates];
      list[i].testimonials_name =
        "*Enter testimonials_name of your awards certificates.";
      setError_testimonials_certificates(list);
    } else if (
      typeof testimonials_certificates[i].testimonials_name === "undefined"
    ) {
      formIsValid = false;
      const list = [...error_testimonials_certificates];
      list[i].testimonials_name =
        "*Enter testimonials_name of your awards certificates.";
      setError_testimonials_certificates(list);
    } else {
      formIsValid = true;
      let list = [...error_testimonials_certificates];
      list[i].testimonials_name = "";
      setError_testimonials_certificates(list);
    }
    return formIsValid;
  }
  function validateAwardsDespcription(i) {
    let formIsValid = false;
    if (!awards_certificates[i].awards_description) {
      formIsValid = false;
      const list = [...error_awards_certificates];
      list[i].awards_description =
        "*Enter awards_description of your awards certificates.";
      setError_awards_certificates(list);
    } else if (
      typeof awards_certificates[i].awards_description === "undefined"
    ) {
      formIsValid = false;
      const list = [...error_awards_certificates];
      list[i].awards_description =
        "*Enter awards_description of your awards certificates.";
      setError_awards_certificates(list);
    } else {
      formIsValid = true;
      let list = [...error_awards_certificates];
      list[i].awards_description = "";
      setError_awards_certificates(list);
    }
    return formIsValid;
  }
  function validateAwardsYear(i) {
    let formIsValid = false;
    if (!awards_certificates[i].awards_year) {
      formIsValid = false;
      const list = [...error_awards_certificates];
      list[i].awards_year = "*Enter awards_year of your awards certificates.";
      setError_awards_certificates(list);
    } else if (typeof awards_certificates[i].awards_year === "undefined") {
      formIsValid = false;
      const list = [...error_awards_certificates];
      list[i].awards_year = "*Enter awards_year of your awards certificates.";
      setError_awards_certificates(list);
    } else {
      formIsValid = true;
      let list = [...error_awards_certificates];
      list[i].awards_year = "";
      setError_awards_certificates(list);
    }
    return formIsValid;
  }

  // Awards TAB VALIDATION

  function validateAwardsName(i) {
    let formIsValid = false;
    if (!awards_certificates[i].awards_name) {
      formIsValid = false;
      const list = [...error_awards_certificates];
      list[i].awards_name = "*Enter awards_name of your awards certificates.";
      setError_awards_certificates(list);
    } else if (typeof awards_certificates[i].awards_name === "undefined") {
      formIsValid = false;
      const list = [...error_awards_certificates];
      list[i].awards_name = "*Enter awards_name of your awards certificates.";
      setError_awards_certificates(list);
    } else {
      formIsValid = true;
      let list = [...error_awards_certificates];
      list[i].awards_name = "";
      setError_awards_certificates(list);
    }
    return formIsValid;
  }
  function validateAwardsDespcription(i) {
    let formIsValid = false;
    if (!awards_certificates[i].awards_description) {
      formIsValid = false;
      const list = [...error_awards_certificates];
      list[i].awards_description =
        "*Enter awards_description of your awards certificates.";
      setError_awards_certificates(list);
    } else if (
      typeof awards_certificates[i].awards_description === "undefined"
    ) {
      formIsValid = false;
      const list = [...error_awards_certificates];
      list[i].awards_description =
        "*Enter awards_description of your awards certificates.";
      setError_awards_certificates(list);
    } else {
      formIsValid = true;
      let list = [...error_awards_certificates];
      list[i].awards_description = "";
      setError_awards_certificates(list);
    }
    return formIsValid;
  }
  function validateAwardsYear(i) {
    let formIsValid = false;
    if (!awards_certificates[i].awards_year) {
      formIsValid = false;
      const list = [...error_awards_certificates];
      list[i].awards_year = "*Enter awards_year of your awards certificates.";
      setError_awards_certificates(list);
    } else if (typeof awards_certificates[i].awards_year === "undefined") {
      formIsValid = false;
      const list = [...error_awards_certificates];
      list[i].awards_year = "*Enter awards_year of your awards certificates.";
      setError_awards_certificates(list);
    } else {
      formIsValid = true;
      let list = [...error_awards_certificates];
      list[i].awards_year = "";
      setError_awards_certificates(list);
    }
    return formIsValid;
  }

  // Professional Academic TAB VALIDATION

  function validateProfAcadName(i) {
    let formIsValid = false;
    if (!academic_professional_certificates[i].prof_acad_name) {
      formIsValid = false;
      const list = [...erroracademic_professional_certificates];
      list[i].prof_acad_name =
        "*Enter prof_acad_name of your erroracademic_professional_certificates.";
      setErrorAcademic_professional_certificates(list);
    } else if (
      typeof academic_professional_certificates[i].prof_acad_name ===
      "undefined"
    ) {
      formIsValid = false;
      const list = [...erroracademic_professional_certificates];
      list[i].prof_acad_name =
        "*Enter prof_acad_name of your erroracademic_professional_certificates.";
      setErrorAcademic_professional_certificates(list);
    } else {
      formIsValid = true;
      let list = [...erroracademic_professional_certificates];
      list[i].prof_acad_name = "";
      setErrorAcademic_professional_certificates(list);
    }
    return formIsValid;
  }

  function validateProfAcadDescription(i) {
    let formIsValid = false;
    if (!academic_professional_certificates[i].prof_acad_description) {
      formIsValid = false;
      const list = [...erroracademic_professional_certificates];
      list[i].prof_acad_description =
        "*Enter prof_acad_description of your erroracademic_professional_certificates.";
      setErrorAcademic_professional_certificates(list);
    } else if (
      typeof academic_professional_certificates[i].prof_acad_description ===
      "undefined"
    ) {
      formIsValid = false;
      const list = [...erroracademic_professional_certificates];
      list[i].prof_acad_description =
        "*Enter prof_acad_description of your erroracademic_professional_certificates.";
      setErrorAcademic_professional_certificates(list);
    } else {
      formIsValid = true;
      let list = [...erroracademic_professional_certificates];
      list[i].prof_acad_description = "";
      setErrorAcademic_professional_certificates(list);
    }
    return formIsValid;
  }

  function validateProfAcadYear(i) {
    let formIsValid = false;
    if (!academic_professional_certificates[i].prof_acad_year) {
      formIsValid = false;
      const list = [...erroracademic_professional_certificates];
      list[i].prof_acad_year =
        "*Enter prof_acad_year of your erroracademic_professional_certificates.";
      setErrorAcademic_professional_certificates(list);
    } else if (
      typeof academic_professional_certificates[i].prof_acad_year ===
      "undefined"
    ) {
      formIsValid = false;
      const list = [...erroracademic_professional_certificates];
      list[i].prof_acad_year =
        "*Enter prof_acad_year of your erroracademic_professional_certificates.";
      setErrorAcademic_professional_certificates(list);
    } else {
      formIsValid = true;
      let list = [...erroracademic_professional_certificates];
      list[i].prof_acad_year = "";
      setErrorAcademic_professional_certificates(list);
    }
    return formIsValid;
  }

  //Submit Awards Form Data

  const handleAwardsCertificates = async (e) => {
    console.log("welcome awards");
    // if (validateForm()) {
    if (9 > 1) {
      console.log({ awards_certificates });
      props.requestEditAwards({
        id: user.id,
        data: {
          awards_certificates: awards_certificates,
        },
        token: user.token,
      });

      // document.querySelector("#printme").style.display = "none";
    } else {
      console.log("Not valid");
    }

    e.preventDefault();
  };

  //Submit Testimonials Form Data

  const handleTestimonialsCertificates = async (e) => {
    console.log("welcome testimonials");
    // if (validateForm()) {
    if (9 > 1) {
      console.log({ testimonials_certificates });
      props.requestEditTestimonials({
        id: user.id,
        data: {
          testimonials_certificates: testimonials_certificates,
        },
        token: user.token,
      });

      // document.querySelector("#printme").style.display = "none";
    } else {
      console.log("Not valid");
    }

    e.preventDefault();
  };

  //Submit Accolades Form Data

  const handleAccoladesCertificates = async (e) => {
    console.log("welcome accolades");
    // if (validateForm()) {
    if (9 > 1) {
      console.log({ accolades_certificates });
      props.requestEditAccolades({
        id: user.id,
        data: {
          accolades_certificates: accolades_certificates,
        },
        token: user.token,
      });

      // document.querySelector("#printme").style.display = "none";
    } else {
      console.log("Not valid");
    }

    e.preventDefault();
  };

  //Submit professional Academic Form Data

  const handleProfAcadCertificates = async (e) => {
    console.log("welcome academic");
    // if (validateForm()) {
    if (9 > 1) {
      console.log({ academic_professional_certificates });

      props.requestEditProfAcadCertificates({
        id: user.id,
        data: {
          academic_professional_certificates:
            academic_professional_certificates,
        },
        token: user.token,
      });

      // props.requestEditExperienceCertificates({
      //   id: user.id,
      //   data: {
      //     experience: experience,
      //   },
      //   token: user.token,
      // });

      // props.requestAddResume({
      //   token: user.token,
      //   id: user.id,
      //   data: formData,
      // });

      // document.querySelector("#printme").style.display = "none";
    } else {
      console.log("Not valid");
    }

    e.preventDefault();
  };

  // Handle Add Testimonials Certificates

  const handleAddAccolades = () => {
    let length = accolades_certificates.length;
    if (length === 0) {
      setAccolades_certificates([
        ...accolades_certificates,
        {
          accolades_uploadpath: "",
          accolades_name: "",
          accolades_description: "",
          accolades_year: "",
        },
      ]);
      setError_testimonials_certificates([
        ...error_testimonials_certificates,
        {
          accolades_uploadpath: "",
          accolades_name: "",
          accolades_description: "",
          accolades_year: "",
        },
      ]);
    } else if (
      // academic_professional_certificates[length - 1].prof_acad_uploadpath === "" ||
      accolades_certificates[length - 1].accolades_name === "" ||
      accolades_certificates[length - 1].accolades_description === "" ||
      accolades_certificates[length - 1].accolades_year === ""
    ) {
      Swal.fire(
        "Error!",
        "After completing previous one, You can add new one.",
        "error"
      );
    } else {
      setAccolades_certificates([
        ...accolades_certificates,
        {
          accolades_uploadpath: "",
          accolades_name: "",
          accolades_description: "",
          accolades_year: "",
        },
      ]);
      setError_accolades_certificates([
        ...error_accolades_certificates,
        {
          accolades_uploadpath: "",
          accolades_name: "",
          accolades_description: "",
          accolades_year: "",
        },
      ]);
    }
  };

  // Handle Add Testimonials Certificates

  const handleAddTestimonials = () => {
    let length = testimonials_certificates.length;
    if (length === 0) {
      setTestimonials_certificates([
        ...testimonials_certificates,
        {
          testimonials_uploadpath: "",
          testimonials_name: "",
          testimonials_description: "",
          testimonials_year: "",
        },
      ]);
      setError_testimonials_certificates([
        ...error_testimonials_certificates,
        {
          testimonials_uploadpath: "",
          testimonials_name: "",
          testimonials_description: "",
          testimonials_year: "",
        },
      ]);
    } else if (
      // academic_professional_certificates[length - 1].prof_acad_uploadpath === "" ||
      testimonials_certificates[length - 1].testimonials_name === "" ||
      testimonials_certificates[length - 1].testimonials_description === "" ||
      testimonials_certificates[length - 1].testimonials_year === ""
    ) {
      Swal.fire(
        "Error!",
        "After completing previous one, You can add new one.",
        "error"
      );
    } else {
      setTestimonials_certificates([
        ...testimonials_certificates,
        {
          testimonials_uploadpath: "",
          testimonials_name: "",
          testimonials_description: "",
          testimonials_year: "",
        },
      ]);
      setError_testimonials_certificates([
        ...error_testimonials_certificates,
        {
          testimonials_uploadpath: "",
          testimonials_name: "",
          testimonials_description: "",
          testimonials_year: "",
        },
      ]);
    }
  };

  // Handle Add Awads Certificates

  const handleAddAwards = () => {
    let length = awards_certificates.length;
    if (length === 0) {
      setAwards_certificates([
        ...awards_certificates,
        {
          awards_uploadpath: "",
          awards_name: "",
          awards_description: "",
          awards_year: "",
        },
      ]);
      setError_awards_certificates([
        ...error_awards_certificates,
        {
          awards_uploadpath: "",
          awards_name: "",
          awards_description: "",
          awards_year: "",
        },
      ]);
    } else if (
      // academic_professional_certificates[length - 1].prof_acad_uploadpath === "" ||
      awards_certificates[length - 1].awards_name === "" ||
      awards_certificates[length - 1].awards_description === "" ||
      awards_certificates[length - 1].awards_year === ""
    ) {
      Swal.fire(
        "Error!",
        "After completing previous one, You can add new one.",
        "error"
      );
    } else {
      setAwards_certificates([
        ...awards_certificates,
        {
          awards_uploadpath: "",
          awards_name: "",
          awards_description: "",
          awards_year: "",
        },
      ]);
      setError_awards_certificates([
        ...error_awards_certificates,
        {
          awards_uploadpath: "",
          awards_name: "",
          awards_description: "",
          awards_year: "",
        },
      ]);
    }
  };

  // Handle Add Academic Professional Certificates

  const handleAddProfAcadExperience = () => {
    let length = academic_professional_certificates.length;
    if (length === 0) {
      setAcademic_professional_certificates([
        ...academic_professional_certificates,
        {
          prof_acad_uploadpath: "",
          prof_acad_name: "",
          prof_acad_description: "",
          prof_acad_year: "",
        },
      ]);
      setErrorAcademic_professional_certificates([
        ...erroracademic_professional_certificates,
        {
          prof_acad_uploadpath: "",
          prof_acad_name: "",
          prof_acad_description: "",
          prof_acad_year: "",
        },
      ]);
    } else if (
      // academic_professional_certificates[length - 1].prof_acad_uploadpath === "" ||
      academic_professional_certificates[length - 1].prof_acad_name === "" ||
      academic_professional_certificates[length - 1].prof_acad_description ===
        "" ||
      academic_professional_certificates[length - 1].prof_acad_year === ""
    ) {
      Swal.fire(
        "Error!",
        "After completing previous one, You can add new one.",
        "error"
      );
    } else {
      setAcademic_professional_certificates([
        ...academic_professional_certificates,
        {
          prof_acad_uploadpath: "",
          prof_acad_name: "",
          prof_acad_description: "",
          prof_acad_year: "",
        },
      ]);
      setErrorAcademic_professional_certificates([
        ...erroracademic_professional_certificates,
        {
          prof_acad_uploadpath: "",
          prof_acad_name: "",
          prof_acad_description: "",
          prof_acad_year: "",
        },
      ]);
    }
  };

  // const [img, setImg] = useState("");
  const [selectedFile, setSelectedFile] = useState();

  const onSelectFile = async (e, i) => {
    let name = e.target.name;
    //  setimg(URL.createObjectURL(e.target.files[0]));
    if (!e.target.files || e.target.files.length === 0) {
      //  setSelectedFile(undefined);

      if (name === "uploadpath") {
        const list = [...experience];
        list[i]["uploadpath"] = undefined;
        setExperience(list);
      }
    } else {
      //  setSelectedFile(e.target.files[0]);

      // console.log(e.target.files[0]);

      if (name != undefined && name === "uploadpath") {
        const list = [...experience];
        let base64 = await convertBase64(e.target.files[0]);
        list[i]["uploadpath"] = base64;
        setExperience(list);
      } else if (name != undefined && name === "prof_acad_uploadpath") {
        const list = [...academic_professional_certificates];
        let base64 = await convertBase64(e.target.files[0]);
        list[i]["prof_acad_uploadpath"] = base64;
        setAcademic_professional_certificates(list);
      } else if (name != undefined && name === "awards_uploadpath") {
        const list = [...awards_certificates];
        let base64 = await convertBase64(e.target.files[0]);
        list[i]["awards_uploadpath"] = base64;
        setAwards_certificates(list);
      } else if (name != undefined && name === "testimonials_uploadpath") {
        const list = [...testimonials_certificates];
        let base64 = await convertBase64(e.target.files[0]);
        list[i]["testimonials_uploadpath"] = base64;
        setTestimonials_certificates(list);
      } else if (name != undefined && name === "accolades_uploadpath") {
        const list = [...accolades_certificates];
        let base64 = await convertBase64(e.target.files[0]);
        list[i]["accolades_uploadpath"] = base64;
        setAccolades_certificates(list);
      }
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  // Handle Remove Accolades

  const handleRemoveAccolades = (index) => {
    const list = [...accolades_certificates];
    list.splice(index, 1);
    setAccolades_certificates(list);
    const errorlist = [...error_accolades_certificates];
    errorlist.splice(index, 1);
    setError_accolades_certificates(errorlist);
  };

  // Handle Remove Testimonials

  const handleRemoveTestimonials = (index) => {
    const list = [...testimonials_certificates];
    list.splice(index, 1);
    setTestimonials_certificates(list);
    const errorlist = [...error_testimonials_certificates];
    errorlist.splice(index, 1);
    setError_testimonials_certificates(errorlist);
  };

  // Handle Remove Professional Academic

  const handleRemoveAcademic = (index) => {
    const list = [...academic_professional_certificates];
    list.splice(index, 1);
    setAcademic_professional_certificates(list);
    const errorlist = [...erroracademic_professional_certificates];
    errorlist.splice(index, 1);
    setErrorAcademic_professional_certificates(errorlist);
  };

  // Handle Remove Awards

  const handleRemoveAwards = (index) => {
    const list = [...awards_certificates];
    list.splice(index, 1);
    setAwards_certificates(list);
    const errorlist = [...error_awards_certificates];
    errorlist.splice(index, 1);
    setError_awards_certificates(errorlist);
  };

  return (
    <>
      <Header />
      <Breadcrumbs title="Make Resume" />
      <div class="resume section common-spacing">
        <div class="container">
        
        <MainTopComponent 
      username = {data.first_name}
      lastname = {data.last_name}
      userimage = {data.profile}
      useraddress = {data.address}
      useraddress2 = {data.address2}
      phone = {data.phone}
      email = {data.email}
      experience = {data.experience}
      notice_period = {data.notice_period}
      pincode = {data.pincode}
      city = {data.city_name}
      state = {data.state_name}
      country = {data.country_name}
      jobtitle = {data.designation}
      current_salary = {data.current_salary}
     
      />

          <div class="resume-inner">
            <div class="row">
              <ManageAccount name="supportingdocuments" from="submenu" />

              <div class="col-lg-8  pe-0">
                <div class="inner-content">
                  <div class="accordion" id="accordionExample">
                    {/* accordian start  */}
                    {/* experience certificates */}
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                            style={{ color: "black", textDecoration: "none" }}
                          >
                            <b>Experience Certificates</b>
                            {/* <span style={{ marginLeft: "20px" }}>
                              Total: {experience?.length}
                            </span> */}
                            <span class="">
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </span>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseOne"
                        class="collapse"
                        aria-labelledby="headingOne"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          {/* <form
                            class="forms-sample"
                            onSubmit={handleExpCertificates}
                          > */}
                          <div class="forms-sample" id="exp_certificate">
                            <div class="single-section exprerience">
                              <h4>
                                Upload Experience Certificates
                                <button className="addicon"
                                  onClick={handleAddExperience}
                                  style={{float: "right",}}>
                                  <i class="fa fa-plus"></i>
                                </button>
                              </h4>

                              {experience?.length > 0 &&
                                experience.map((item, i) => {
                                  return (
                                    <div
                                      class="row "
                                      style={{
                                        boxShadow:
                                          "2px 4px 8px 4px rgba(0,0,0,0.2)",
                                        transition: "0.3s",
                                        padding: "20px 15px",
                                        borderRadius: "15px",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <div
                                        class="col-lg-12 col-md-12"
                                        style={{
                                          marginBottom: "5px",
                                          color: "black",
                                        }}
                                      >
                                        <b>Certificates : {i + 1}</b>
                                        <button className="delteicon"
                                          onClick={() => handleRemoveExperience(i)}
                                          style={{float: "right",}}>
                                          <i class="fa fa-trash"></i>
                                        </button>
                                      </div>

                                      {item.uploadpath &&
                                        item.uploadpath != "" && (
                                          <div
                                            class="col-lg-12 col-md-12"
                                            style={{
                                              marginBottom: "5px",
                                              color: "black",
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "100px",
                                                height: "100px",
                                                objectFit: "cover"
                                              }}
                                              src={
                                                process.env.REACT_APP_API_HOST +
                                                "/storage/sdocuments/" +
                                                item.uploadpath
                                              }
                                            />
                                          </div>
                                        )}

                                      <div
                                        class="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                      >
                                        <div class="form-group">
                                          <label>Upload Exp Certificates</label>
                                          <input
                                            class="form-control uploadfileStyle"
                                            type="file"
                                            name="uploadpath"
                                            // value={x.person_name}
                                            // // value={}
                                            // onBlur={() => validateReferencesPersonName(i)}

                                            onChange={(e) => onSelectFile(e, i)}

                                            // placeholder=""
                                          />
                                          {errorexperience[i]?.uploadpath && (
                                            <div style={mystyle}>
                                              {errorexperience[i]?.uploadpath}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        class="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                      >
                                        <div class="form-group">
                                          <label>Name</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="name"
                                            value={item.name}
                                            onBlur={() =>
                                              validateExperienceName(i)
                                            }
                                            onChange={(e) =>
                                              handleInputExperience(e, i)
                                            }
                                            placeholder=""
                                          />
                                          {errorexperience[i]?.name && (
                                            <div style={mystyle}>
                                              {errorexperience[i]?.name}
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div
                                        class="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                      >
                                        <div class="form-group">
                                          <label>Description </label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="description"
                                            value={item.description}
                                            onBlur={() =>
                                              validateExperienceDescription(i)
                                            }
                                            onChange={(e) =>
                                              handleInputExperience(e, i)
                                            }
                                            // placeholder=""
                                          />
                                          {errorexperience[i]?.description && (
                                            <div style={mystyle}>
                                              {errorexperience[i]?.description}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        class="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                      >
                                        <div class="form-group">
                                          <label>Received Year</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="year"
                                            value={item.year}
                                            onBlur={() =>
                                              validateExperienceYear(i)
                                            }
                                            onChange={(e) =>
                                              handleInputExperience(e, i)
                                            }
                                            placeholder=""
                                          />
                                          {errorexperience[i]?.year && (
                                            <div style={mystyle}>
                                              {errorexperience[i]?.year}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>

                          <div class="col-lg-12">
                            <div class="button" style={{ marginTop: "30px" }}>
                              <button
                                class="btn"
                                onClick={handleExpCertificates}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>

                    {/*academic and professional certificates */}
                    <div class="card">
                      <div class="card-header" id="headingTwo">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                            style={{ color: "black", textDecoration: "none" }}
                          >
                            <b>Professional Certificates</b>
                            <span class="">
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </span>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseTwo"
                        class="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          {/* <form
                            class="forms-sample"
                            onSubmit={handleExpCertificates}
                          > */}
                          <div class="forms-sample" id="exp_certificate">
                            <div class="single-section exprerience">
                              <h4>
                                Upload Professional Certificates
                                <button className="addicon"
                                  onClick={handleAddProfAcadExperience}
                                  style={{ float: "right",}}>
                                  <i class="fa fa-plus"></i>
                                </button>
                              </h4>

                              {academic_professional_certificates?.length > 0 &&
                                academic_professional_certificates.map(
                                  (item, i) => {
                                    return (
                                      <div
                                        class="row "
                                        style={{
                                          boxShadow:
                                            "2px 4px 8px 4px rgba(0,0,0,0.2)",
                                          transition: "0.3s",
                                          padding: "20px 15px",
                                          borderRadius: "15px",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <div
                                          class="col-lg-12 col-md-12 pe-0"
                                          style={{
                                            marginBottom: "5px",
                                            color: "black",
                                          }}
                                        >
                                          <b>Certificates : {i + 1}</b>
                                          <button className="delteicon"
                                            onClick={() => handleRemoveAcademic(i)}
                                            style={{float: "right",}}>
                                            <i class="fa fa-trash"></i>
                                          </button>
                                        </div>

                                        {item.prof_acad_uploadpath &&
                                          item.prof_acad_uploadpath != "" && (
                                            <div
                                              class="col-lg-12 col-md-12"
                                              style={{
                                                marginBottom: "5px",
                                                color: "black",
                                              }}
                                            >
                                              <img
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                }}
                                                src={
                                                  process.env
                                                    .REACT_APP_API_HOST +
                                                  "/storage/sdocuments/" +
                                                  item.prof_acad_uploadpath
                                                }
                                              />
                                            </div>
                                          )}

                                        <div
                                          class="col-lg-6 col-md-6"
                                          style={{ padding: "5px" }}
                                        >
                                          <div class="form-group">
                                            <label>
                                              Upload Prof/Acad Certificates
                                            </label>
                                            <input
                                              class="form-control uploadfileStyle"
                                              type="file"
                                              name="prof_acad_uploadpath"
                                              // value={x.person_name}
                                              // // value={}
                                              // onBlur={() => validateReferencesPersonName(i)}

                                              onChange={(e) =>
                                                onSelectFile(e, i)
                                              }

                                              // placeholder=""
                                            />
                                            {erroracademic_professional_certificates[
                                              i
                                            ]?.prof_acad_uploadpath && (
                                              <div style={mystyle}>
                                                {
                                                  erroracademic_professional_certificates[
                                                    i
                                                  ]?.prof_acad_uploadpath
                                                }
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          class="col-lg-6 col-md-6"
                                          style={{ padding: "5px" }}
                                        >
                                          <div class="form-group">
                                            <label>Name</label>
                                            <input
                                              class="form-control"
                                              type="text"
                                              name="prof_acad_name"
                                              value={item?.prof_acad_name}
                                              onBlur={() =>
                                                validateProfAcadName(i)
                                              }
                                              onChange={(e) =>
                                                handleInputProfessionalAcademic(
                                                  e,
                                                  i
                                                )
                                              }
                                              placeholder=""
                                            />
                                            {erroracademic_professional_certificates[
                                              i
                                            ]?.prof_acad_name && (
                                              <div style={mystyle}>
                                                {
                                                  erroracademic_professional_certificates[
                                                    i
                                                  ]?.prof_acad_name
                                                }
                                              </div>
                                            )}
                                          </div>
                                        </div>

                                        <div
                                          class="col-lg-6 col-md-6"
                                          style={{ padding: "5px" }}
                                        >
                                          <div class="form-group">
                                            <label>Description </label>
                                            <input
                                              class="form-control"
                                              type="text"
                                              name="prof_acad_description"
                                              value={item.prof_acad_description}
                                              onBlur={() =>
                                                validateProfAcadDescription(i)
                                              }
                                              onChange={(e) =>
                                                handleInputProfessionalAcademic(
                                                  e,
                                                  i
                                                )
                                              }
                                              // placeholder=""
                                            />
                                            {erroracademic_professional_certificates[
                                              i
                                            ]?.prof_acad_description && (
                                              <div style={mystyle}>
                                                {
                                                  erroracademic_professional_certificates[
                                                    i
                                                  ]?.prof_acad_description
                                                }
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          class="col-lg-6 col-md-6"
                                          style={{ padding: "5px" }}
                                        >
                                          <div class="form-group">
                                            <label>Received Year</label>
                                            <input
                                              class="form-control"
                                              type="text"
                                              name="prof_acad_year"
                                              value={item.prof_acad_year}
                                              onBlur={() =>
                                                validateProfAcadYear(i)
                                              }
                                              onChange={(e) =>
                                                handleInputProfessionalAcademic(
                                                  e,
                                                  i
                                                )
                                              }
                                              placeholder=""
                                            />
                                            {erroracademic_professional_certificates[
                                              i
                                            ]?.prof_acad_year && (
                                              <div style={mystyle}>
                                                {
                                                  erroracademic_professional_certificates[
                                                    i
                                                  ]?.prof_acad_year
                                                }
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>

                          <div class="col-lg-12">
                            <div class="button" style={{ marginTop: "30px" }}>
                              <button
                                class="btn"
                                onClick={handleProfAcadCertificates}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                    {/* Awards*/}
                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            style={{ color: "black", textDecoration: "none" }}
                          >
                            <b>Awards</b>
                            <span class="">
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </span>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseThree"
                        class="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          {/* <form
                            class="forms-sample"
                            onSubmit={handleExpCertificates}
                          > */}
                          <div class="forms-sample" id="awards_certificate">
                            <div class="single-section exprerience">
                              <h4>
                                Upload Awards
                                <button className="addicon"
                                  onClick={handleAddAwards}
                                  style={{ float: "right",}}>
                                  <i class="fa fa-plus" ></i>
                                </button>
                              </h4>

                              {awards_certificates?.length > 0 &&
                                awards_certificates.map((item, i) => {
                                  return (
                                    <div
                                      class="row "
                                      style={{
                                        boxShadow:
                                          "2px 4px 8px 4px rgba(0,0,0,0.2)",
                                        transition: "0.3s",
                                        padding: "20px 15px",
                                        borderRadius: "15px",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <div
                                        class="col-lg-12 col-md-12 pe-0"
                                        style={{
                                          marginBottom: "5px",
                                          color: "black",
                                        }}
                                      >
                                        <b>Awards : {i + 1}</b>
                                        <button className="delteicon"
                                          onClick={() => handleRemoveAwards(i)}
                                          style={{ float: "right",}}>
                                          <i class="fa fa-trash"></i>
                                        </button>
                                      </div>
                                      
                                      {item.awards_uploadpath &&
                                        item.awards_uploadpath != "" && (
                                          <div
                                            class="col-lg-12 col-md-12"
                                            style={{
                                              marginBottom: "5px",
                                              color: "black",
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "100px",
                                                height: "100px",
                                              }}
                                              src={
                                                process.env.REACT_APP_API_HOST +
                                                "/storage/sdocuments/" +
                                                item.awards_uploadpath
                                              }
                                            />
                                          </div>
                                        )}
                                      <div
                                        class="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                      >
                                        <div class="form-group">
                                          <label>
                                            Upload Awards Certificates
                                          </label>
                                          <input
                                            class="form-control uploadfileStyle"
                                            type="file"
                                            name="awards_uploadpath"
                                            // value={x.person_name}
                                            // // value={}
                                            // onBlur={() => validateReferencesPersonName(i)}

                                            onChange={(e) => onSelectFile(e, i)}

                                            // placeholder=""
                                          />
                                          {error_awards_certificates[i]
                                            ?.awards_uploadpath && (
                                            <div style={mystyle}>
                                              {
                                                error_awards_certificates[i]
                                                  ?.awards_uploadpath
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        class="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                      >
                                        <div class="form-group">
                                          <label>Name</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="awards_name"
                                            value={item.awards_name}
                                            onBlur={() => validateAwardsName(i)}
                                            onChange={(e) =>
                                              handleInputAwards(e, i)
                                            }
                                            placeholder=""
                                          />
                                          {error_awards_certificates[i]
                                            ?.awards_name && (
                                            <div style={mystyle}>
                                              {
                                                error_awards_certificates[i]
                                                  ?.awards_name
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div
                                        class="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                      >
                                        <div class="form-group">
                                          <label>Description </label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="awards_description"
                                            value={item.awards_description}
                                            onBlur={() =>
                                              validateAwardsDespcription(i)
                                            }
                                            onChange={(e) =>
                                              handleInputAwards(e, i)
                                            }
                                            // placeholder=""
                                          />
                                          {error_awards_certificates[i]
                                            ?.awards_description && (
                                            <div style={mystyle}>
                                              {
                                                error_awards_certificates[i]
                                                  ?.awards_description
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        class="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                      >
                                        <div class="form-group">
                                          <label>Received Year</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="awards_year"
                                            value={item.awards_year}
                                            onBlur={() => validateAwardsYear(i)}
                                            onChange={(e) =>
                                              handleInputAwards(e, i)
                                            }
                                            placeholder=""
                                          />
                                          {error_awards_certificates[i]
                                            ?.awards_year && (
                                            <div style={mystyle}>
                                              {
                                                error_awards_certificates[i]
                                                  ?.awards_year
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>

                          <div class="col-lg-12">
                            <div class="button" style={{ marginTop: "30px" }}>
                              <button
                                class="btn"
                                onClick={handleAwardsCertificates}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                    {/* Testimonials */}
                    <div class="card">
                      <div class="card-header" id="headingFour">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                            style={{ color: "black", textDecoration: "none" }}
                          >
                            <b>Testimonials</b>
                            <span class="">
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </span>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseFour"
                        class="collapse"
                        aria-labelledby="headingFour"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          {/* <form
                            class="forms-sample"
                            onSubmit={handleExpCertificates}
                          > */}
                          <div class="forms-sample" id="exp_certificate">
                            <div class="single-section exprerience">
                              <h4>
                                Upload Testimonials
                                <button className="addicon"
                                  onClick={handleAddTestimonials}
                                  style={{float: "right",}}>
                                  <i class="fa fa-plus"></i>
                                </button>
                              </h4>

                              {testimonials_certificates?.length > 0 &&
                                testimonials_certificates.map((item, i) => {
                                  return (
                                    <div
                                      class="row "
                                      style={{
                                        boxShadow:
                                          "2px 4px 8px 4px rgba(0,0,0,0.2)",
                                        transition: "0.3s",
                                        padding: "20px 15px",
                                        borderRadius: "15px",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <div
                                        class="col-lg-12 col-md-12 pe-0"
                                        style={{
                                          marginBottom: "5px",
                                          color: "black",
                                        }}
                                      >
                                        <b>Testimonials : {i + 1}</b>
                                        <button className="delteicon"
                                          onClick={() =>
                                            handleRemoveTestimonials(i)
                                          }
                                          style={{float: "right",}}>
                                          <i class="fa fa-trash"></i>
                                        </button>
                                      </div>
                                      {item.testimonials_uploadpath &&
                                        item.testimonials_uploadpath != "" && (
                                          <div
                                            class="col-lg-12 col-md-12"
                                            style={{
                                              marginBottom: "5px",
                                              color: "black",
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "100px",
                                                height: "100px",
                                              }}
                                              src={
                                                process.env.REACT_APP_API_HOST +
                                                "/storage/sdocuments/" +
                                                item.testimonials_uploadpath
                                              }
                                            />
                                          </div>
                                        )}
                                      <div
                                        class="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                      >
                                        <div class="form-group">
                                          <label>Upload Testimonials</label>
                                          <input
                                            class="form-control uploadfileStyle"
                                            type="file"
                                            name="testimonials_uploadpath"
                                            // value={x.person_name}
                                            // // value={}
                                            // onBlur={() => validateReferencesPersonName(i)}

                                            onChange={(e) => onSelectFile(e, i)}

                                            // placeholder=""
                                          />
                                          {error_testimonials_certificates[i]
                                            ?.testimonials_uploadpath && (
                                            <div style={mystyle}>
                                              {
                                                error_testimonials_certificates[
                                                  i
                                                ]?.testimonials_uploadpath
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        class="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                      >
                                        <div class="form-group">
                                          <label>Name</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="testimonials_name"
                                            value={item.testimonials_name}
                                            onBlur={() =>
                                              validateTestimonialsName(i)
                                            }
                                            onChange={(e) =>
                                              handleInputTestimonials(e, i)
                                            }
                                            placeholder=""
                                          />
                                          {error_testimonials_certificates[i]
                                            ?.testimonials_name && (
                                            <div style={mystyle}>
                                              {
                                                error_testimonials_certificates[
                                                  i
                                                ]?.testimonials_name
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div
                                        class="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                      >
                                        <div class="form-group">
                                          <label>Description </label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="testimonials_description"
                                            value={
                                              item.testimonials_description
                                            }
                                            onBlur={() =>
                                              validateTestimonialsDescription(i)
                                            }
                                            onChange={(e) =>
                                              handleInputTestimonials(e, i)
                                            }
                                            // placeholder=""
                                          />
                                          {error_testimonials_certificates[i]
                                            ?.testimonials_description && (
                                            <div style={mystyle}>
                                              {
                                                error_testimonials_certificates[
                                                  i
                                                ]?.testimonials_description
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        class="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                      >
                                        <div class="form-group">
                                          <label>Received Year</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="testimonials_year"
                                            value={item.testimonials_year}
                                            onBlur={() =>
                                              validateTestimonialsYear(i)
                                            }
                                            onChange={(e) =>
                                              handleInputTestimonials(e, i)
                                            }
                                            placeholder=""
                                          />
                                          {error_testimonials_certificates[i]
                                            ?.testimonials_year && (
                                            <div style={mystyle}>
                                              {
                                                error_testimonials_certificates[
                                                  i
                                                ]?.testimonials_year
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>

                          <div class="col-lg-12">
                            <div class="button" style={{ marginTop: "30px" }}>
                              <button
                                class="btn"
                                onClick={handleTestimonialsCertificates}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                    {/* Accolades */}
                    <div class="card">
                      <div class="card-header" id="headingFive">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                            style={{ color: "black", textDecoration: "none" }}
                          >
                            <b>Accolades</b>
                            <span class="">
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </span>
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseFive"
                        class="collapse"
                        aria-labelledby="headingFive"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body">
                          {/* <form
                            class="forms-sample"
                            onSubmit={handleExpCertificates}
                          > */}
                          <div class="forms-sample" id="exp_certificate">
                            <div class="single-section exprerience">
                              <h4>
                                Upload Accolades
                                <button className="addicon"
                                  onClick={handleAddAccolades}
                                  style={{
                                   
                                    float: "right",
                                  }}
                                >
                                  <i
                                    class="fa fa-plus"
                                    
                                  ></i>
                                </button>
                              </h4>

                              {accolades_certificates?.length > 0 &&
                                accolades_certificates.map((item, i) => {
                                  return (
                                    <div
                                      class="row "
                                      style={{
                                        boxShadow:
                                          "2px 4px 8px 4px rgba(0,0,0,0.2)",
                                        transition: "0.3s",
                                        padding: "20px",
                                        borderRadius: "15px",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <div
                                        class="col-lg-12 col-md-12 pe-0"
                                        style={{
                                          marginBottom: "5px",
                                          color: "black",
                                        }}
                                      >
                                        <b>Accolades : {i + 1}</b>
                                        <button className="delteicon"
                                          onClick={() =>
                                            handleRemoveAccolades(i)
                                          }
                                          style={{
                                            
                                            float: "right",
                                          }}
                                        >
                                          <i
                                            class="fa fa-trash"
                                            
                                          ></i>
                                        </button>
                                      </div>
                                      {item.accolades_uploadpath &&
                                        item.accolades_uploadpath != "" && (
                                          <div
                                            class="col-lg-12 col-md-12"
                                            style={{
                                              marginBottom: "5px",
                                              color: "black",
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "100px",
                                                height: "100px",
                                              }}
                                              src={
                                                process.env.REACT_APP_API_HOST +
                                                "/storage/sdocuments/" +
                                                item.accolades_uploadpath
                                              }
                                            />
                                          </div>
                                        )}
                                      <div
                                        class="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                      >
                                        <div class="form-group">
                                          <label>
                                            Upload Accolades Certificates
                                          </label>
                                          <input
                                            class="form-control uploadfileStyle"
                                            type="file"
                                            name="accolades_uploadpath"
                                            // value={x.person_name}
                                            // // value={}
                                            // onBlur={() => validateReferencesPersonName(i)}

                                            onChange={(e) => onSelectFile(e, i)}

                                            // placeholder=""
                                          />
                                          {error_accolades_certificates[i]
                                            ?.accolades_uploadpath && (
                                            <div style={mystyle}>
                                              {
                                                error_accolades_certificates[i]
                                                  ?.accolades_uploadpath
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        class="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                      >
                                        <div class="form-group">
                                          <label>Name</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="accolades_name"
                                            value={item.accolades_name}
                                            onBlur={() =>
                                              validateAccoladesName(i)
                                            }
                                            onChange={(e) =>
                                              handleInputAccolades(e, i)
                                            }
                                            placeholder=""
                                          />
                                          {error_accolades_certificates[i]
                                            ?.accolades_name && (
                                            <div style={mystyle}>
                                              {
                                                error_accolades_certificates[i]
                                                  ?.accolades_name
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div
                                        class="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                      >
                                        <div class="form-group">
                                          <label>Description </label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="accolades_description"
                                            value={item.accolades_description}
                                            onBlur={() =>
                                              validateAccoladesDescription(i)
                                            }
                                            onChange={(e) =>
                                              handleInputAccolades(e, i)
                                            }
                                            // placeholder=""
                                          />
                                          {error_accolades_certificates[i]
                                            ?.accolades_description && (
                                            <div style={mystyle}>
                                              {
                                                error_accolades_certificates[i]
                                                  ?.accolades_description
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        class="col-lg-6 col-md-6"
                                        style={{ padding: "5px" }}
                                      >
                                        <div class="form-group">
                                          <label>Received Year</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="accolades_year"
                                            value={item.accolades_year}
                                            onBlur={() =>
                                              validateAccoladesYear(i)
                                            }
                                            onChange={(e) =>
                                              handleInputAccolades(e, i)
                                            }
                                            placeholder=""
                                          />
                                          {error_accolades_certificates[i]
                                            ?.accolades_year && (
                                            <div style={mystyle}>
                                              {
                                                error_accolades_certificates[i]
                                                  ?.accolades_year
                                              }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>

                          <div class="col-lg-12">
                            <div class="button" style={{ marginTop: "30px" }}>
                              <button
                                class="btn"
                                onClick={handleAccoladesCertificates}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* accordian end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div ref={printRef}>
        <div id="printme" style={{ display: "none" }}>
          <div class="row">
            <div
              class="col-4"
              style={{ backgroundColor: "#2042e3", padding: "50px" }}
            >
              <h1 style={{ color: "white" }}>{data.first_name}</h1>
              <h1 style={{ color: "white" }}>{data.last_name}</h1>
              <br />
              <br />
              <div
                style={{
                  padding: "20px 0px",
                  margin: "15px -50px",
                  backgroundColor: "#000080",
                }}
              >
                <h4 style={{ color: "white", padding: "0px 50px" }}>
                  Personal Information
                </h4>
              </div>
              <br />
              <h5 style={{ color: "white" }}>
                <i class="fa fa-map-marker"></i>&nbsp;&nbsp;&nbsp;
                {data.state_name}, {data.country_name}
              </h5>
              <br />
              <h5 style={{ color: "white" }}>
                <i class="fa fa-phone"></i>&nbsp;&nbsp;&nbsp;{data.phone}
              </h5>
              <br />
              <h5 style={{ color: "white" }}>
                <i class="fa fa-envelope"></i>&nbsp;&nbsp;&nbsp;{data.email}
              </h5>
              <br />
              <br />
              <br />
              <div
                style={{
                  padding: "20px 0px",
                  margin: "15px -50px",
                  backgroundColor: "#000080",
                }}
              >
                <h4 style={{ color: "white", padding: "0px 50px" }}>Skills</h4>
              </div>
              <br />
              {skills.length > 0 &&
                skills.map((item, index) => {
                  return (
                    <>
                      <h5 style={{ color: "white" }} key={index}>
                        {item.skill}
                      </h5>
                      <br />
                    </>
                  );
                })}
              <br />
              <br />
              <br />
              <div
                style={{
                  padding: "20px 0px",
                  margin: "15px -50px",
                  backgroundColor: "#000080",
                }}
              >
                <h4 style={{ color: "white", padding: "0px 50px" }}>
                  Languages
                </h4>
              </div>
              <br />

              {language.length > 0 &&
                language.map((item, index) => {
                  return (
                    <>
                      <h5 style={{ color: "white" }} key={index}>
                        {item}
                      </h5>
                      <br />
                    </>
                  );
                })}
            </div>
            <div class="col-8" style={{ padding: "50px" }}>
              <h5
                style={{
                  whiteSpace: "pre-wrap",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  color: "#808080",
                }}
              >
                {about}
              </h5>
              <br />

              {experience.length > 0 && (
                <>
                  <hr />
                  <h4>Work Exprerience</h4>
                  <br />
                </>
              )}
              {experience.length > 0 &&
                experience.map((item, index) => {
                  return (
                    <div class="single-exp mb-30" key={index}>
                      <h5>{item.title} </h5>
                      <h5 style={{ color: "#808080" }}>{item.company}</h5>
                      <h5 style={{ color: "#808080" }}>
                        {item.start} - {item.leave}
                      </h5>
                      <h5
                        style={{
                          whiteSpace: "pre-wrap",
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          color: "#808080",
                        }}
                      >
                        {item.achieve}
                      </h5>
                    </div>
                  );
                })}

              {education.length > 0 && (
                <>
                  <hr />
                  <h4>Education</h4>
                  <br />
                </>
              )}

              {education.length > 0 &&
                education.map((item, index) => {
                  return (
                    <div class="single-exp mb-30" key={index}>
                      <h5>{item.degree} </h5>
                      <h5 style={{ color: "#808080" }}>{item.university}</h5>
                      <h5 style={{ color: "#808080" }}>
                        {item.start} - {item.leave}
                      </h5>
                      <h5
                        style={{
                          whiteSpace: "pre-wrap",
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          color: "#808080",
                        }}
                      >
                        {item.achieve}
                      </h5>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return { candidate: state.candidate, employee: state.employee };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestLogin,
      requestEmpGetCandidate,
      requestAddResume,
      requestFormField,
      requestCandidateResume,
      requestEditReferences,
      requestEditExperienceCertificates,
      requestEditProfAcadCertificates,
      requestEditAwards,
      requestEditTestimonials,
      requestEditAccolades,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportingDocuments);

import Footer from "../Components/footer";
import Header from "../Components/header";
import { RWebShare } from "react-web-share";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  requestEmpLogin,
  requestLogin,
  requestCountLastweekJob,
  requestRecentlyJob,
  requestCategory,
  requestAddBookmark,
  userLogout
} from "../Redux/actions";
import WOW from "wowjs";
import img from "../images/emp.jpg";
import image from "../images/extraLogo.png";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import Swal from "sweetalert2";
import About from "../Section/aboutUsSection";
import Apply from "../Section/applyProcessSection";
import Jobcategory from "../Section/jobCategorySection";
import Client from "../Section/clientLogoSection";
import Testimonials from "../Section/Testimonials";
// import { FaClock } from "react-icons/fa6";

import { LuClock12 } from "react-icons/lu";



function Home(props) {
  useEffect(() => {
    new WOW.WOW().init();
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [user, setUSer] = useState({});
  const [emp, setEmp] = useState({});
  const [jobcount, setjobcount] = useState(0);
  const [jobs, setjobs] = useState([]);

  useEffect(() => {
    props.requestCountLastweekJob();
    props.requestCategory();
    props.requestRecentlyJob({});
  }, []);

  useEffect(() => {
    let loginData = props.candidate.loginData;
    if (loginData !== undefined) {
      if (loginData?.data?.status == "success") {
        setUSer(loginData.data.data);
        props.requestRecentlyJob({
          token: loginData.data.data.token,
        });
      }
    } else {
      props.requestRecentlyJob({});
    }
  }, []);

  useEffect(() => {
    let empLoginData = props.employee.empLoginData;
    if (empLoginData !== undefined) {
      if (empLoginData?.data?.status == "success") {
        setEmp(empLoginData.data.data);
      }
    }
  }, [props.employee.empLoginData]);

  useEffect(() => {
    let loginData = props.candidate.loginData;
    if (loginData !== undefined) {
      if (loginData?.data?.status == "success") {
        setUSer(loginData.data.data);
      }
    }
  }, [props.candidate.loginData]);

  useEffect(() => {
    let lastweekjob = props.candidate.lastWeekJobCount;
    if (lastweekjob !== undefined) {
      if (lastweekjob?.data?.status == "success") {
        setjobcount(lastweekjob.data.data);
      }
    }
  }, [props.candidate.lastWeekJobCount]);

  useEffect(() => {
    let recentlyjob = props.candidate.recentlyAddedJobData;
    if (recentlyjob !== undefined) {
      if (recentlyjob?.data?.status == "success") {
        setjobs(recentlyjob.data.data);
      }
    }
  }, [props.candidate.recentlyAddedJobData]);

  function bookmarkJobs(id) {
    if (user.id) {
      props.requestAddBookmark({
        token: user.token,
        id: id,
        data: {},
      });
    } else {
      localStorage.setItem("link", `/home`);
      navigate("/login");
    }
  }

  useEffect(() => {
    if (user.id) {
      let addBookmarkedData = props.candidate.addBookmarkedData;
      if (addBookmarkedData !== undefined) {
        if (addBookmarkedData?.data?.status == "success") {
          Swal.fire("Good job!", "Bookmarked for Job successfully.", "success");
          props.candidate.addBookmarkedData = undefined;
          let loginData = props.candidate.loginData;
          if (loginData !== undefined) {
            if (loginData?.data?.status == "success") {
              setUSer(loginData.data.data);
              props.requestRecentlyJob({
                token: loginData.data.data.token,
              });
            }
          } else {
            props.requestRecentlyJob();
          }
        } else {
          Swal.fire("Error!", "Already bookmarked for the job.", "error");
          props.candidate.addBookmarkedData = undefined;
        }
      }
    } else {
      let addBookmarkedData = props.candidate.addBookmarkedData;
      if (addBookmarkedData !== undefined) {
        if (addBookmarkedData.data.status == "Token is Expired") {
          props.userLogout()
        }
      }
    }
  }, [props.candidate.addBookmarkedData]);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });
  const [categories, setCategories] = useState([]);
  const [items, setitems] = useState([]);
  const [state, setState] = useState({
    suggestions: [],
    text: "",
  });
  useEffect(() => {
    let categoryData = props.candidate.categoryData;
    if (categoryData) {
      if (categoryData?.data?.status == "success") {
        setCategories(categoryData.data.data.categories);
      }
    }
  }, [props.candidate.categoryData]);
  useEffect(() => {
    {
      categories.map((item1) => {
        if (items.indexOf(item1.name) === -1) {
          items.push(item1.name);
        }
      });
    }
  }, [categories]);
  function suggestionSelected(value) {
    setState(() => ({
      text: value,
      suggestions: [],
    }));
  }

  const onTextChanged = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = items.sort().filter((v) => regex.test(v));
    }
    setState(() => ({ suggestions, text: value }));
  };

  function renderSuggestionsTitle() {
    const { suggestions } = state;
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <div className="srchList">
        <div
          style={{
            backgroundColor: "white",
            margin: "0px -2px",
            marginTop: "1px",
            border: "2px solid rgba(13, 110, 253, .25)",
            borderRadius: "3px",
            padding: "5px",
            cursor: "pointer",
          }}
        >
          <ul>
            {suggestions.map((item) => (
              <li
                style={{ fontSize: "14px", color: "black" }}
                onClick={() => suggestionSelected(item)}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
      () => {
        setValue(description, false);
        clearSuggestions();
      };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      const text = main_text + ", " + secondary_text;
      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <small style={{ fontSize: "14px", color: "black" }}>
            {text.length > 20 ? text.substring(0, 20) + "..." : text}
          </small>
        </li>
      );
    });

  function submitForm(e) {
    e.preventDefault();
    localStorage.setItem("jobKeyword", state.text);
    localStorage.setItem("jobLocation", value);
    navigate("/browseJobs");
  }

  function commentFunction(id) {
    localStorage.setItem("comment", "comment");
    navigate(`/jobDetails/${id}`);
  }

  return (
    <>
      <Header name="Home" />
      <section class="hero-area two bg-SectionClr">
      <div className="handshakeImg">
               <img src= {process.env.PUBLIC_URL + "/assets/images/hero/shakinghand.jpg"} alt="" className="img-fluid" />
               {/* <img src= process.env.PUBLIC_URL + "/assets/images/hero/shakinghand.png" alt="" className="img-fluid" /> */}
               </div>

        <div class="hero-inner two ">
          <div class="container">
            {emp.id ? (
              // employee login page home

                // <div class="row ">
              //   <div class="justify-content-center col-lg-8 co-8">
              //     <div class="inner-content">
              //       <div class="hero-text ">
              //         <h1 class="wow fadeInUp" data-wow-delay=".3s">

              //           Perfect Way To Find  <br />The Right Candidate At The Faster Pace
              //         </h1>
              //         <p>With the help of technology, human insights and faster <br />
              //           Innovative processes</p>
              //         <br />
              //         <br />
              //         <div class="job-search-form">
              //           <a class=" wow fadeInUp submit-btn" href="/postJob">
              //             <button class="btn btn-primary" type="submit">
              //               Post A Job
              //             </button>
              //           </a>
              //         </div>
              //       </div>
              //       <div
              //         class="job-search-wrap-two mt-50 wow fadeInUp"
              //         data-wow-delay=".7s"
              //       ></div>
              //     </div>
              //   </div>
              //   <div class="col-lg-6 co-12">
              //     <div
              //       class="hero-video-head wow fadeInRight"
              //       data-wow-delay=".5s"
              //     >
              //       <div class="video-inner">
              //         <img src={img} alt="#" height="auto" width="100%" />
              //       </div>
              //     </div>
              //   </div>
              // </div>


             <div className="row justify-content-center heroImgHadnShake">
              <div class=" col-lg-12">
                  <div class="inner-content text-center">
                  <div class="hero-text">
                      <h1 class="wow fadeInUp" data-wow-delay=".3s">
                        {/* Perfect Way To Find   The <span className=" d-inline d-lg-block">Right Candidate</span> At The Faster Pace */}
                        Get Hired In 60 Minutes
                      </h1>
                      <p>
                      A rewarding career option needs the right break and exposure. We match your skill set against an advanced set of tools to tailor the best fit employment opportunities to give your career the right trajectory of high growth.
                      </p>


              <div className="justify-content-center d-flex hireOrJob gap-3 mt-5">
              <div class="button  mt-0"><a href="/job-seeker-landing"> <button type="submit" class="btn ">Get A Job</button></a></div>
              <div class="button mt-0 "><a href="/emp-landing"> <button type="submit" class="btn ">Hire Now</button></a></div>
              </div>
                
                    </div>
                    <div
                      class="job-search-wrap-two  wow fadeInUp"
                      data-wow-delay=".7s"
                    >
                      
                    </div>
                    <div class="row">
                      <div
                        class="pr-0"
                        style={{ position: "relative", width: "37%" }}
                      >
                        <div class="col-md-12 justify-content-md-center">
                          {renderSuggestionsTitle()}
                        </div>
                      </div>
                      <div style={{ position: "relative", width: "37%" }}>
                        {status === "OK" && (
                          <div
                            style={{
                              backgroundColor: "white",
                              margin: "0px -2px",
                              marginTop: "1px",
                              border: "2px solid rgba(13, 110, 253, .25)",
                              borderRadius: "3px",
                              padding: "5px 5px",
                              cursor: "pointer",
                            }}
                          >
                            <ul>{renderSuggestions()}</ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
             </div>
               // End employee login page home
            ) : (
              <div class="row justify-content-center heroImgHadnShake">

                <div class=" col-lg-12">
                  <div class="inner-content text-center">
                    <div class="hero-text">
                      <h1 class="wow fadeInUp" data-wow-delay=".3s">
                        {/* Perfect Way To Find   The <span className=" d-inline d-lg-block">Right Candidate</span> At The Faster Pace */}
                        Get Hired In 60 Minutes
                      </h1>
                      <p>
                      A rewarding career option needs the right break and exposure. We match your skill set against an advanced set of tools to tailor the best fit employment opportunities to give your career the right trajectory of high growth.
                      </p>


              <div className="justify-content-center d-flex hireOrJob gap-3 mt-5">
              <div class="button  mt-0"><a href="/job-seeker-landing"> <button type="submit" class="btn ">Get A Job</button></a></div>
              <div class="button mt-0 "><a href="/emp-landing"> <button type="submit" class="btn ">Hire Now</button></a></div>
              </div>
                
                    </div>
                    <div
                      class="job-search-wrap-two  wow fadeInUp"
                      data-wow-delay=".7s"
                    >
                      
                    </div>
                    <div class="row">
                      <div
                        class="pr-0"
                        style={{ position: "relative", width: "37%" }}
                      >
                        <div class="col-md-12 justify-content-md-center">
                          {renderSuggestionsTitle()}
                        </div>
                      </div>
                      <div style={{ position: "relative", width: "37%" }}>
                        {status === "OK" && (
                          <div
                            style={{
                              backgroundColor: "white",
                              margin: "0px -2px",
                              marginTop: "1px",
                              border: "2px solid rgba(13, 110, 253, .25)",
                              borderRadius: "3px",
                              padding: "5px 5px",
                              cursor: "pointer",
                            }}
                          >
                            <ul>{renderSuggestions()}</ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
               
              </div>
            )}


          </div>
        </div>
      </section>

  

      {/* ========================= Start Get On Highway To Growth Section ======================= */}
      <section className="common-spacing">
        <div className="container">
          <div className="section-title" >
            <h2 class="wow fadeInUp" data-wow-delay=".4s">Get on <span className="headingHighLight"> Highway to</span> Growth</h2>
            {/* <p>
            A rewarding career option needs the right break and exposure. We match your skill set against an advanced set of tools to tailor the best fit employment opportunities to give your career the right trajectory of high growth.
            </p> */}
          </div>

          <div className="main">
          <ul class="infoGraphic mb-0">
                    <li>
                        <div class="numberWrap">
                            <div class="number">1</div>
                            <div class="coverWrap">
                                <div class="numberCover"></div>
                            </div>
                        </div>
                        <div class="content">
                            <div class="icon iconCodepen">
                            <i class="fa fa-briefcase" aria-hidden="true"></i>
                              </div>
                            <h2>Wide Array Of Careers</h2>
                            {/* <p>Add new leads, select source, assign it to any user.</p> */}
                        </div>
                    </li>
                    <li>
                        <div class="numberWrap">
                            <div class="number">2</div>
                            <div class="coverWrap">
                                <div class="numberCover"></div>
                            </div>
                        </div>
                        <div class="content">
                            <div class="icon iconSocial">
                            <i class="fa fa-users" aria-hidden="true"></i>
                             </div>
                            <h2>Verified And Reputed Employers</h2>
                           
                        </div>
                    </li>
                    <li>
                        <div class="numberWrap">
                            <div class="number">3</div>
                            <div class="coverWrap">
                                <div class="numberCover"></div>
                            </div>
                        </div>
                        <div class="content">
                            <div class="icon iconAirplane">
                            <i class="fa fa-id-card" aria-hidden="true"></i>
                              </div>
                            <h2>Best-In-Class Profile Matching</h2>
                            
                        </div>
                    </li>
                    <li>
                        <div class="numberWrap">
                            <div class="number">4</div>
                            <div class="coverWrap">
                                <div class="numberCover"></div>
                            </div>
                        </div>
                        <div class="content">
                            <div class="icon iconMap">
                            <i class="fa fa-desktop" aria-hidden="true"></i>
                              </div>
                            <h2>Virtual Selection</h2>
                        </div>
                    </li>
                    <li>
                        <div class="numberWrap">
                            <div class="number">5</div>
                            <div class="coverWrap">
                                <div class="numberCover"></div>
                            </div>
                        </div>
                        <div class="content">
                            <div class="icon iconBulb">
                            <i class="fa fa-window-restore" aria-hidden="true"></i>
                             </div>
                            <h2>Advanced Tech Process</h2>
                           
                        </div>
                    </li>
                  
                </ul>
          </div>
        
          <div class="button text-center gethiredbtn">
            <a href="#0"> <button type="submit" class="btn ">Get hired in Record Time</button></a>
            </div>
        
        </div>

      </section>
      {/* ========================= End Get On Highway To Growth Section ======================= */}

      {emp.id ? (
       <>
        {/* <section class="apply-process  common-spacing">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-12">
                <a href="/empregister">
                  {" "}
                  <div class="process-item">
                    <i class="lni lni-user"></i>
                    <h4>Register Your Account</h4>
                    <p>
                      Register here, to get hire in 60 minutes.
                    </p>
                  </div>
                </a>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <a href="/empProfile">
                  {" "}
                  <div class="process-item">
                    <i class="lni lni-book"></i>
                    <h4>Upload Your Profile</h4>
                    <p>
                      Upload your resume and other collateral.
                    </p>
                  </div>
                </a>
              </div>
              <div class="col-lg-4 col-md-4 col-12">
                <a href="/postJob">
                  <div class="process-item">
                    <i class="lni lni-briefcase"></i>
                    <h4>Post your Jobs</h4>
                    <p>
                      It is an official advertisement regarding a job opening or
                      vacancy.
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section> */}

    {/* Start new section upload  */}
    <section className="common-spacing bg-SectionClr">
       <div className="container">
        <div className="align-items-center justify-content-center row">
          <div className="col-12 col-md-12 col-lg-4 mb-4 ">
          <div class="pxp-services-1-item text-center pxp-animate-icon"><div class="pxp-services-1-item-icon">
            <img decoding="async" src= {process.env.PUBLIC_URL + "/assets/images/public-newimages/service-1-icon-3.png"} alt="I am a candidate" class="" />
              </div><div class="pxp-services-1-item-title">
              Register Your Account
                 </div>
              <div class="pxp-services-1-item-text pxp-text-light">
              Register here, to get hire in 60 minutes.
              </div>
              <div class="pxp-services-1-item-cta">
              <div class="button  "><a href="/empregister"> <button type="submit" class="btn ">Register Now</button></a></div>
                    </div>
           </div>
          </div>
          {/* End card upload resume  */}
          <div className="col-12 col-md-12 col-lg-4 mb-4 ">
          <div class="pxp-services-1-item text-center pxp-animate-icon"><div class="pxp-services-1-item-icon">
            <img decoding="async" src= {process.env.PUBLIC_URL + "/assets/images/public-newimages/service-1-icon-1.png"} alt="I am a candidate" class="" />
              </div><div class="pxp-services-1-item-title">
              Upload Your Profile
                 </div>
              <div class="pxp-services-1-item-text pxp-text-light">
              Upload your resume and other collateral.
              </div>
              <div class="pxp-services-1-item-cta">
              <div class="button  "><a href="/empProfile"> <button type="submit" class="btn ">Upload Resume</button></a></div>
                    </div>
           </div>
          </div>
          {/* End card upload resume  */}
          <div className="col-12 col-md-12 col-lg-4 mb-4 ">
          <div class="pxp-services-1-item text-center pxp-animate-icon"><div class="pxp-services-1-item-icon">
            <img decoding="async" src= {process.env.PUBLIC_URL + "/assets/images/public-newimages/postyour-jobs-2.png"} alt="I am a candidate" class="" />
              </div><div class="pxp-services-1-item-title">
              Post Your Jobs
                 </div>
              <div class="pxp-services-1-item-text pxp-text-light">
              It is an official advertisement regarding a job opening or vacancy.
              </div>
              <div class="pxp-services-1-item-cta">
              <div class="button  "><a href="/postJob"> <button type="submit" class="btn ">Post Jobs Now</button></a></div>
                    </div>
           </div>
          </div>
          {/* End card upload resume  */}
        </div>
       </div>
    </section>

    
       </>
    //  End new section upload 

      ) : (
        <Apply />
      )}

      {!emp.id && (
        <>
          <Jobcategory />

          {/* <section>
            <div className="container">
              
            <div className="row mb-100 pt-5 fast-eff">
              <div className="co-12">
                <div className="section-title" style={{ marginBottom:'1rem' }}>
                  <h2 class="wow fadeInUp" data-wow-delay=".4s">Fast and seamless talent acquisition  with in 60 minutes</h2>
                </div>
              </div>
              <div className="col-lg-6 co-12">
                <div class="fe-ta-container">
                    <div class="missionSec">
                        <div class="main-img">
                            <img src= process.env.PUBLIC_URL + "/assets/images/testimonial/pre-screened-candidate.png" alt="" />
                        </div>
                        <div class="missionSec-text">
                            <h6>PRE SCREENED PROFILE</h6>
                        </div>
                    </div>
                    <div class="missionSec">
                        <div class="main-img">
                            <img src= process.env.PUBLIC_URL + "/assets/images/testimonial/profile.png" alt="" />
                        </div>
                        <div class="missionSec-text">
                            <h6>VIDEO PROFILES</h6>
                        </div>

                    </div>
                    <div class="missionSec">
                        <div class="main-img">
                            <img src= process.env.PUBLIC_URL + "/assets/images/testimonial/innovative-assignments-tools.png" alt="" />
                        </div>
                        <div class="missionSec-text">
                            <h6>INNOVATIVE ASSESSMENT TOOLS</h6>
                        </div>

                    </div>
                    <div class="missionSec">
                        <div class="main-img">
                            <img src= process.env.PUBLIC_URL + "/assets/images/testimonial/virtual-interview.png" alt="" />
                        </div>
                        <div class="missionSec-text">
                            <h6>VIRTUAL INTERVIEW</h6>
                        </div>

                    </div>
                    <div class="missionSec">
                        <div class="main-img">
                            <img src= process.env.PUBLIC_URL + "/assets/images/testimonial/verified-profile.png" alt="" />
                        </div>
                        <div class="missionSec-text">
                            <h6>VERIFIED JobSeeker FOR QUICK RECRUITMENT</h6>
                        </div>
                    </div>
                </div>
                <div>
                <p>Finding the right manpower at the right time can help a business grow exponentially.
                                    Apart from right age, skills, experience and aptitude, we pre filter the candidates
                                    for you on more than 30 dynamic parameters to offer you the best fit.</p>
                </div>
              </div>
              <div className="col-lg-6 co-12">
                <a href="#0">
                  <img src= process.env.PUBLIC_URL + "/assets/images/testimonial/img.png" alt="project" />
                </a>
              </div>
            </div>

            </div>
          </section> */}



          {/* <About /> */}
  {/* =========== Start New Get Hired in 60 minutes ======= */}
   <section className="common-spacing ">
  <div className="container">
  <div class="section-title ">
        <h2 class="wow fadeInUp" data-wow-delay=".4s" >Get Hired in  <span class="headingHighLight"> 60 Minutes </span>  for your Dream Job </h2>
      </div>
    <div className="row row py-0  py-md-4">
    <div className="col-12 col-md-12 col-lg-4 mb-4">
    <div class="starter__content">
      <ul>
      <li>
          <div class="starter__item d-flex gap-3">
            <div class="starter__icon mr-25"><i class="fa fa-question" aria-hidden="true"></i></div>
            <div class="starter__text">
              <h4>Are you happy in your <br /> current job?</h4>
              {/* <p>But must expla to you how this mistaken idea of denouncing pleure praising</p> */}
              {/* <a class="link-btn" href="#0"><span>We Can Help</span><i class="fas fa-arrow-right"></i></a> */}
              </div>
              </div>
          </li>
          {/* end list */}
        <li>
          <div class="starter__item d-flex gap-3">
            <div class="starter__icon mr-25">
            <i class="fa fa-briefcase" aria-hidden="true"></i>
              </div>
            <div class="starter__text">
              <h4>Are you looking for a job?</h4>
              {/* <p>But must expla to you how this mistaken idea of denouncing pleure praising</p> */}
              {/* <a class="link-btn" href="#0"><span>We Can Help</span><i class="fas fa-arrow-right"></i></a> */}
              </div>
              </div>
          </li>
          {/* end list */}
          <li>
          <div class="starter__item d-flex gap-3">
            <div class="starter__icon mr-25"><i class="fa fa-list-alt" aria-hidden="true"></i></div>
            <div class="starter__text">
              <h4>Do you have a CV which Employers are looking for?</h4>
              {/* <p>But must expla to you how this mistaken idea of denouncing pleure praising</p> */}
              {/* <a class="link-btn" href="#0"><span>We Can Help</span><i class="fas fa-arrow-right"></i></a> */}
              </div>
              </div>
          </li>
          {/* end list */}
          <li>
          <div class="starter__item d-flex gap-3">
            <div class="starter__icon mr-25"><i class="fa fa-video-camera" aria-hidden="true"></i></div>
            <div class="starter__text">
              <h4>Do you have a short Video Pitch?</h4>
              {/* <p>But must expla to you how this mistaken idea of denouncing pleure praising</p> */}
              {/* <a class="link-btn" href="#0"><span>We Can Help</span><i class="fas fa-arrow-right"></i></a> */}
              </div>
              </div>
          </li>
          {/* end list */}
      
         
          </ul>
          </div>
    </div>
    {/* End left item */}
    {/* Start center item */}
     <div className="col-12 col-md-12 col-lg-4 mb-4 text-center">
     <img alt="starter-img" src= { process.env.PUBLIC_URL + "/assets/images/public-newimages/hirein60minutes-dreamjob.png"} />
     <div class="button text-center gethiredbtn "><a href="#0" className="w-100"> <button type="submit" class="btn w-100">We Can Help</button></a></div>
     </div>
    {/* End center item */}
    {/* Start Right item */}
    <div className="col-12 col-md-12 col-lg-4 mb-4">
    <div class="starter__content">
      <ul>
      <li>
          <div class="starter__item d-flex gap-3">
            <div class="starter__icon mr-25"><i class="fa fa-list" aria-hidden="true"></i></div>
            <div class="starter__text">
              <h4>Do you have skill evaluation report?</h4>
              {/* <a class="link-btn" href="#0"><span>We Can Help</span><i class="fas fa-arrow-right"></i></a> */}
              </div>
              </div>
          </li>
          {/* end item  */}
          <li>
          <div class="starter__item d-flex gap-3">
            <div class="starter__icon mr-25"><i class="fa fa-medkit" aria-hidden="true"></i></div>
            <div class="starter__text">
              <h4>Do you have your wellness report?</h4>
              {/* <a class="link-btn" href="#0"><span>We Can Help</span><i class="fas fa-arrow-right"></i></a> */}
              </div>
              </div>
          </li>
          {/* end item  */}
        <li>
          <div class="starter__item d-flex gap-3">
            <div class="starter__icon mr-25"><i class="fa fa-sticky-note" aria-hidden="true"></i></div>
            <div class="starter__text">
              <h4>Do you have a personality report?</h4>
              {/* <a class="link-btn" href="#0"><span>We Can Help</span><i class="fas fa-arrow-right"></i></a> */}
              </div>
              </div>
          </li>
          {/* end item  */}
          </ul>
          </div>
    </div>
    {/* End Right item */}
    </div>
    
  </div>
 </section>
 {/* =========== End New Get Hired in 60 minutes ======= */}



   {/* =========== Start Short list Section upload resume ==============*/}
   <section className="common-spacing bg-SectionClr ">
    <div className="container">
      <div className="resumeCard shortlistCard ">
      <div className="row">
      <div class="col-12 col-md-12 col-lg-5 "><div class="shortlist-shape two"></div>
      <img src= {process.env.PUBLIC_URL + "/assets/images/testimonial/uploadresume.png"} alt="" /></div>
      <div class="col-12 col-md-12 col-lg-7"><div class="section-title leftHeading">
      <h2 class="wow fadeInUp animated" data-wow-delay=".4s" >
        Stop Getting Eliminated  <span class="headingHighLight"> Start Getting </span> Shortlisted</h2>
          </div>
          <h3>Increase your chances of getting into a dream job by 200% and let your dream employers hire you immediately.</h3>
          <div class="button  ">
            <Link to = "/addResumeForm"> 
            <button type="submit" class="btn ">Upload Resume</button>
            </Link>
            </div>
            </div>
      </div>
      </div>
    </div>
   </section>
   {/* =========== End Short list Section upload resume ==============*/}



          {/*---------- Start Third Section -------------------------------------------------  */}
          {/* <section class="call-action overlay section common-spacing">
            <div class="container">
              <div class="row ">
                <div class="col-lg-8 offset-lg-2 col-12">
                  <div class="inner">
                    <div class="section-title">
                      <span class="wow fadeInDown" data-wow-delay=".2s">
                        GETTING STARTED
                      </span>
                      <h2 class="wow fadeInUp" data-wow-delay=".4s">
                        Stop Getting Eliminated... <br /> Start Getting Shortlisted

                      </h2>
                      <p class="wow fadeInUp" data-wow-delay=".6s">
                        Increase your chances of getting into a dream job by 200% and let your dream employers hire you immediately.
                      </p>
                      <div class="button wow fadeInUp" data-wow-delay=".8s">
                        <Link to="/addResumeForm" class="btn">
                          <i class="lni lni-upload"></i> Upload Your Resume
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/*---------- End Third Section -------------------------------------------------  */}

          {/* <section class="find-job job-list section">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="section-title">
                    <span class="wow fadeInDown" data-wow-delay=".2s">
                      Hot Jobs
                    </span>
                    <h2 class="wow fadeInUp" data-wow-delay=".4s">
                      Browse Recent Jobs
                    </h2>
                    <p class="wow fadeInUp" data-wow-delay=".6s">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form.
                    </p>
                  </div>
                </div>
              </div>
              <div class="single-head">
                <div class="row">
                  {jobs.map((item, index) => {
                    const img = item.employee_logo
                      ? process.env.REACT_APP_API_HOST + item.employee_logo
                      : image;
                    return (
                      <>
                        <div class="col-lg-6 col-12" key={index}>
                          <div class="single-job">
                            <div class="job-image">
                              <a href={`/jobDetails/${item.id}`}>
                                <img
                                  src={img}
                                  alt="logo"
                                  height="50"
                                  style={{ marginTop: "5px" }}
                                />
                              </a>
                            </div>
                            <div class="job-content">
                              <h4>
                                <a href={`/jobDetails/${item.id}`}>
                                  {item.title}
                                </a>
                              </h4>
                              <a href={`/jobDetails/${item.id}`}>
                                <p>
                                  {item.description &&
                                    item.description.substring(0, 120)}
                                </p>
                              </a>
                              <ul>
                                <li>
                                  <i class="lni lni-website"></i>
                                  <a
                                    href={`${item.employee_website}`}
                                    target="_blank"
                                  >
                                    {" "}
                                    {item.employee_website}
                                  </a>
                                </li>
                                <li>
                                  <a href={`/jobDetails/${item.id}`}>
                                    {" "}
                                    {item.currency_name} {item.salary_from}-
                                    {item.salary_to}
                                  </a>
                                </li>
                                <li>
                                  <a href={`/jobDetails/${item.id}`}>
                                    {" "}
                                    <i class="lni lni-map-marker"></i>{" "}
                                    {item.city_name}, {item.state_name},{" "}
                                    {item.country_name}
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div class="job-button ">
                              <ul>
                                <li>
                                  <button
                                    style={{
                                      border: "0px",
                                      backgroundColor: "#edf0fd",
                                      color: "#2043e3",
                                      padding: "5px 8px",
                                      borderRadius: "5px",
                                      margin: "2px",
                                    }}
                                    onClick={() => {
                                      bookmarkJobs(item.id);
                                    }}
                                  >
                                    {item.isbookmarkJob ? (
                                      <i class="fa fa-heart"></i>
                                    ) : (
                                      <i class="fa fa-heart-o"></i>
                                    )}
                                  </button>
                                  <button
                                    style={{
                                      border: "0px",
                                      backgroundColor: "#edf0fd",
                                      color: "#2043e3",
                                      padding: "5px 8px",
                                      borderRadius: "5px",
                                      margin: "2px",
                                    }}
                                    onClick={() => {
                                      commentFunction(item.id);
                                    }}
                                  >
                                    <i class="fa fa-commenting-o"></i>
                                  </button>

                                  <RWebShare
                                    data={{
                                      text: "Job Portal",
                                      url: `http://localhost:3000/jobDetails/${item.id}`,
                                      title: "Job Portal",
                                    }}
                                  >
                                    <button
                                      style={{
                                        border: "0px",
                                        backgroundColor: "#edf0fd",
                                        color: "#2043e3",
                                        padding: "5px 9px",
                                        borderRadius: "5px",
                                        margin: "2px",
                                      }}
                                    >
                                      <i class="fa fa-share-alt"></i>
                                    </button>
                                  </RWebShare>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <br />
                <br />
                <div class="row">
                  <div class="col-12">
                    <div class="section-title">
                      <a href={`/jobList/1/10`} style={{ cursor: "pointer" }}>
                        <span class="wow fadeInDown" data-wow-delay=".2s">
                          View More
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <Testimonials />
        </>
      )}

      {/* <Client /> */}
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return { employee: state.employee, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestEmpLogin,
      requestLogin,
      requestCountLastweekJob,
      requestRecentlyJob,
      requestCategory,
      requestAddBookmark,
      userLogout
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);

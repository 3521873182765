import React from "react";
import { useState } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; // Import the styles for the circular progress bar
import progresstype from "./progressData";
import { BsThreeDots } from 'react-icons/bs';

function UserProgres () {

const[progressdata, setProgresData ] = useState(progresstype);

    return (
        <>
        <p style={{  fontSize: '14px', marginBottom: '6px', padding: '0 0 0 26px', fontWeight: 600 }}> Mandatory credentials</p>
            <div className='profile-dashboard additional mb-3'>
<div className="row">

{
    progressdata.map((item)=> {
        return (
            <>
            
            <div className="col">
    <h5 className='text-center userProgress'>{item.name} </h5>
    <div className='additional-progressbar' style={{ width: '100px', height: '100px', position: 'relative' , margin: "0 auto"}}>
                
        <CircularProgressbar  className="multiple-cricularbar thick-stroke"
          value= {item.percentage}
          text={`${item.percentage}%`}
          strokeWidth={12}

          styles={buildStyles({
            textSize: '22px',
            textColor: '#000',
            pathColor: item.pathcolor,
            trailColor: item.trailcolor,
            
          })}

        /> 
</div>
    </div>
    
   
            </>
        )
    })
}


</div>
</div>
        </>
    )
}

export default UserProgres;
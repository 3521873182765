import Footer from "./footer";
import Header from "./header";
import ManageAccount from "./manageAccount";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import ReactPlayer from "react-player";
import VideoUpload from "../DashboardComponent/VideoUpload";
import VideoPreview from "../DashboardComponent/VideoPreview";

import {
  requestLogin,
  requestCandidateLogo,
  requestCandidateVideo,
  requestGetCandidate,
  requestCandidateVideoPitch
} from "../Redux/actions";
import WOW from "wowjs";
import camera from "../images/camera.png";
import img1 from "../images/profile.png";
import Swal from "sweetalert2";
import Breadcrumbs from "../Section/breadcrumbsSection";
import Videopartner from "../DashboardComponent/videopartner";
import MainTopComponent from "../DashboardComponent/topdashboard";

const VideoPitchTwo = (props) => {
  useEffect(() => {
    new WOW.WOW().init();
    // window.scrollTo(0, 0);
    localStorage.removeItem("link");
  }, []);

  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [emp, setEmp] = useState({});
  const [img, setimg] = useState("");
  const [video, setvideo] = useState("");
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    let loginData = props.candidate.loginData;
    if (loginData !== undefined) {
      if (loginData?.data?.status == "success") {
        setEmp(loginData.data.data);
        props.requestGetCandidate({
          id: loginData.data.data.id,
          token: loginData.data.data.token,
        });
      } else {
        localStorage.setItem("link", "/videoresume");
        navigate("/login");
      }
    } else {
      localStorage.setItem("link", "/videoresume");
      navigate("/login");
    }
  }, [props.candidate.loginData]);

  const onSelectFile = (e) => {
    setimg(URL.createObjectURL(e.target.files[0]));
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
    } else {
      setSelectedFile(e.target.files[0]);
    }
  }; 

  function submitForm(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("profile", selectedFile);

    props.requestCandidateVideo({
      id: emp.id,
      data: formData,
      token: emp.token,
    });
  }

  useEffect(() => {
    let getCandidateData = props.candidate.getCandidateData;
    if (getCandidateData !== undefined) {
      if (getCandidateData.data.status == "success") {
        setData(getCandidateData.data.data);
        if (getCandidateData.data.data.video_resume) {
          setvideo(
            process.env.REACT_APP_API_HOST + getCandidateData.data.data.video_resume
          );
        } else {
          setvideo(img1);
        }
      }
    }
  }, [props.candidate.getCandidateData]);




  useEffect(() => {
    let candidatelogo = props.candidate.candidateVideoData;
    if (candidatelogo !== undefined) {
      console.log(candidatelogo.data)
      if (candidatelogo.data!=undefined && candidatelogo.data.status == "success") {
        Swal.fire(
          "Good job!",
          "Video updated Successfully.",
          "success"
        );
        props.candidate.candidateVideoData = undefined;
        props.requestGetCandidate({
          id: emp.id,
          token: emp.token,
        });
        navigate("/");
      } else {
        Swal.fire(
          "Error!",
          "Please select mp4 format for video.",
          "error"
        );

        props.candidate.candidateVideoData = undefined;
        props.requestGetCandidate({
          id: emp.id,
          token: emp.token,
        });
        navigate("/");
      }
    }
  }, [props.candidate.candidateVideoData]);




// Add Edit Save Video 
const [vid, setVideo] = useState(null);

  const handleVideoUpload = (file) => {
    setVideo(file);
  };

  const handleVideoEdit = (file) => {
    setVideo(file);
  };

  const handleVideoRemove = () => {
    setVideo(null);
  };

const onSaveVideo = (file) => {
  // console.log("upload video file" , file);
  // console.log(data.video_resume, "===", file.path)
 let videopitch =  props.requestCandidateVideoPitch(file.path);
 console.log(videopitch , "get >>>>>>>>");
}
// upload video save 
  return (
    <>
      <Header />
      <Breadcrumbs title="Profile Picture" />
      <div class="resume section">
        <div class="container">
        
      <MainTopComponent 
      username = {data.first_name}
      lastname = {data.last_name}
      userimage = {data.profile}
      useraddress = {data.address}
      useraddress2 = {data.address2}
      phone = {data.phone}
      email = {data.email}
      experience = {data.experience}
      notice_period = {data.notice_period}
      pincode = {data.pincode}
      city = {data.city_name}
      state = {data.state_name}
      country = {data.country_name}
      jobtitle = {data.designation_name}
      current_salary = {data.current_salary}
     
      />

          <div class="resume-inner">
            <div class="row">
              <ManageAccount name="WellnessReport" from="submenu2" />

              <div class="col-lg-8 col-12">
                <div class="inner-content">
                  <div class="alerts-inner">
                    <div class="row">
                    <h3 class="dashborardpage-subtitile">Personality Score</h3>
              
     <div className="col-lg-12 col-12">
         {vid ? (
        <VideoPreview vid={vid} onVideoUpload={handleVideoEdit} onVideoRemove={handleVideoRemove} onSaveVideo = {onSaveVideo}  />
      ) : (
        <VideoUpload onVideoUpload={handleVideoUpload} />
      )}
</div>

<Videopartner/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestLogin,
      requestCandidateLogo,
      requestCandidateVideo,
      requestGetCandidate,
      requestCandidateVideoPitch,
    },
    dispatch
  );

  export default connect(mapStateToProps, mapDispatchToProps)(VideoPitchTwo);


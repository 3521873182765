import Footer from "./footer";
import Header from "./header";
import ManageAccount from "./manageAccount";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestLogin, requestGetCandidate } from "../Redux/actions";
import { Document, Page, pdfjs } from "react-pdf";
// import pdf from './nency.pdf'
import WOW from "wowjs";
import Breadcrumbs from "../Section/breadcrumbsSection";
import MainTopComponent from "../DashboardComponent/topdashboard";


function Resume(props) {
  
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [data, setData] = useState({});
  const [pdf, setPdf] = useState("");
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    new WOW.WOW().init();
     window.scrollTo(200, 300);
    localStorage.removeItem("link");
  }, []);

  useEffect(() => {
    let loginData = props.candidate.loginData;
    if (loginData !== undefined) {
      if (loginData?.data?.status == "success") {
        setUser(loginData.data.data);
        props.requestGetCandidate({
          token: loginData.data.data.token,
          id: loginData.data.data.id,
        });
      } else {
        localStorage.setItem("link", "/resume");
        navigate("/login");
      }
    } else {
      localStorage.setItem("link", "/resume");
      navigate("/login");
    }
  }, [props.candidate.loginData]);

  useEffect(() => {
    let getCandidateData = props.candidate.getCandidateData;
    console.log("data======", getCandidateData)
    if (getCandidateData !== undefined) {
      if (getCandidateData?.data?.status == "success") {
        setData(getCandidateData.data.data);
        console.log("data",getCandidateData.data.data)
        setPdf(process.env.REACT_APP_API_HOST + getCandidateData.data.data.resume);
      }
    }
  }, [props.candidate.getCandidateData]);

  
console.log("data get here >>>>>>>>>>", data)
  
  return (
    <>
      <Header />
      <Breadcrumbs title="My Resume" />
      <div class="bookmarked section">
        <div class="container">

      <MainTopComponent 
      username = {data.first_name}
      lastname = {data.last_name}
      userimage = {data.profile}
      useraddress = {data.address}
      useraddress2 = {data.address2}
      phone = {data.phone}
      email = {data.email}
      experience = {data.experience}
      notice_period = {data.notice_period}
      pincode = {data.pincode}
      city = {data.city_name}
      state = {data.state_name}
      country = {data.country_name}
      />

      
          <div class="alerts-inner">
            <div class="row">
              <ManageAccount name="Resume" from="submenu" />
              <div class="col-lg-8 col-12">
                <div class="job-items">
                  <h2 className="titleDashPage">
                    Resume of {data.first_name} {data.last_name}{" "}
                    {/* <a href={pdf}>Load pdf</a> */}
                  </h2>
                 
                  {data.resume ? (
                    <iframe src={pdf} width="100%" height="900" />
                  ) : (
                    <p>Resume is not Uploaded.</p>
                  )}

                  {/* <Document
                    file={pdf}
                    // options={{ workerSrc: "./pdf.worker.js" }}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                    ))}
                  </Document> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestLogin,
      requestGetCandidate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Resume);

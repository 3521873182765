// eslint-disable-next-line no-unused-vars
import React from "react";
import { Link } from "react-router-dom";
import { LiaSearchPlusSolid } from 'react-icons/lia';

function Skillspartner (){
   return (
    <>
       <div className="col-lg-12 col-12">
       <div className="partner-component ">
           <div className="row">
            <div className="col-2  w-auto">
                <div className="partner-icon">
                    <LiaSearchPlusSolid/>
                </div>
            </div>
            <div className="col-10 ps-0">
                <div className="partner-description">
                     <h5 className="mb-2">Get your Skills evaluated!</h5>
                     <p>Seek the trusted professionals to assess your skills. 
                     {/* <br />These professionals can help you best assessed and put you a step ahead. */}

                     
                     <div className=" connectTopartner-btn">
                    <span className="contptr-text">Connect with trusted professional partners.</span>
                    <span className="button"><Link to = "" className="btn small" 
                      
                     >Connect</Link></span>
                    </div>
                     
                     {/* <span className="mt-1">Connect with trusted professional partners.</span>
                     <span className="button ms-2"><Link to = "" className="btn" 
                      style={{ padding: "10px 16px", fontSize: "12px" }}
                     >Connect</Link></span> */}
                     </p>
                </div>
            </div>
            {/* <div className="col-3">
                <div className="text-end partner-link"><Link to = "" className="color-theme">Create Video</Link></div>
            </div> */}
           </div>
        </div>
       </div>
    </>
   )
}

export default Skillspartner;

import React from "react";
import { MdCancel } from 'react-icons/md';


function Searchbar({
  show,
  hideFilterBar,
  career_level,
  functional_area,
  industry,
  states,
  cities,
  onChangeState,
  handleInputSearch,
  searchSubmit,
  search
}) {
  const showHideClassName = "";
  // const showHideClassName = show ? "modal-block" : "modal-hide";

  return (
    <div class={`searchBar ${showHideClassName}`} style={{ 
      width: show ? "25%" : "0%"
     }}>
      <div class="sidebarTop">
        <div>
          <div onClick={hideFilterBar} class="closeBar filtercrossbtn">
            <MdCancel />
          </div>
        </div>
        <div class="col-lg-12 col-md-12">
          <div class="form-group">
            <label>Career Level</label>
            <select
              class="select"
              name="career_level"
                 value={search[0].career_level}
              //   onBlur={validatecareer_level}
              onChange={(e) => handleInputSearch(e)}
            >
              <option value="0">Select Industry</option>
              {career_level.map((option) => (
                <option value={option.id}>{option.level}</option>
              ))}
            </select>
          </div>
        </div>

        <div class="col-lg-12 col-md-12">
          <div class="form-group">
            <label>Industry</label>
            <select
              class="select"
              name="industry"
                 value={search[0].industry}
              //   onBlur={validateindustry}
              onChange={(e) => handleInputSearch(e)}
            >
              <option value="0">Select Industry</option>
              {industry.map((option) => (
                <option value={option.id}>{option.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div class="col-lg-12 col-md-12">
          <div class="form-group">
            <label>Functional Area</label>
            <select
              class="select"
              name="functional_area"
                value={search[0].functional_area}
              //   onBlur={validatefunctional_area}
              onChange={(e) => handleInputSearch(e)}
            >
              <option value="0">Select Ownership Type</option>
              {functional_area.map((option) => (
                <option value={option.id}>{option.name}</option>
              ))}
            </select>
          </div>
        </div>

        {/* <div class="col-lg-6 col-md-6">
          <div class="form-group">
            <label>State</label>
            <select
              class="select"
              name="state"
              //   value={stateId}
              //   onBlur={validatestate}
              onChange={(e) => handleInputSearch(e)}
            >
              <option value="0">Select State</option>
              {states.map((option) => (
                <option value={option.id}>{option.name}</option>
              ))}
            </select>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="form-group">
            <label>City</label>
            <select
              class="select"
              name="city"
              //   value={cityId}
              //   onBlur={validatecity}
              onChange={(e) => handleInputSearch(e)}
            >
              <option value="0">Select City</option>
              {cities.map((option) => (
                <option value={option.id}>{option.name}</option>
              ))}
            </select>
          </div>
        </div> */}
        <div class="col-lg-8 col-md-8">
          <div
            // style={{ color: "black" }}
            class="form-group"
          >
            <label for="gender" class="label">
              Employment Type
            </label>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                style={{ margin: "0px" }}
                // id="genderMale"
                name="employment_type"
                value="1"
                // onBlur={validateGender}
                onChange={(e) => handleInputSearch(e)}
                 checked={parseInt(search[0].employment_type) === 1}
              />
              <label class="form-check-label" for="inlineRadio1">
                Work from home
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                style={{ margin: "0px" }}
                // id="genderFemale"
                name="employment_type"
                value="2"
                // onBlur={validateGender}
                onChange={(e) => handleInputSearch(e)}
                checked={parseInt(search[0].employment_type) === 2}
              />
              <label class="form-check-label" for="inlineRadio2">
                Permanent
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                style={{ margin: "0px" }}
                // id="genderAny"
                name="employment_type"
                value="3"
                // onBlur={validateGender}
                onChange={(e) => handleInputSearch(e)}
                checked={parseInt(search[0].employment_type) === 3}
              />
              <label class="form-check-label" for="inlineRadio3">
                Contractual
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                style={{ margin: "0px" }}
                // id="genderAny"
                name="employment_type"
                value="4"
                // onBlur={validateGender}
                onChange={(e) => handleInputSearch(e)}
                checked={parseInt(search[0].employment_type) === 4}
              />
              <label class="form-check-label" for="inlineRadio3">
                Internship
              </label>
            </div>


            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                style={{ margin: "0px" }}
                // id="genderAny"
                name="employment_type"
                value="0"
                //onBlur={validateGender}
                onChange={(e) => handleInputSearch(e)}
                checked={search[0].employment_type === ""}
                //checked={parseInt(data.employment_type) === 4}
              />
              <label class="form-check-label" for="inlineRadio3">
                None
              </label>
            </div>
            {/* {errorGender && (
                              <div style={mystyle}>{errorGender}</div>
                            )} */}
          </div>
        </div>

        <div class="col-lg-8 col-md-8">
          <div style={{ color: "black" }} class="form-group">
            <label for="gender" class="label">
              Notice Period
            </label>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                style={{ margin: "0px" }}
                // id="genderMale"
                name="notice_period"
                value="1"
                // onBlur={validateGender}
                onChange={(e) => handleInputSearch(e)}
                checked={parseInt(search[0].notice_period) === 1}
              />
              <label class="form-check-label" for="inlineRadio1">
                Immediate
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                style={{ margin: "0px" }}
                // id="genderFemale"
                name="notice_period"
                value="2"
                // onBlur={validateGender}
                onChange={(e) => handleInputSearch(e)}
                checked={parseInt(search[0].notice_period) === 2}
              />
              <label class="form-check-label" for="inlineRadio2">
                1 Months
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                style={{ margin: "0px" }}
                // id="genderAny"
                name="notice_period"
                value="3"
                // onBlur={validateGender}
                onChange={(e) => handleInputSearch(e)}
                checked={parseInt(search[0].notice_period) === 3}
              />
              <label class="form-check-label" for="inlineRadio3">
                2 Months
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                style={{ margin: "0px" }}
                // id="genderAny"
                name="notice_period"
                value="4"
                // onBlur={validateGender}
                onChange={(e) => handleInputSearch(e)}
                checked={parseInt(search[0].notice_period) === 4}
              />
              <label class="form-check-label" for="inlineRadio3">
                3 Months
              </label>
            </div>


            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                style={{ margin: "0px" }}
                // id="genderAny"
                name="notice_period"
                value="0"
                // onBlur={validateGender}
                onChange={(e) => handleInputSearch(e)}
                checked={search[0].notice_period === ""}
              />
              <label class="form-check-label" for="inlineRadio3">
                None
              </label>
            </div>



            {/* {errorGender && (
                              <div style={mystyle}>{errorGender}</div>
                            )} */}
          </div>
        </div>

        <div class="col-lg-8 col-md-8 div-exp gap-4">
          <div style={{ color: "black" }} class="form-group">
            <label for="gender" class="label">
              Min. Exp
            </label>
            <input 
            type="number" 
            name="min_exp"
            value={search[0].min_exp}
            onChange={(e) => handleInputSearch(e)}
            />
          </div>

          <div style={{ color: "black" }} class="form-group">
            <label for="gender" class="label">
              Max. Exp
            </label>
            <input 
            type="number" 
            name="max_exp"
            value={search[0].max_exp}
            onChange={(e) => handleInputSearch(e)}
            />
          </div>
        </div>
        <div class="col-lg-8 col-md-8 div-exp gap-4">
          <div style={{ color: "black" }} class="form-group">
            <label for="gender" class="label">
              Min. Salary
            </label>
            <input
             type="number"
             name="min_sal"
             value={search[0].min_sal}
            onChange={(e) => handleInputSearch(e)}
              />
          </div>

          <div style={{ color: "black" }} class="form-group">
            <label for="gender" class="label">
              Max. Salary
            </label>
            <input 
            type="number" 
            name="max_sal"
            value={search[0].max_sal}
            onChange={(e) => handleInputSearch(e)}
            />
          </div>
        </div>

        <div class="col-lg-8 col-md-8 btn-sidebar button justify-content-start mx-0" >
          <button onClick={searchSubmit} className="btn">Submit</button>
        </div>
      </div>
    </div>
  );
}

export default Searchbar;

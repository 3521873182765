import Footer from "./footer";
import Header from "./header";
import ManageAccount from "./manageAccount";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import ReactPlayer from "react-player";

import {
  requestLogin,
  requestCandidateLogo,
  requestCandidateVideo,
  requestGetCandidate,
  requestCandidateTRUST
} from "../Redux/actions";
import WOW from "wowjs";
import camera from "../images/camera.png";
import img1 from "../images/profile.png";
import Swal from "sweetalert2";
import Breadcrumbs from "../Section/breadcrumbsSection";


 



import TrustPreview from "../DashboardComponent/TrustPrev";
import TrustUploadVideo from "../DashboardComponent/TrustUpload";
import Trustpartner from "../DashboardComponent/TurstscorePartner";
import { BsCameraVideo } from 'react-icons/bs';
import MainTopComponent from "../DashboardComponent/topdashboard";

function TrustScore(props) {
  
  useEffect(() => {
    new WOW.WOW().init();
    // window.scrollTo(0, 0);
    localStorage.removeItem("link");
  }, []);

  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [emp, setEmp] = useState({});
  const [img, setimg] = useState("");
  const [video, setvideo] = useState("");
  

  useEffect(() => {
    let loginData = props.candidate.loginData;
    if (loginData !== undefined) {
      if (loginData?.data?.status == "success") {
        setEmp(loginData.data.data);
        props.requestGetCandidate({
          id: loginData.data.data.id,
          token: loginData.data.data.token,
        });
      } else {
        localStorage.setItem("link", "/videoresume");
        navigate("/login");
      }
    } else {
      localStorage.setItem("link", "/videoresume");
      navigate("/login");
    }
  }, [props.candidate.loginData]);

  const onSelectFile = (e) => {
    setimg(URL.createObjectURL(e.target.files[0]));
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
    } else {
      setSelectedFile(e.target.files[0]);
    }
  };

  function submitForm(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("profile", selectedFile);

    props.requestCandidateVideo({
      id: emp.id,
      data: formData,
      token: emp.token,
    });
  }

  useEffect(() => {
    let getCandidateData = props.candidate.getCandidateData;
    if (getCandidateData !== undefined) {
      if (getCandidateData.data.status == "success") {
        setData(getCandidateData.data.data);
        if (getCandidateData.data.data.video_resume) {
          setvideo(
            process.env.REACT_APP_API_HOST + getCandidateData.data.data.video_resume
          );
        } else {
          setvideo(img1);
        } 
      }
    }
  }, [props.candidate.getCandidateData]);

  useEffect(() => {
    let candidatelogo = props.candidate.candidateVideoData;
    if (candidatelogo !== undefined) {
      console.log(candidatelogo.data)
      if (candidatelogo.data!=undefined && candidatelogo.data.status == "success") {
        Swal.fire(
          "Good job!",
          "Video updated Successfully.",
          "success"
        );
        props.candidate.candidateVideoData = undefined;
        props.requestGetCandidate({
          id: emp.id,
          token: emp.token,
        });
        navigate("/videoresume");
      } else {
        Swal.fire(
          "Error!",
          "Please select mp4 format for video.",
          "error"
        );

        props.candidate.candidateVideoData = undefined;
        props.requestGetCandidate({
          id: emp.id,
          token: emp.token,
        });
        navigate("/videoresume");
      }
    }
  }, [props.candidate.candidateVideoData]);



  const [pdffle, setpdffile] = useState(null);
  const [selectedFile, setSelectedFile] = useState();

  const handleFileUpload = (file) => {
    setpdffile(file);
    
  };


  const handleDeleteFile = () => {
    setpdffile(null);
  };


  const handleEdit = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf";
    input.onchange = (e) => {
      const editedFile = e.target.files[0];
      setSelectedFile(editedFile);
    };
    input.click();
  };

  const [uploadFile, setUploadFile] = useState(null);
  // Remove File
  const handleRemovefile = () => {
    setUploadFile(null)
  
  }
  
  // Save File
  const handleSavefile = (file) => {
    setUploadFile(file)
  
    let psychometricTest =  props.requestCandidateTRUST(file.name);
    console.log(psychometricTest , "<<<<<<<<<<< get >>>>>>>>");
  }

  return (
    <>
      <Header />
      <Breadcrumbs title="Create Resume" />
      <div class="resume section">
        <div class="container" >

        <MainTopComponent 
      username = {data.first_name}
      lastname = {data.last_name}
      userimage = {data.profile}
      useraddress = {data.address}
      useraddress2 = {data.address2}
      phone = {data.phone}
      email = {data.email}
      experience = {data.experience}
      notice_period = {data.notice_period}
      pincode = {data.pincode}
      city = {data.city_name}
      state = {data.state_name}
      country = {data.country_name}
      jobtitle = {data.designation}
      current_salary = {data.current_salary}
     
      />

          <div class="resume-inner">
            <div class="row">
              <ManageAccount name="TrustScore" from="parent" />   

              <div class="col-lg-8 col-12">
                <div class="inner-content">
                  <div class="alerts-inner">

                  <div className="row">
                  <h3 class="dashborardpage-subtitile">Trust Score</h3>
              
                  <div className="row">
            <div className="col-2  w-auto">
                <div className="partner-icon">
                    {/* <BsPersonVideo2/> */}
                    <BsCameraVideo/>
                </div>
            </div>
            <div className="col-10 ps-0">
                <div className="partner-description">
                     <p>
                     A Trust Score is crucial as it reflects your credibility and reliability. It's a measure of your integrity, work ethics, and professional reputation. A high Trust Score can help you as a candidate to build trust with employers, leading to better job opportunities and career growth, as trust is the foundation of successful professional relationships.  </p>
                </div>
            </div>
            
           </div>



       <Trustpartner/>


       <div className="col-lg-12 col-12 uploadfiles">
        

              <TrustUploadVideo uploadFile = {uploadFile}  handleRemovefile = {handleRemovefile} handleSavefile = {handleSavefile} />
           </div>

              </div>


                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}


const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestLogin,
      requestCandidateLogo,
      requestCandidateVideo,
      requestGetCandidate,
      requestCandidateTRUST
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(TrustScore);

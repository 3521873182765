import React, { useState } from "react";
import { useEffect } from "react";
import { AiFillLike } from 'react-icons/ai';
import { AiFillDislike } from 'react-icons/ai';
import { BiSmile } from 'react-icons/bi';
import { BiSad } from 'react-icons/bi';



function Checkscore(props){

    const [progress, setProgress] = useState(90);

  useEffect(() => {
    // Simulate progress by incrementing the percentage at a set interval.
    const interval = setInterval(() => {
      if (progress < 100) {
        // setProgress(progress + 1);
      } else {
        // clearInterval(interval);
      }
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, [progress]);



return (
    <>
    {
      !(window.location.href).includes('employer/candidates') && (

      <p style={{  fontSize: '14px', marginBottom: '6px', padding: '0 0px 0px 26px', fontWeight: 600 }}>Upload  all  six mandatory credentials and check your total score</p>
      )
    }





<div className= {props.JobseekerPage ? "jobseekerpagedata profile-dashboard checkscrore mb-3" : 'profile-dashboard checkscrore mb-3'}   >

        <div className="align-items-center row">
        <div className="col-2 pr-3 w-auto ">
        <div>

   {
    
  props.JobseekerPage === "JobseekerPage" ? (
    
    <h5 className="titleDashPage">Total Score</h5>
  ) : (
    <h5 className="titleDashPage">Your Total Score</h5>
  )
  
}

        </div>
        </div>
        <div className="col-8 ps-0">
        <div className="progress-bar" style={{ backgroundColor: 'rgb(215 215 215)' }} >
      <div className="progress" style={{ width: `${progress}%` }}></div>
    </div>
        </div>
        <div className="col text-center ps-0 userpersentage">
            <div className="align-items-center d-flex userTotal-score">
           <h5 className="titleDashPage number" > {progress}%</h5> <span className="ml-2"> 
     {
          progress >= 50 ? (
           <>
             {/* <AiFillLike/>  */}
             {/* <BiSmile/> */}
             <span style={{ fontSize: '21px' }}>
              <img  src="/assets/images/dashboard/smilyicon.svg" alt="smilyicon" 
style={{ width: '2rem', objectFit: 'cover', marginInlineStart: '10px' }}

              />
             </span>

           </>
          )  : (
            <>
           <BiSad/>

            </>
          )
     }
     
           </span>
            </div>
        </div>
        </div>
       </div>






    </>
)
}
export default Checkscore

import React from 'react';
import { useState } from 'react';
import ReactPlayer from 'react-player';
import VideoUpload from './VideoUpload';

function VideoPreview({ vid, onVideoUpload, onVideoRemove , onSaveVideo }) {

    const [isEditing, setIsEditing] = useState(false);

    const handleEditClick = () => {
        if (!isEditing) {
            // When the "Edit" button is clicked, trigger the file input element
            document.getElementById('videoInput').click();
          }
    }
    

  return (
    <div className="video-preview ">


     { isEditing ? (
            <>
            <VideoUpload onVideoUpload={onVideoUpload} />
            </>
        ) : (
            <>
            <ReactPlayer url={URL.createObjectURL(vid)} width="400px" height="100%" controls />
      
        <div className='d-flex gap-2 mt-2'>
      <div class="button  mb-2">
       <button type="submit" class="btn seconday-btn" onClick={handleEditClick}>Change</button>
       </div>

       <div class="button  mb-2">
       <button type="submit" class="btn seconday-btn" onClick={onVideoRemove}>Remove</button>
       </div>

       <div class="button  mb-2">
       <button type="submit" class="btn" onClick={()=> onSaveVideo(vid)}>Save</button>
       </div>


       <input
            id="videoInput"
            type="file"
            accept="video/mp4,video/x-m4v,video/*"
            style={{ display: 'none' }}
            onChange={(e) => {
            onVideoUpload(e.target.files[0]);
            }}
          />
      </div>
            </>
        )
     }

    </div>
  );
}

export default VideoPreview;
import Footer from "./footer";
import Header from "./header";
import React, { useEffect, useState } from "react";
import ManageAccount from "../Employee/manageAccount";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  requestEmpLogin,
  requestFormField,
  requestAddJob,
  requestCountry,
  requestState,
  requestCity,
} from "../Redux/actions";
import WOW from "wowjs";
import Swal from "sweetalert2";
import Breadcrumbs from "../Section/breadcrumbsSection";

function PostJob(props) {
  useEffect(() => {
    new WOW.WOW().init();
    localStorage.removeItem("link");
  }, []);

  const mystyle = {
    color: "#D10000",
    backgroundColor: "#FFD2D2",
    padding: "3px 10px",
    border: "1px solid red",
    borderRadius: "5px",
    marginTop: "5px",
  };

  const [data, setData] = useState({});
  const [selectedskill, setselectedskill] = useState([]);
  const [selectedtag, setselectedtag] = useState([]);
  const [SalaryPeriod, setSalaryPeriod] = useState([]);
  const [career_levels, setcareer_levels] = useState([]);
  const [notice_periods, setNotice_Periods] = useState([]);
  const [categories, setcategories] = useState([]);
  const [currencies, setcurrencies] = useState([]);
  const [degree_levels, setdegree_levels] = useState([]);
  const [functional_areas, setfunctional_areas] = useState([]);
  const [job_shifts, setjob_shifts] = useState([]);
  const [skills, setskills] = useState([]);
  const [experiences, setExperiences] = useState([]);

  const [tags, settags] = useState([]);
  const [types, settypes] = useState([]);
  const [position, setposition] = useState([]);

  const [errorTitle, seterrorTitle] = useState("");
  const [errorType, seterrorType] = useState("");
  const [errorCategory, seterrorCategory] = useState("");
  const [errorGender, seterrorGender] = useState("");
  const [errorExpiry, seterrorExpiry] = useState("");
  const [errorFrom, seterrorFrom] = useState("");
  const [errorTo, seterrorTo] = useState("");
  const [errorCurrency, seterrorCurrency] = useState("");
  const [errorPeriod, seterrorPeriod] = useState("");
  const [errorCareer, seterrorCareer] = useState("");
  const [errorShift, seterrorShift] = useState("");
  const [errorDegree, seterrorDegree] = useState("");
  const [errorFunctional, seterrorFunctional] = useState("");
  const [errorPosition, seterrorPosition] = useState("");
  const [errorDesc, seterrorDesc] = useState("");
  const [errorName, seterrorName] = useState("");
  const [errorEmail, seterrorEmail] = useState("");
  const [errorVacancy, seterrorVacancy] = useState("");

  const [professionalSkills, setProfessionalSkills] = useState([{professionalskill: ""}]);
  const [errorskill, setErrorskill] = useState([]);

  const [countries, setcountries] = useState([]);
  const [countryIdperm, setcountryIdperm] = useState(0);
  const [action, setAction] = useState([]);
  const [countryId, setcountryId] = useState(0);

  const [statesp, setstatesp] = useState([]);
  const [stateId, setstateId] = useState(0);
  const [states, setstates] = useState([]);
  const [stateIdperm, setstateIdperm] = useState(0);


  const [cityId, setcityId] = useState(0);
  const [cities, setcities] = useState([]);
  const [cityIdperm, setcityIdperm] = useState(0);

  const [citiesp, setcitiesp] = useState([]);

  const [errorcountry, seterrorcountry] = useState("");
  const [errorstate, seterrorstate] = useState("");
  const [errorcity, seterrorcity] = useState("");


const[requiredSkills, setRequiredSkills] = useState([])


  const navigate = useNavigate();
  const [emp, setEmp] = useState({});

  useEffect(() => {
    let empLoginData = props.employee.empLoginData;
    if (empLoginData !== undefined) {
      if (empLoginData?.data?.status == "success") {

        

        setEmp(empLoginData.data.data);
        props.requestFormField({
          token: empLoginData.data.data.token,
        });
      } else {
        localStorage.setItem("link", "/postJob");
        navigate("/emplogin");
      }
    } else {
      localStorage.setItem("link", "/postJob");
      navigate("/emplogin");
    }
  }, [props.employee.empLoginData]);

  useEffect(() => {
    let formfieldData = props.employee.formfieldData;
    if (formfieldData !== undefined) {
      if (formfieldData.data.status == "success") {


      // console.log(formfieldData.data.data, "=========");

        setSalaryPeriod(formfieldData.data.data.SalaryPeriod);
        setcareer_levels(formfieldData.data.data.career_levels);
        setNotice_Periods(formfieldData.data.data.NoticePeriod);
        setcategories(formfieldData.data.data.categories);
        setcurrencies(formfieldData.data.data.currencies);
        setdegree_levels(formfieldData.data.data.degree_levels);
        setfunctional_areas(formfieldData.data.data.functional_areas);
        setjob_shifts(formfieldData.data.data.job_shifts);
        setskills(formfieldData.data.data.skills);
        setExperiences(formfieldData.data.data.experiences);
        settags(formfieldData.data.data.tags);
        settypes(formfieldData.data.data.types);
        setposition(formfieldData.data.data.position);
      }
    }
  }, [props.employee.formfieldData]);



  useEffect(() => {
    props.requestCountry();
  }, []);

  useEffect(() => {
    let countryData = props.candidate.countryData;
    if (countryData !== undefined) {
      if (countryData?.data?.status == "success") {
        setcountries(countryData.data.data.countries);
      }
    }
  }, [props.candidate.countryData]);




  function onChangeCountryPerm(e) {
    setAction("permanent");
    console.log("e.target.value", e.target.value);
    setcountryIdperm(e.target.value);
    console.log("onchange country change value ", countryIdperm );

    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));

    props.requestState({
      id: e.target.value,
    });
  }

  const [errorcountryperm, setErrorcountryperm] = useState("");

  function validatecountryPerma() {
    let formIsValid = false;
    if (countryIdperm === "0") {
      formIsValid = false;
      setErrorcountryperm("*Select your country.");
    } else if (typeof countryIdperm === "undefined") {
      formIsValid = false;
      setErrorcountryperm("*Select your country.");
    } else {
      formIsValid = true;
      setErrorcountryperm("");
    }

    return formIsValid;
  }



  useEffect(() => {
    let stateData = props.candidate.stateData;
    if (stateData !== undefined) {
      if (stateData?.data?.status == "success") {
        if (action == "permanent") setstatesp(stateData.data.data.states);
        else if (action == "correspondence") {
          setstates(stateData.data.data.states);
        }
      }
    }
  }, [props.candidate.stateData]);


  function onChangeStatePerm(e) {
    setAction("permanent");
    setstateIdperm(e.target.value);

    console.log("onchange permanent state==" , stateIdperm);

    console.log("state=", e.target.value);

    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));


    props.requestCity({
      id: e.target.value,
    });
  }

  const [errorstateperm, setErrorstateperm] = useState("");
  function validatestatePerma() {
    let formIsValid = false;
    if (stateIdperm === "0") {
      formIsValid = false;
      setErrorstateperm("*Enter your state");
    } else if (typeof cityIdperm === "undefined") {
      formIsValid = false;
      setErrorstateperm("*Enter your state.");
    } else {
      formIsValid = true;
      setErrorstateperm("");
    }
    return formIsValid;
  }


  useEffect(() => {
    let cityData = props.candidate.cityData;
    if (cityData !== undefined) {
      if (cityData?.data?.status == "success") {
        if (action == "permanent") setcitiesp(cityData.data.data.cities);
        else if (action == "correspondence")
          setcities(cityData.data.data.cities);
      }
    }
  }, [props.candidate.cityData]);

  function onChangeCity(e) {
    setAction("correspondence");

    console.log("city ==", e.target.value );

    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));

    setcityId(e.target.value);
  }

  const [errorcityperm, setErrorcityperm] = useState("");
  function validatecityPerma() {
    let formIsValid = false;
    if (cityIdperm === "0") {
      formIsValid = false;
      setErrorcityperm("*Enter your city");
    } else if (typeof cityIdperm === "undefined") {
      formIsValid = false;
      setErrorcityperm("*Enter your city");
    } else {
      formIsValid = true;
      setErrorcityperm("");
    }
    return formIsValid;
  }


  function onChangeData(e) {
    
    // if (e.target.type === "radio") {
    //   setData((data) => ({
    //     ...data,
    //     [e.target.name]: parseInt(e.target.value),
    //   }));
    // }

     {
      // console.log(e.target.name, e.target.value , ">>>>>>>>>>");
      setData((data) => ({
        ...data,
        [e.target.name]: e.target.value,
      }));
    }
  }



  // console.log(data, "=================");


  const handleInputSkills = (e, index) => {
    const { name, value } = e.target;
    const list = [...professionalSkills];
    list[index][name] = value;
    
    setProfessionalSkills(list);
    


    const transformedData = {
      professionalskill: professionalSkills.map(item => ({ requiredskill: item.professionalskill }))
    };
    
    // console.log(transformedData, "====");
    
    
    setData((prevData) => ({
      ...prevData,
      ...transformedData
  }));


  };
  // console.log('skill ???? ', professionalSkills);



  function onChangeSkill(e) {
// console.log("v===",e.target.value);
    // selectedskill.push(e.target.value);

    var options = e.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        selectedskill.push(options[i].value);
      }
    }
  }

  function validateTitle() {
    let formIsValid = false;
    if (!data["title"]) {
      formIsValid = false;
      seterrorTitle("*Enter job title.");
    } else if (typeof data["title"] === "undefined") {
      formIsValid = false;
      seterrorTitle("*Enter job title.");
    } else {
      formIsValid = true;
      seterrorTitle("");
    }
    return formIsValid;
  }




  
  function validateSkill(i) {
    // console.log(professionalSkills[i].professionalskill, ">>>>>>>>>>>>>>>>>>>");
    let formIsValid = false;
    if (!professionalSkills[i].professionalskill) {
      formIsValid = false;
      const list = [...errorskill];
      list[i] = "*Enter skill or delete textbox.";
      setErrorskill(list);
    } else if (typeof professionalSkills[i].professionalskill === "undefined") {
      formIsValid = false;
      const list = [...errorskill];
      list[i] = "*Enter skill or delete textbox.";
      setErrorskill(list);
    } else {
      formIsValid = true;
      const list = [...errorskill];
      list[i] = "";
      setErrorskill(list);
    }
    return formIsValid;
  }



  
  function validatLocation() {
    let formIsValid = false;
    if (!data["location"]) {
      formIsValid = false;
      seterrorTitle("*Enter Location.");
    } else if (typeof data["location"] === "undefined") {
      formIsValid = false;
      seterrorTitle("*Enter location.");
    } else {
      formIsValid = true;
      seterrorTitle("");
    }
    return formIsValid;
  }


  function validateType() {
    let formIsValid = false;
    if (data["type"] === "0") {
      formIsValid = false;
      seterrorType("*Select job type.");
    } else if (typeof data["type"] === "undefined") {
      formIsValid = false;
      seterrorType("*Select job type.");
    } else {
      formIsValid = true;
      seterrorType("");
    }
    return formIsValid;
  }


  function validatePeriod() {
    let formIsValid = false;
    if (data["notice_period"] === "0") {
      formIsValid = false;
      seterrorPeriod("*Select notice_period.");
    } else if (typeof data["notice_period"] === "undefined") {
      formIsValid = false;
      seterrorPeriod("*Select Notice Period.");
    } else {
      formIsValid = true;
      seterrorPeriod("");
    }
    return formIsValid;
  }


  function validateCategory() {
    let formIsValid = false;
    if (data["category"] === "0") {
      formIsValid = false;
      seterrorCategory("*Select job category.");
    } else if (typeof data["category"] === "undefined") {
      formIsValid = false;
      seterrorCategory("*Select job category.");
    } else {
      formIsValid = true;
      seterrorCategory("");
    }
    return formIsValid;
  }

  
  function validateGender() {
    let formIsValid = false;
    if (!data.gender || data.gender === "0") {
      formIsValid = false;
      seterrorGender("*Select gender for job.");
    } else {
      formIsValid = true;
      seterrorGender("");
    }
    return formIsValid;
  }


  
  // function validateExpiry() {
  //   let formIsValid = false;
  //   var Today = new Date();
  //   if (typeof data["expiry_date"] !== "undefined") {
  //     if (new Date(data["expiry_date"]).getTime() <= Today.getTime()) {
  //       formIsValid = false;
  //       seterrorExpiry("*Please select proper date.");
  //     } else {
  //       formIsValid = true;
  //       seterrorExpiry("");
  //     }
  //   } else {
  //     formIsValid = true;
  //     seterrorExpiry("");
  //   }
  //   return formIsValid;
  // }


  function validateExpiry() {
    let formIsValid = true;
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Remove time part for comparison
  
    if (!data["expiry_date"]) {
      formIsValid = false;
      seterrorExpiry("*Please select a date.");
    } else if (new Date(data["expiry_date"]).getTime() <= today.getTime()) {
      formIsValid = false;
      seterrorExpiry("*Please select a future date.");
    } else {
      seterrorExpiry("");
    }
  
    return formIsValid;
  }



  function validateFrom() {
    let formIsValid = false;
    if (data["salary_from"] === undefined) {
      formIsValid = false;
      seterrorFrom("*Enter minimum salary.");
    } else if (data["salary_from"] <= 0) {
      formIsValid = false;
      seterrorFrom("*Minimum salary shold be more than 0.");
    } else {
      formIsValid = true;
      seterrorFrom("");
    }
    return formIsValid;
  }
  function validateTo() {

    // console.log("to==",data["salary_to"])
    // console.log("from==",data["salary_from"])
    let formIsValid = false;
    if (data["salary_to"] === undefined) {
      formIsValid = false;
      seterrorTo("*Enter maximum salary.");
    } else if (data["salary_to"] <= data["salary_from"]) {
      formIsValid = false;
      seterrorTo("*Maximum salary shold be more than Minimum salary.");
    } else {
      formIsValid = true;
      seterrorTo("");
    }
    return formIsValid;
  }
  function validateCurrency() {
    let formIsValid = false;
    if (data["currency"] === "0") {
      formIsValid = false;
      seterrorCurrency("*Select salary currency.");
    } else if (typeof data["currency"] === "undefined") {
      formIsValid = false;
      seterrorCurrency("*Select salary currency.");
    } else {
      formIsValid = true;
      seterrorCurrency("");
    }
    return formIsValid;
  }





  function validateCareer() {
    let formIsValid = false;
    if (data["career_level"] === "0") {
      formIsValid = false;
      seterrorCareer("*Select career level.");
    } else if (typeof data["career_level"] === "undefined") {
      formIsValid = false;
      seterrorCareer("*Select career level.");
    } else {
      formIsValid = true;
      seterrorCareer("");
    }
    return formIsValid;
  }
  function validateShift() {
    let formIsValid = false;
    if (data["shift"] === "0") {
      formIsValid = false;
      seterrorShift("*Select job shift.");
    } else if (typeof data["shift"] === "undefined") {
      formIsValid = false;
      seterrorShift("*Select job shift.");
    } else {
      formIsValid = true;
      seterrorShift("");
    }
    return formIsValid;
  }
  function validateDegree() {
    let formIsValid = false;
    if (data["degree_level"] === "0") {
      formIsValid = false;
      seterrorDegree("*Select Qualification level.");
    } else if (typeof data["degree_level"] === "undefined") {
      formIsValid = false;
      seterrorDegree("*Select degree level.");
    } else {
      formIsValid = true;
      seterrorDegree("");
    }
    return formIsValid;
  }
  function validateFunctional() {
    let formIsValid = false;
    if (data["functional_area"] === "0") {
      formIsValid = false;
      seterrorFunctional("*Select functional area.");
    } else if (typeof data["functional_area"] === "undefined") {
      formIsValid = false;
      seterrorFunctional("*Select functional area.");
    } else {
      formIsValid = true;
      seterrorFunctional("");
    }
    return formIsValid;
  }
  function validatePosition() {
    let formIsValid = false;
    if (data["position"] === "0") {
      formIsValid = false;
      seterrorPosition("*Select job position.");
    } else if (typeof data["position"] === "undefined") {
      formIsValid = false;
      seterrorPosition("*Select job position.");
    } else {
      formIsValid = true;
      seterrorPosition("");
    }
    return formIsValid;
  }
  function validateDesc() {
    let formIsValid = false;
    if (!data["description"]) {
      formIsValid = false;
      seterrorDesc("*Enter job description.");
    } else if (typeof data["description"] === "undefined") {
      formIsValid = false;
      seterrorDesc("*Enter job description.");
    } else {
      formIsValid = true;
      seterrorDesc("");
    }
    return formIsValid;
  }
  function validateName() {
    let formIsValid = false;
    if (!data["Recruiter_name"]) {
      formIsValid = false;
      seterrorName("*Enter recruiter name.");
    } else if (typeof data["Recruiter_name"] !== "undefined") {
      if (!data["Recruiter_name"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
        formIsValid = false;
        seterrorName("*Please enter Alphabet characters only.");
      } else {
        formIsValid = true;
        seterrorName("");
      }
    }
    return formIsValid;
  }
  function validateEmail() {
    let formIsValid = false;
    if (!data["Recruiter_email"]) {
      formIsValid = false;
      seterrorEmail("*Enter recruiter E-mail ID.");
    } else if (typeof data["Recruiter_email"] !== "undefined") {
      if (
        !data["Recruiter_email"].match(
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        )
      ) {
        formIsValid = false;
        seterrorEmail("*Please enter valid E-mail ID.");
      } else {
        formIsValid = true;
        seterrorEmail("");
      }
    }
    return formIsValid;
  }
  function validateVacancy() {
    let formIsValid = false;
    if (data["vacancy"] === undefined) {
      formIsValid = false;
      seterrorVacancy("*Enter job vacancy.");
    } else if (data["vacancy"] <= 0) {
      formIsValid = false;
      seterrorVacancy("*Vacancy shold be more than 0.");
    } else {
      formIsValid = true;
      seterrorVacancy("");
    }
    return formIsValid;
  }



  // console.log(data, "check professional skill");

  const handleRemoveSkills = (index) => {
    const list = [...professionalSkills];
    list.splice(index, 1);
    setProfessionalSkills(list);
    const errorlist = [...errorskill];
    errorlist.splice(index, 1);
    setErrorskill(errorlist);
  };

  const handleAddSkills = (e) => {
    e.preventDefault()

    let length = professionalSkills.length;
   
    if (length === 0) {
      setProfessionalSkills([
        ...professionalSkills,{professionalSkill: "",},
      ]);
    }
    //  else if (professionalSkills[length - 1].professionalSkill === "") {
    //   Swal.fire(
    //     "Error!",
    //     "After completing previous one, You can add new one.",
    //     "error"
    //   );
    // } 
    else {
      setProfessionalSkills([
        ...professionalSkills,
        {
          professionalSkill: "",
        },
      ]);
    }
  };



  // const handleAddSkills = (e) => {
  //   e.preventDefault();
  
  //   const length = professionalSkills.length;

  //   if (length === 0 || professionalSkills[length - 1]?.professionalSkill.trim() === "") {
  //     // if (length > 0) {
  //     //   Swal.fire(
  //     //     "Error!",
  //     //     "Please fill in the previous skill before adding a new one.",
  //     //     "error"
  //     //   );
  //     // }
  //   } else {
  //     setProfessionalSkills([
  //       ...professionalSkills,
  //       {
  //         professionalSkill: "",
  //       },
  //     ]);
  //   }
  // };
  


  
  function validateForm() {
    let Title = validateTitle();
    let Type = validateType();
    let Category = validateCategory();
    let Gender = validateGender();
    let Expiry = validateExpiry();
    let From = validateFrom();
    let To = validateTo();
    let Currency = validateCurrency();
    let Period = validatePeriod();
    let Career = validateCareer();
    let Shift = validateShift();
    let Degree = validateDegree();
    let Functional = validateFunctional();
    let Position = validatePosition();
    let Desc = validateDesc();
    let Name = validateName();
    let Email = validateEmail();
    let Vacancy = validateVacancy();
    
    let   countryPerma =   validatecountryPerma();
    let   statePerma =   validatestatePerma();
    let   cityPerma =   validatecityPerma();

    // let professionalskiil = validateSkill();
    
    
    let valid =
      Title &&
      Type &&
      Category &&
      Gender &&
      Expiry &&
      From &&
      To &&
      Currency &&
      Period &&
      Career &&
      Shift &&
      Degree &&
      Functional &&
      Position &&
      Desc &&
      Name &&
      Email &&
      Vacancy &&
     countryPerma &&
     statePerma &&
     cityPerma ;
    //  professionalskiil;
    return valid;
  }

  function submitForm(e)
  {

    e.preventDefault();
    
    
    // console.log("data==", data);
    
  // console.log(props.employee, "================data============");

    let myselectedskill =  [...new Set(selectedskill)];
    // console.log("skill===",myselectedskill);


    console.log("data==", data.professionalskill);



  props.requestAddJob({
    token: emp.token,
    data: {

      company_id: emp.id,
      title: data.title,

      notice_period: data.notice_period,

      employment_type: data.employment_type.toString(),

      // employment_type: parseInt(data.employment_type),

      type: data.type,
      category: data.category,
      

      country_permanent: parseInt(countryIdperm),
      state_permanent:   parseInt(stateIdperm),
      city_permanent:    parseInt(cityIdperm),
      

      professionalskill: data.professionalskill,
     

      // skill: myselectedskill,
      gender: parseInt(data.gender),
      expiry_date: data.expiry_date,
      salary_from: parseInt(data.salary_from),
      salary_to: parseInt(data.salary_to),
      currency: parseInt(data.currency),
      salary_period: data.salary_period,
      career_level: data.career_level,
      shift: data.shift,
      tag: data.tag,
      degree_level: data.degree_level,
      functional_area: data.functional_area,
      position: data.position,
      experience: data.experience,
      description: data.description,
      Recruiter_name: data.Recruiter_name,
      Recruiter_email: data.Recruiter_email,
      vacancy: parseInt(data.vacancy),
    },
  }); 
  
 
       
  }



  useEffect(() => {
    let empAddJobData = props.employee.empAddJobData;

    if (empAddJobData !== undefined) {
      if (empAddJobData?.data?.status == "success") {
        Swal.fire("Good job!", "Job added successfully.", "success");
        props.employee.empAddJobData = undefined;
        navigate("/manageJobs");
      } else {
        Swal.fire("Error!", `${empAddJobData?.data?.message}`, "error");
        props.employee.empAddJobData = undefined;
      }
    }
  }, [props.employee.empAddJobData]);




  return (
    <>
      <Header />
      <Breadcrumbs title="Post Job" />
      <div class="manage-jobs section common-spacing">
        <div class="container">
          <div class="alerts-inner">
            <div class="row">
              <ManageAccount name="PostJob" />

              <div class="col-lg-8 col-12">
                <div class="job-post">
                  <div class="job-information">
                    <h3 class="title">Job Information</h3>
                    <form class="forms-sample">
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Job title*</label>
                            <input
                              class="form-control"
                              type="text"
                              name="title"
                              value={data.title}
                              onBlur={validateTitle}
                              onChange={onChangeData}
                              placeholder=""
                            />
                            {errorTitle && (
                              <div style={mystyle}>{errorTitle}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>No. of Vacancies*</label>
                            <input
                              class="form-control"
                              type="number"
                              name="vacancy"
                              value={data.vacancy}
                              onBlur={validateVacancy}
                              onChange={onChangeData}
                              placeholder=""
                            />
                            {errorVacancy && (
                              <div style={mystyle}>{errorVacancy}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Industry</label>
                            <select
                              class="select"
                              name="type"
                              value={data.type}
                              onBlur={validateType}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Job Type</option>
                              {types.map((option) => (
                                <option value={option.id}>{option.name}</option>
                              ))}
                            </select>
                            {errorType && (
                              <div style={mystyle}>{errorType}</div>
                            )}
                          </div>
                        </div>

 
                        {/* <div class="col-lg-6 col-md-6">
                        llll
                          <div class="form-group">
                            <label>Functional Area</label>
                            <select
                              class="select"
                              name="functional_area"
                              value={data.functional_area}
                              onBlur={validateFunctional}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Functional Area</option>
                              {functional_areas.map((option) => (
                                <option value={option.id}>{option.name}</option>
                              ))}
                            </select>
                            {errorFunctional && (
                              <div style={mystyle}>{errorFunctional}</div>
                            )}
                          </div>
                        </div> */}

                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>
                            Functional Area
                            {/* Job Category* */}
                            </label>
                            <select
                              class="select"
                              name="category"
                              value={data.category}
                              onBlur={validateCategory}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Job Category</option>
                              {categories.map((option) => (
                                <option value={option.id}>{option.name}</option>
                              ))}
                            </select>
                            {errorCategory && (
                              <div style={mystyle}>{errorCategory}</div>
                            )}
                          </div>
                        </div>

<div className="col-lg-12 col-md-12">
      <label htmlFor="gender" className="label">
        Employment Type
      </label>
      <div style={{ color: 'black' }} className="employment-type">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            style={{ margin: '0px' }}
            name="employment_type"
            value="1"
            onChange={onChangeData}
          />
          <label className="form-check-label" htmlFor="inlineRadio1">
            Work from home
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            style={{ margin: '0px' }}
            name="employment_type"
            value="2"
            onChange={onChangeData}
          />
          <label className="form-check-label" htmlFor="inlineRadio2">
            Permanent
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            style={{ margin: '0px' }}
            name="employment_type"
            value="3"
            onChange={onChangeData}
          />
          <label className="form-check-label" htmlFor="inlineRadio3">
            Contractual
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            style={{ margin: '0px' }}
            name="employment_type"
            value="4"
            onChange={onChangeData}
          />
          <label className="form-check-label" htmlFor="inlineRadio4">
            Internship
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            style={{ margin: '0px' }}
            name="employment_type"
            value="5"
            onChange={onChangeData}
          />
          <label className="form-check-label" htmlFor="inlineRadio5">
          Hybrid 
          </label>
        </div>
      </div>
    </div>

                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Notice Period*</label>
                            <select
                              class="select"
                              name="notice_period"
                              value={data.notice_period}
                              onBlur={validatePeriod}
                              onChange={onChangeData}
                            >
                           <option value="0">Select Notice Period</option>
                              {
                                notice_periods.map((options)=> (
                                  <option value= {options.id} key={options.id} > {options.name} </option>
                                ))
                              }
                              
                            </select>
                            {errorPeriod && (
                              <div style={mystyle}>{errorPeriod}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label for="gender" class="label">
                              Gender
                            </label>
                            <select
                              class="select"
                              name="gender"
                              value={data.gender}
                              onBlur={validateGender}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Gender</option>
                              <option value="1">Male</option>
                              <option value="2">Female</option>
                              <option value="3">Any</option>
                            </select>
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Min. Salary* ( CTC Per Annum )</label>
                            <input
                              class="form-control"
                              type="number"
                              name="salary_from"
                              min="0"
                              value={data.salary_from}
                              // onBlur={validateFrom}
                              onChange={onChangeData}
                              placeholder=""
                            />
                            {errorFrom && (
                              <div style={mystyle}>{errorFrom}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Max Salary* ( CTC Per Annum )</label>
                            <input
                              class="form-control"
                              type="number"
                              name="salary_to"
                              min="0"
                              value={data.salary_to}
                              // onBlur={validateTo}
                              onChange={onChangeData}
                              placeholder=""
                            />
                            {errorTo && <div style={mystyle}>{errorTo}</div>}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Currency*</label>
                            <select
                              class="select"
                              name="currency"
                              value={data.currency}
                              onBlur={validateCurrency}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Currency</option>
                              {currencies.map((option) => (
                                <option value={option.id}>
                                  {option.currency_name}
                                </option>
                              ))}
                            </select>
                            {errorCurrency && (
                              <div style={mystyle}>{errorCurrency}</div>
                            )}
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Job Expiry Date</label>
                            <input
                              class="form-control"
                              type="date"
                              name="expiry_date"
                              value={data.expiry_date}
                              onBlur={validateExpiry}
                              onChange={onChangeData}
                              placeholder=""
                            />
                            {errorExpiry && (
                              <div style={mystyle}>{errorExpiry}</div>
                            )}
                          </div>
                        </div>
                        {/* <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Salary Period*</label>
                            <select
                              class="select"
                              name="salary_period"
                              value={data.salary_period}
                              onBlur={validatePeriod}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Salary Period</option>
                              {SalaryPeriod.map((option) => (
                                <option value={option.id}>
                                  {option.period}
                                </option>
                              ))}
                            </select>
                            {errorPeriod && (
                              <div style={mystyle}>{errorPeriod}</div>
                            )}
                          </div>
                        </div> */}
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Career Level*</label>
                            <select
                              class="select"
                              name="career_level"
                              value={data.career_level}
                              onBlur={validateCareer}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Career Level</option>
                              {career_levels.map((option) => (
                                <option value={option.id}>
                                  {option.level}
                                </option>
                              ))}
                            </select>
                            {errorCareer && (
                              <div style={mystyle}>{errorCareer}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Shift*</label>
                            <select
                              class="select"
                              name="shift"
                              value={data.shift}
                              onBlur={validateShift}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Shift</option>
                              {job_shifts.map((option) => (
                                <option value={option.id}>{option.name}</option>
                              ))}
                            </select>
                            {errorShift && (
                              <div style={mystyle}>{errorShift}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Position*</label>
                            <select
                              class="select"
                              name="position"
                              value={data.position}
                              onBlur={validatePosition}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Position</option>
                              {position.map((option) => (
                                <option value={option.id}>{option.name}</option>
                              ))}
                            </select>
                            {errorPosition && (
                              <div style={mystyle}>{errorPosition}</div>
                            )}
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Highest Academic Qualification</label>
                            <select
                              class="select"
                              name="degree_level"
                              value={data.degree_level}
                              onBlur={validateDegree}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Qualification</option>
                              {degree_levels.map((option) => (
                                <option value={option.id}>
                                  {option.level}
                                </option>
                              ))}
                            </select>
                            {errorDegree && (
                              <div style={mystyle}>{errorDegree}</div>
                            )}
                          </div>
                        </div>
                        
                        {/* <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Functional Area</label>
                            <select
                              class="select"
                              name="functional_area"
                              value={data.functional_area}
                              onBlur={validateFunctional}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Functional Area</option>
                              {functional_areas.map((option) => (
                                <option value={option.id}>{option.name}</option>
                              ))}
                            </select>
                            {errorFunctional && (
                              <div style={mystyle}>{errorFunctional}</div>
                            )}
                          </div>
                        </div> */}

   {/* ========  */}
   
        <div class="single-section skill">
                      <h4 className="titleDashPage two">
                      Professional Skills
                        <button className="addicon"
                          onClick={handleAddSkills}
                          style={{float: "right",}}
                        >
                          <i class="fa fa-plus" ></i>
                        </button>
                      </h4>
                      <div class="row">
                        { 
                        professionalSkills.map((x, i) => {
                          return (
                            <div className="col-12 col-sm-6 ps-0">
                              <div
                                style={{ float: "left", padding: "5px 0px" }}
                                class="form-group w-100"
                              >
                                <div style={{ position: "relative" }}>
                                
                                    <input
                                      class="form-control"
                                      type="text"
                                      name="professionalskill"
                                      defaultValue={x?.professionalskill}
                                      onBlur={() => validateSkill(i)}
                                      onChange={(e) => handleInputSkills(e, i)}
                                      placeholder=""
                                    />
                

                                  <button
                                    onClick={() => handleRemoveSkills(i)}
                                    class="btn "
                                    type="button"
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "1px solid red",
                                      position: "absolute",
                                      right: "0px",
                                      top: "0px",
                                      padding: "11px"
                                    }}
                                  >
                                    <i
                                      class="fa fa-trash"
                                      style={{ color: "red" }}
                                    ></i>
                                  </button>

                                  {errorskill[i] && (
                                    <div style={mystyle}>{errorskill[i]}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
   {/* ========  */}



                        {/* <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Professional Skills</label>
                            <select
                              class="select"
                              style={{ height: "200px" }}
                              name="tag"
                              value={data.tag}
                              onChange={onChangeData}
                              multiple
                            >
                              {tags.map((option) => (
                                <option value={option.id}>{option.name}</option>
                              ))}
                            </select>
                          </div>
                        </div> */}

                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Experience In Years</label>
                            <select
                              class="select"
                              name="experience"
                              value={data.experience}
                              onChange={onChangeData}
                            >
                              {experiences.map((option) => (
                                <option value={option.id}>{option.name}</option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Country</label>
                                <select
                                  class="select"
                                  name="country_permanent"
                                  value={countryIdperm}
                                  onBlur={validatecountryPerma}
                                  onChange={onChangeCountryPerm}
                                >
                                  <option value="0">Select Country</option>
                                  {countries.map((option) => (
                                    <option value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                                {errorcountryperm && (
                                  <div >{errorcountryperm}</div>
                                )}
                              </div>
                            </div>

                            {/* =============== State ========== */}
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>State</label>
                                <select
                                  class="select"
                                  name="state_permanent"
                                  value={stateIdperm}
                                  onBlur={validatestatePerma}
                                  onChange={onChangeStatePerm}
                                >
                                  <option value="0">Select State</option>
                                  {statesp.map((option) => (
                                    <option value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                                {errorstateperm && (
                                  <div >{errorstateperm}</div>
                                )}
                              </div>
                            </div>
                            {/* =============== State ========== */}


                            {/* ========= City ======== */}
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>City</label>
                                <select
                                  class="select"
                                  name="city"
                                  value={cityId}
                                  onBlur={validatecityPerma}
                                  onChange={onChangeCity}
                                >
                                  <option value="0">Select City</option>
                                  {citiesp.map((option) => (
                                    <option value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                                {errorcityperm && (
                                  <div style={mystyle}>{errorcityperm}</div>
                                )}
                              </div>
                            </div>
                            {/* ========= City ======== */}


               


                        {/* <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Experience</label>
                            <input
                              class="form-control"
                              type="number"
                              min="0"
                              name="experience"
                              value={data.experience}
                              onChange={onChangeData}
                              placeholder=""
                            />
                          </div>
                        </div> */}
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label>Job Description*</label>
                            <textarea
                              class="form-control"
                              rows="5"
                              name="description"
                              value={data.description}
                              onChange={onChangeData}
                              onBlur={validateDesc}
                              placeholder=""
                            ></textarea>
                            {errorDesc && (
                              <div style={mystyle}>{errorDesc}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <h3 class="title">Employer Information</h3>
                      <div class="row">
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Full Name</label>
                            <input
                              class="form-control"
                              type="text"
                              name="Recruiter_name"
                              value={data.Recruiter_name}
                              onChange={onChangeData}
                              onBlur={validateName}
                              placeholder=""
                            />
                            {errorName && (
                              <div style={mystyle}>{errorName}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Email</label>
                            <input
                              class="form-control"
                              type="email"
                              name="Recruiter_email"
                              value={data.Recruiter_email}
                              onChange={onChangeData}
                              onBlur={validateEmail}
                              placeholder=""
                            />

                            {errorEmail && (
                              <div style={mystyle}>{errorEmail}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                    <div class="col-lg-12 button">
                      <button class="btn" onClick={submitForm}>
                        Post a Job
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return { employee: state.employee, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { requestEmpLogin, 
      requestFormField,
      requestAddJob,
      requestCountry,
      requestState,
      requestCity, },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PostJob);

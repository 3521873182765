import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { requestEmpLogin, userLogout } from "../Redux/actions";
import WOW from "wowjs";
import Header from "../Components/header";
import Footer from "../Components/footer";
import Testimonials from "../Section/Testimonials";
import Apply from "../Section/applyProcessSection";

function LandingPage2(props) {
  useEffect(() => {
    new WOW.WOW().init();
    window.scrollTo(0, 0);
  }, []);

  const { loginData } = useSelector((state) => state.candidate);
  const [emp, setEmp] = useState({});

  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [erroremail, seterroremail] = useState("");
  const [errorpassword, seterrorpassword] = useState("");

  const mystyle = {
    color: "#D10000",
    backgroundColor: "#FFD2D2",
    padding: "3px 10px",
    border: "1px solid red",
    borderRadius: "5px",
    marginTop: "5px",
  };

  function onChangeData(e) {
    setData((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }

  function validateEmail() {
    let formIsValid = false;
    if (!data["email"]) {
      formIsValid = false;
      seterroremail("*Enter your E-mail ID.");
    } else if (typeof data["email"] !== "undefined") {
      if (
        !data["email"].match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
      ) {
        formIsValid = false;
        seterroremail("*Please enter valid E-mail ID.");
      } else {
        formIsValid = true;
        seterroremail("");
      }
    } else {
      formIsValid = true;
      seterroremail("");
    }
    return formIsValid;
  }
  function validatePassword() {
    let formIsValid = false;
    if (!data["password"]) {
      seterrorpassword("*Enter your password.");
      formIsValid = false;
    } else if (data["password"].length < 6) {
      seterrorpassword("*Enter atleast 6 character long password.");
      formIsValid = false;
    } else {
      seterrorpassword("");
      formIsValid = true;
    }
    return formIsValid;
  }
  function validateForm() {
    let email = validateEmail();
    let pass = validatePassword();
    let valid = email && pass;
    return valid;
  }

  function onSubmitForm(e) {
    e.preventDefault();
    props.userLogout();
    if (validateForm()) {
      props.requestEmpLogin({
        data: {
          email: data.email,
          password: data.password,
        },
      });
    }
  }

  useEffect(() => {
    let empLoginData = props.employee.empLoginData;
    if (empLoginData !== undefined) {
      if (empLoginData?.data?.status == "success") {
        // if (localStorage.getItem("link")) {56
        //   navigate(localStorage.getItem("link"));
        // } else {
        //   navigate("/home");
        // }
        navigate("/home");
      } else {
        seterrorpassword(empLoginData?.data?.message);
      }
    }
  }, [props.employee.empLoginData]);

  useEffect(() => {
    new WOW.WOW().init();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        className=" modal form-modal"
        style={{ display: "block", position: "static" }}
      >
        <Header name="Home" />
        <div className="modal-dialog max-width-px-840 position-relative"></div>



      {/* Start new section rotate cube */}
        <section className="common-spacing hireInsixty-Minutes">
         <div className="container">
          <div className=" align-items-center row">
          <div className="col-12 col-md-7 col-lg-7 px-0">

          <div class="section-title">
            <h2 class="" > Get your Dream Job in <span class="headingHighLight">60 minutes</span> by uploading your credential reports</h2>
          </div>


          <div class="button text-center "><a href="#0"> <button type="submit" class="btn ">Get hired in Record Time</button></a></div>

          </div>

          <div className="col-12 col-md-5 col-lg-5 ">
              <img src= {process.env.PUBLIC_URL +  "/assets/images/public-newimages/hirein60min.png"} alt="" className=" img-fluid"  />
          </div>

          </div>
         </div>
        </section>
        {/* End new section rotate cube */}



          {/* Start new section rotate cube */}
          <section className="common-spacing hireInsixty-Minutes bg-SectionClr">
         <div className="container">
          <div className=" align-items-center row">

          <div className="col-12 col-md-5 col-lg-5 text-center">
          <img src= {process.env.PUBLIC_URL +  "/assets/images/public-newimages/hirein5min.png"} alt=""  className="w-75 img-fluid"/>
          </div>

          <div className="col-12 col-md-7 col-lg-7 px-0">

          <div class="section-title">
            <h2 class="" > Build Your Profile In <span class="headingHighLight">5 Minutes</span> To Put Your Career On The Highway To Growth</h2>
          </div>


          <div class="button text-center "><a href="#0"> <button type="submit" class="btn ">Get hired in Record Time</button></a></div>
          </div>

          

          </div>
         </div>
        </section>
        {/* End new section rotate cube */}
    

    {/* =========== Start New Get Hired in 60 minutes ======= */}
    <section className="common-spacing ">
  <div className="container">
  <div class="section-title ">
        <h2 class="wow fadeInUp" data-wow-delay=".4s" >Get Hired in  <span class="headingHighLight"> 60 Minutes </span>  for your Dream Job </h2>
      </div>
    <div className="row row py-0  py-md-4">
    <div className="col-12 col-md-12 col-lg-4 mb-4">
    <div class="starter__content">
      <ul>
        <li>
          <div class="starter__item d-flex gap-3">
            <div class="starter__icon mr-25">
            <i class="fa fa-briefcase" aria-hidden="true"></i>
              </div>
            <div class="starter__text">
              <h4>Are you looking for a job?</h4>
              {/* <p>But must expla to you how this mistaken idea of denouncing pleure praising</p> */}
              {/* <a class="link-btn" href="#0"><span>We Can Help</span><i class="fas fa-arrow-right"></i></a> */}
              </div>
              </div>
          </li>
          {/* end list */}
          <li>
          <div class="starter__item d-flex gap-3">
            <div class="starter__icon mr-25"><i class="fa fa-video-camera" aria-hidden="true"></i></div>
            <div class="starter__text">
              <h4>Do you have a short Video Pitch?</h4>
              {/* <p>But must expla to you how this mistaken idea of denouncing pleure praising</p> */}
              {/* <a class="link-btn" href="#0"><span>We Can Help</span><i class="fas fa-arrow-right"></i></a> */}
              </div>
              </div>
          </li>
          {/* end list */}
          <li>
          <div class="starter__item d-flex gap-3">
            <div class="starter__icon mr-25"><i class="fa fa-question" aria-hidden="true"></i></div>
            <div class="starter__text">
              <h4>Are you happy in your <br /> current job?</h4>
              {/* <p>But must expla to you how this mistaken idea of denouncing pleure praising</p> */}
              {/* <a class="link-btn" href="#0"><span>We Can Help</span><i class="fas fa-arrow-right"></i></a> */}
              </div>
              </div>
          </li>
          {/* end list */}
          <li>
          <div class="starter__item d-flex gap-3">
            <div class="starter__icon mr-25"><i class="fa fa-list-alt" aria-hidden="true"></i></div>
            <div class="starter__text">
              <h4>Do you have a CV which Employers are looking for?</h4>
              {/* <p>But must expla to you how this mistaken idea of denouncing pleure praising</p> */}
              {/* <a class="link-btn" href="#0"><span>We Can Help</span><i class="fas fa-arrow-right"></i></a> */}
              </div>
              </div>
          </li>
          {/* end list */}
          </ul>
          </div>
    </div>
    {/* End left item */}
    {/* Start center item */}
     <div className="col-12 col-md-12 col-lg-4 mb-4 text-center">
     <img alt="starter-img" src= { process.env.PUBLIC_URL + "/assets/images/public-newimages/hirein60minutes-dreamjob.png"} />
     <div class="button text-center gethiredbtn "><a href="#0" className="w-100"> <button type="submit" class="btn w-100">We Can Help</button></a></div>
     </div>
    {/* End center item */}
    {/* Start Right item */}
    <div className="col-12 col-md-12 col-lg-4 mb-4">
    <div class="starter__content">
      <ul>
        <li>
          <div class="starter__item d-flex gap-3">
            <div class="starter__icon mr-25"><i class="fa fa-sticky-note" aria-hidden="true"></i></div>
            <div class="starter__text">
              <h4>Do you have a personality report?</h4>
              {/* <a class="link-btn" href="#0"><span>We Can Help</span><i class="fas fa-arrow-right"></i></a> */}
              </div>
              </div>
          </li>
          {/* end item  */}
          <li>
          <div class="starter__item d-flex gap-3">
            <div class="starter__icon mr-25"><i class="fa fa-list" aria-hidden="true"></i></div>
            <div class="starter__text">
              <h4>Do you have skill evaluation report?</h4>
              {/* <a class="link-btn" href="#0"><span>We Can Help</span><i class="fas fa-arrow-right"></i></a> */}
              </div>
              </div>
          </li>
          {/* end item  */}
          <li>
          <div class="starter__item d-flex gap-3">
            <div class="starter__icon mr-25"><i class="fa fa-medkit" aria-hidden="true"></i></div>
            <div class="starter__text">
              <h4>Do you have your wellness report?</h4>
              {/* <a class="link-btn" href="#0"><span>We Can Help</span><i class="fas fa-arrow-right"></i></a> */}
              </div>
              </div>
          </li>
          {/* end item  */}
          

          </ul>
          </div>
    </div>
    {/* End Right item */}
    </div>
    
  </div>
 </section>
 {/* =========== End New Get Hired in 60 minutes ======= */}


 <Apply />



        {/* <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6">
              <div className="custom-size text-center">
                <Link
                  to={"/profile"}
                  data-wow-delay=".3s"
                  className="btn btn-primary wow fadeInUp"
                  style={{ width: "50%", color: "#fff" }}
                >
                  Create Profile
                </Link>
              </div>
            </div>
           
          </div>
        </div> */}

        <Testimonials />

        <Footer />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return { employee: state.employee };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestEmpLogin, userLogout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage2);

import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestSubscribe } from "../Redux/actions";
import useIntercom from "use-intercom-hook";
import Swal from "sweetalert2";
import logo  from "../assets/logo/02.png";

function Footer(props) {

  const mystyle = {
    color: "#D10000",
    backgroundColor: "#FFD2D2",
    padding: "3px 10px",
    border: "1px solid red",
    borderRadius: "5px",
    marginTop: "5px",
  };

  // useIntercom(process.env.REACT_APP_INTERCOM_APP_ID);
  const [email, setemail] = useState("");
  const [errorEmail, seterrorEmail] = useState("");
  function validateEmail() {
    let valid = false;
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      seterrorEmail("");
      valid = true;
    } else {
      seterrorEmail("Enter valid email");
      valid = false;
    }
    return valid;
  }
  function validateForm() {
    let email = validateEmail();
    return email;
  }
  function onchange(e) {
    setemail(e.target.value);
  }
  function onSubmitForm(e) {
    e.preventDefault();
    if (validateForm()) {
      props.requestSubscribe({
        data: {
          email: email,
        },
      });
    }
  }

  useEffect(() => {
    let subscribeData = props.candidate.subscribeData;
    if (subscribeData !== undefined) {
      if (subscribeData?.data?.status === "success") {
        Swal.fire(
          "Good job!",
          "Subscibed your email id successfully.",
          "success"
        );
        props.candidate.subscribeData = undefined;
        setemail("");
      } else {
        Swal.fire("Error!", "The email has already been taken.", "error");
        props.candidate.subscribeData = undefined;
        setemail("");
      }
    }
  }, [props.candidate.subscribeData]);

  function gototop() {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <footer class="footer">
        <div class="footer-middle">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-12">
                <div class="f-about single-footer">
                  <div class="logo">
                    <a href="/home">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >

<div>
<img src={logo} alt='Hired60' />
<div className="logotagline ft-tagline text-white " >Get Hired in 60 minutes</div>
                      </div>
                       
                        {/* <div
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#000",
                            borderTopLeftRadius: 40,
                            borderBottomLeftRadius: 0,
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                            width: 120,
                            height: 40,
                          }}
                        >
                          <h4
                            style={{
                              color: "#fff",
                              textAlign: "center",
                              marginTop: "10px",
                            }}
                          >
                            &nbsp;{"Portal"}
                          </h4>
                        </div> */}
                      </div>
                    </a>
                  </div>

                  <ul class="contact-address pl-0">
                    <li>
                      <span>Address:</span>
                      <a>  K1/ 114, Chittaranjan Park, <br />New Delhi- 110019</a>
                    </li>
                    <li>
                      <span>Email:</span>{" "}
                      <a href="mailto:info@hired60.in">
                      info@hired60.in
                      </a>
                    </li>
                    <li>
                      <span>Call:</span>  <a href="tel:999950999" >+91 9999509990</a>
                    </li>
                  </ul>
                  <div class="footer-social">
                    <ul>
                      <li>
                        <a href="#">
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                        <i class="fa fa-youtube-play" aria-hidden="true"></i>

                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-md-6 col-12">
                <div class="row">
                  <div class="col-lg-4 col-md-6 col-12">
                    <div class="single-footer f-link">
                      <h3>For JobSeekers</h3>
                      <ul className="pl-0">
                        <li>
                          <a href="/profile">User Profile</a>
                        </li>
                        <li>
                          <a href="/jobAlerts">Job Alerts</a>
                        </li>
                        {/* <li>
                          <a href="/jobList/1/10">Job List</a>
                        </li>
                        <li>
                          <a href="/browseJobs">Browse Jobs</a>
                        </li>
                        <li>
                          <a href="/browseCategories">Browse Categories</a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6 col-12">
                    <div class="single-footer f-link">
                      <h3>For Employers</h3>
                      <ul className="pl-0">
                        <li>
                          <a href="#0">Post your requirement</a>
                        </li>
                        {/* <li>
                          <a href="/empProfile">Company Profile</a>
                        </li> */}
                        <li>
                          <a href="#0">Manage Jobs</a>
                        </li>
                        {/* <li>
                          <a href="/manageApplications">Manage Applications</a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12 col-12">
                    <div class="single-footer newsletter">
                      <h3>Join Our Newsletter</h3>
                      <p>
                        Subscribe to get the latest updates
                      </p>
                      <form onSubmit={onSubmitForm}>
                        <div class="form-group">
                          <input
                            type="email"
                            class="form-control"
                            placeholder="Your email address"
                            id="email"
                            name="email"
                            value={email}
                            onBlur={validateEmail}
                            onChange={onchange}
                          />
                          {errorEmail && (
                            <div style={mystyle}>{errorEmail}</div>
                          )}
                          <div class="button">
                            <button class="btn ">Subscribe Now!</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-bottom">
          <div class="container">
            <div class="inner">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="left"></div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="right">
                    <ul>
                      <li>
                        <a href="/privacyPolicy"> Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/faq">Faq</a>
                      </li>
                      <li>
                        <a href="/contact">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <a
        class="scroll-top btn-hover floatingbtn-scroll"
        onClick={gototop}
      >
        <i class="fa fa-chevron-up"></i>
      </a>


   <div className="floading-btn">
    <h2> 
      <a target="_blank" href="https://wa.me/919999509990">
      <img src= {process.env.PUBLIC_URL + "/assets/images/clients/whatsapp.webp"} alt="#" className="img-fluid"/>
      </a>
      
    </h2>
   </div>

    </>
  );
}
const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestSubscribe }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

import Footer from "./footer";
import Header from "./header";
import ManageAccount from "./manageAccount";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

import {
  requestLogin,
  requestCandidateLogo,
  requestCandidateVideo,
  requestGetCandidate,
} from "../Redux/actions";
import WOW from "wowjs";
import camera from "../images/camera.png";
import img1 from "../images/profile.png";
import Swal from "sweetalert2";
import Breadcrumbs from "../Section/breadcrumbsSection";
import { Document, Page, pdfjs } from "react-pdf";
import Resumepartner from "../DashboardComponent/ResumePartner";
import ResumeUpload from "../DashboardComponent/ResumeUplaod";
import MainTopComponent from "../DashboardComponent/topdashboard";


const AddUploadResume = (props) => {

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [pdf, setPdf] = useState("");

  useEffect(() => {
    new WOW.WOW().init();
    // window.scrollTo(0, 0);
    localStorage.removeItem("link");
  }, []);

  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [emp, setEmp] = useState({});
  const [img, setimg] = useState("");
  const [video, setvideo] = useState("");
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    let loginData = props.candidate.loginData;
    if (loginData !== undefined) {
      if (loginData?.data?.status == "success") {
        setEmp(loginData.data.data);
        props.requestGetCandidate({
          id: loginData.data.data.id,
          token: loginData.data.data.token,
        });
      } else {
        localStorage.setItem("link", "/videoresume");
        navigate("/login");
      }
    } else {
      localStorage.setItem("link", "/videoresume");
      navigate("/login");
    }
  }, [props.candidate.loginData]);

  const onSelectFile = (e) => {
    setimg(URL.createObjectURL(e.target.files[0]));
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
    } else {
      setSelectedFile(e.target.files[0]);
    }
  };

  function submitForm(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("profile", selectedFile);

    props.requestCandidateVideo({
      id: emp.id,
      data: formData,
      token: emp.token,
    });
  }

  useEffect(() => {
    let getCandidateData = props.candidate.getCandidateData;
    if (getCandidateData !== undefined) {
      if (getCandidateData.data.status == "success") {
        setData(getCandidateData.data.data);
        if (getCandidateData.data.data.video_resume) {
          setvideo(
            process.env.REACT_APP_API_HOST + getCandidateData.data.data.video_resume
          );
        } else {
          setvideo(img1);
        } 
      }
    }
  }, [props.candidate.getCandidateData]);

  useEffect(() => {
    let candidatelogo = props.candidate.candidateVideoData;
    if (candidatelogo !== undefined) {
      console.log(candidatelogo.data)
      if (candidatelogo.data!=undefined && candidatelogo.data.status == "success") {
        Swal.fire(
          "Good job!",
          "Video updated Successfully.",
          "success"
        );
        props.candidate.candidateVideoData = undefined;
        props.requestGetCandidate({
          id: emp.id,
          token: emp.token,
        });
        navigate("/videoresume");
      } else {
        Swal.fire(
          "Error!",
          "Please select mp4 format for video.",
          "error"
        );

        props.candidate.candidateVideoData = undefined;
        props.requestGetCandidate({
          id: emp.id,
          token: emp.token,
        });
        navigate("/videoresume");
      }
    }
  }, [props.candidate.candidateVideoData]);

  
  useEffect(() => {
    let getCandidateData = props.candidate.getCandidateData;
    console.log("data======", getCandidateData)
    if (getCandidateData !== undefined) {
      if (getCandidateData?.data?.status == "success") {
        setData(getCandidateData.data.data);
        console.log("data",getCandidateData.data.data)
        setPdf(process.env.REACT_APP_API_HOST + getCandidateData.data.data.resume);
      }
    }
  }, [props.candidate.getCandidateData]);



  const [pdffle, setpdffile] = useState(null);

  const handleFileUpload = (file) => {
    setpdffile(file);
    // You can now handle the file, e.g., send it to the server.
  };


  const handleDeleteFile = () => {
    setpdffile(null);
  };


  const handleEdit = () => {
    // Create an input for re-uploading the PDF
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".pdf";
    input.onchange = (e) => {
      const editedFile = e.target.files[0];
      setSelectedFile(editedFile);
      // You can now handle the edited PDF file, such as sending it to the server for further processing.
      // Optionally, you can also update the UI to show the edited file's name.
    };
    input.click();
  };

  

  //  data.resume ? (
  //   {console.log("pdf >>>>>>", process.env.REACT_APP_API_HOST + pdf)}
  // ) : (
  //     ""
  // )


  return (
    <>
      <Header />
      <Breadcrumbs title="Profile Picture" />
      <div class="resume section">
        <div class="container">

        <MainTopComponent 
      username = {data.first_name}
      lastname = {data.last_name}
      userimage = {data.profile}
      useraddress = {data.address}
      useraddress2 = {data.address2}
      phone = {data.phone}
      email = {data.email}
      experience = {data.experience}
      notice_period = {data.notice_period}
      pincode = {data.pincode}
      city = {data.city_name}
      state = {data.state_name}
      country = {data.country_name}
      />



          <div class="resume-inner">
            <div class="row">
              <ManageAccount name="AddUploadResume" from="submenu2" />
              <div class="col-lg-8 col-12">
                <div class="inner-content">
                  <div class="alerts-inner">
                  <h3 class="dashborardpage-subtitile">Upload Resume</h3>
      <div className="justify-content-between row ">
        <div className="col-8">
        <ResumeUpload onFileUpload={handleFileUpload} onDeleteFile={handleDeleteFile}/>
                {pdffle && (
        <div className="d-flex gap-2 mt-2">
         <div className="button"> <button  onClick={handleEdit} className="btn">Edit</button></div>
          <div className="button"><button onClick={handleDeleteFile} className="btn seconday-btn">Delete</button></div>
        </div>
      )}
        </div>

  <div className="col-4 button text-right viewpdiv">
  {data.resume ? (<a href = {pdf} target="_blank"><button className="btn small">View Resume </button></a>) : (" ")}
  </div>

      </div> 


   

  {/* =========================== */}
                  <div class="row">
                 

               {/* <div className="col-10">
                <ResumeUpload onFileUpload={handleFileUpload} onDeleteFile={handleDeleteFile}/>
                {pdffle && (
        <div className="d-flex gap-2 mt-2">
         <div className="button"> <button  onClick={handleEdit} className="btn">Edit</button></div>
          <div className="button"><button onClick={handleDeleteFile} className="btn seconday-btn">Delete</button></div>
        </div>
      )}

    </div> */}

   


     

    {/* <div className="button mt-3 col-2">

           {data.resume ? (
            <a href = {pdf} target="_blank"><button className="btn">View Resume</button></a>
                   
                  ) : (
                   " "
                  )}

    </div> */}

<Resumepartner/>
{/* End Upload Resume */}

                

              
                    </div>

                    {/* =========================== */}



                 
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return { candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestLogin,
      requestCandidateLogo,
      requestCandidateVideo,
      requestGetCandidate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddUploadResume);

import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import logo  from "../assets/logo/02.png"

import {
  requestLogin,
  requestEmpLogin,
  userLogout,
  requestRecentlyJob,
  requestGetCandidate,
  requestGetEmp,
} from "../Redux/actions";
import "./DarkMode.css";
import jwt_decode from "jwt-decode";
import userEvent from "@testing-library/user-event";
function Header(props) {
  const navigate = useNavigate();
  const [user, setUSer] = useState({});
  const [emp, setEmp] = useState({});
  const [headername, setHeadername] = useState({});
  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          props.userLogout();
        }
        return error;
      }
    );
  }, []);
  function logout() 
  {
    props.userLogout();
    navigate("/home");
  }
  useEffect(() => {
    let loginData = props.candidate.loginData;
    if (loginData !== undefined) {
      if (loginData?.data?.status === "success") {
        let a = window.location.href
            a = a.split('/')
        let checkRoute = a[a.length-1]
        if(['login', 'register'].includes(checkRoute)){
            window.location.href = '/resume'
        }else
        if(['emplogin', 'empregister'].includes(checkRoute)){
            window.location.href = '/'
        }
        setUSer(loginData.data.data);
        if(!props?.candidate?.getCandidateData){
          // props?.candidate?.getCandidateData!=undefined
          // props.requestGetCandidate({
          //   id: loginData.data.data.id,
          //   token: loginData.data.data.token,
          // });
        }
if(props.candidate.getCandidateData!=undefined)
        setHeadername(props?.candidate?.getCandidateData!=undefined && props.candidate.getCandidateData?.data?.data.first_name+' '+props.candidate.getCandidateData.data?.data?.last_name);
        else {
          props.requestGetCandidate({
            id: loginData.data.data.id,
            token: loginData.data.data.token,
          });
          setHeadername('User')        
        }
      } else {
        setUSer({});
      }
    } else {
      setUSer({});
    }
  }, [props.candidate.loginData, props.candidate.getCandidateData]);
  useEffect(() => {
    let empLoginData = props.employee.empLoginData;
    // let loginData = props.employee.loginData;
    console.log('loginData >>> ', props)
    
    if (empLoginData !== undefined) {
      if (empLoginData?.data?.status === "success") {
        let a = window.location.href
            a = a.split('/')
        let checkRoute = a[a.length-1]
        if(['login', 'register'].includes(checkRoute)){
            window.location.href = '/resume'
        }else
        if(['emplogin', 'empregister'].includes(checkRoute)){
            window.location.href = '/manageJobs'
        }

        setEmp(empLoginData.data!=undefined &&  empLoginData.data.data);
        // console.log("props.employee==",)
        if(props.employee.empData!=undefined)
         {
          setHeadername(props?.employee?.empData!=undefined && props.employee.empData.data?.data?.name )
         }
         else{
          let empdata= {
            id: empLoginData?.data?.data?.id,
            token: empLoginData?.data?.data?.token,
          }
          console.log(' empdata ????????????????? ', empdata)
            props.requestGetEmp(empdata);
           setHeadername('Emp')
          }
         

      } else {
        setEmp({});
      }
    } else {
      setEmp({});
    }
  }, [props.employee.empLoginData, props?.employee?.empData]);


  function gotToTop() {
    window.scrollTo(0, 0);
  }







  const gotodashboardtop = useRef(null);


  const scrollfun = () => {
    console.log('sasa >>> ', window.location.href.includes('profile'))
    if(!window.location.href.includes('profile')){  
    if (gotodashboardtop.current) {
      const element = gotodashboardtop.current;
  
      const offset = element.getBoundingClientRect().top + window.pageYOffset - 30;
  
      window.scrollTo({
        top: offset,
        behavior: 'smooth', 
      });
    }
    }
    
  };


  const [isDarkMode, setIsDarkMode] = useState(() => {
    if (localStorage.getItem("dark") === "true") {
      document.body.classList.add("dark");
      return true;
    } else {
      return false;
    }
  });
  const setDarkMode = (e) => {
    localStorage.setItem("dark", !isDarkMode);
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle("dark");
  };



  const [active, setActive] = useState(null)

  function activeMenu(link) {
    setActive(link)

  }

  
useEffect(()=> {
     let e = window.location.href;
     e = e.split('/')
     e = e[e.length - 1]
     setActive('/' + e)
  
  }, [])


  return (
    <>
        <header class="header other-page" >
        <div class="navbar-area" >
          <div class="container-fluid container-lg" >
            <div class="row align-items-center" >
              <div class="col-lg-12">
                <nav class="navbar navbar-expand-lg " style={{ backgroundColor: "#fff" }}>
                  <Link class="navbar-brand logo" to="/home" onClick={gotToTop}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",


                      }}
                    >
                      <div>
                         <img src={logo} alt='Hired60'/>
                         <div className="logotagline" >
                         Get Hired in 60 minutes
                         </div>
                         
                      </div>
                      {/* <div
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#000",
                          borderTopLeftRadius: 40,
                          borderBottomLeftRadius: 0,
                          borderTopRightRadius: 8,
                          borderBottomRightRadius: 8,
                          width: 120,
                          height: 40,
                        }}
                      >
                        <h4
                          style={{
                            color: "#fff",
                            textAlign: "center",
                            marginTop: "10px",
                          }}
                        >
                          &nbsp;{"Portal"}
                        </h4>
                      </div> */}



                    </div>
                  </Link>
                  <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    style={{ backgroundColor: "#0383d4", }}
                  >
                    <span class="toggler-icon"></span>
                    <span class="toggler-icon"></span>
                    <span class="toggler-icon"></span>
                  </button>
                  <div
                    class="collapse navbar-collapse sub-menu-bar open-nav"
                    id="navbarSupportedContent"
                  >
                    {/* ml-auto */}
                    <ul id="nav" class="navbar-nav ms-auto">
                      <li className= {`nav-item ${active  === '/home'   ? "activemenu" : " "}`} >
                        
                        <Link to="/home"   onClick={ ()=> {gotToTop();  activeMenu('/')  }  }   
                        >
                          Home
                        </Link>
                      </li>
                      <li className= {`nav-item ${active === '/aboutUs' ? "activemenu" : " "}`}>
                      <Link to="/aboutUs"  onClick={ ()=> { gotToTop();  activeMenu('/aboutUs') }   }
                      >About Us</Link>
                      </li>

                      {/* <li class="nav-item">
                        <Link to="">Pages</Link>
                        <ul class="sub-menu">
                          <li>
                            <Link to="/aboutUs">About Us</Link>
                          </li>
                          <li>
                            <Link to="/privacyPolicy">Privacy Policy</Link>
                          </li>
                          <li>
                            <Link to="/faq">Faq</Link>
                          </li>
                        </ul>
                      </li> */}
                      {/* {!emp.id && (
                        <li class="nav-item">
                          <Link to="">JobSeeker</Link>
                          <ul class="sub-menu">
                            <li>
                              <Link to="/jobList/1/10">Job List</Link>
                            </li>
                            <li>
                              <Link to="/browseJobs">Browse Jobs</Link>
                            </li>
                            <li>
                              <Link to="/browseCategories">
                                Browse Categories
                              </Link>
                            </li>
                          </ul>
                        </li>
                      )} */}


                      { emp.id && (
                        <li class="nav-item">
                          <Link to="">Employers </Link>
                          <ul class="sub-menu">
                            <li>
                              <Link onClick={gotToTop} to="/postJob">
                                Post A Job
                              </Link>
                            </li>
                            <li>
                              <Link onClick={gotToTop} to="/manageJobs">
                                Manage Jobs
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={gotToTop}
                                to="/manageApplications"
                              >
                                Manage Applications
                              </Link>
                            </li>
                            <li>
                              <Link onClick={gotToTop} to="/interviews">
                                Interviews
                              </Link>
                            </li>
                          </ul>
                        </li>
                      )}
                      
                      {
                        user.id && (
                          <>
                            <li className= {`nav-item ${active === '/job-seeker-landing' ? "activemenu" : " "}`}>
                              <Link to="/job-seeker-landing"  
                               
                               onClick={ ()=> { gotToTop(); activeMenu('/job-seeker-landing') }   }
                              
                              >JobSeeker </Link>{" "}
                            </li>
                          </>
                        )
                      }

                      {
                        (!user.id && !emp.id) && (
                          <>
                            <li className= {`nav-item ${active === '/job-seeker-landing' ? "activemenu" : " "}`}>

                              <Link 
                              to="/job-seeker-landing" 
                              onClick={ ()=> { gotToTop(); activeMenu('/job-seeker-landing') }   }
                              >JobSeeker </Link>{" "}

                            </li>
                            <li className= {`nav-item ${active === '/emp-landing' ? "activemenu" : " "}`} >

                            <Link to="/emp-landing"
                            
                            onClick={ ()=> { gotToTop(); activeMenu('/emp-landing') }   }
                            
                            >Employer 
                            
                            </Link>{" "}
                          </li>
                          </>
                        )
                      }

{/* 
                      {(!emp.id && !user.id) && (
                        <>
                          <li class="nav-item">
                            <Link to="/emp-landing">Employer </Link>{" "}
                          </li>

                        </>
                      )} */}
                      
                      <li className= {`nav-item ${active === '/contact' ? "activemenu" : " "}`}>
                        <Link to="/contact"   
                        onClick={ ()=> 
                        { 
                        gotToTop(); 
                        activeMenu('/contact') 
                        } }>
                          Contact
                          </Link>{" "}
                      </li>
                      <li class="nav-item ">
                        {(() => {
                          if (emp.id) {
                            return (
                              <>
                                <Link         
                                  to=""
                                  class="login"
                                  style={{ color: "#2042e3" }}
                                >
                                  <i class="lni lni-lock-alt"></i> {headername}
                                </Link>
                                <ul class="sub-menu">
                                  {/* <li>
                                    <Link to="/empLogo">Company Logo</Link>
                                  </li> */}
                                  {/* <li>
                                    <Link to="/empProfile">My Profile</Link>
                                  </li> */}
                                  <li>
                                    <Link  to="/manageJobs">
                                      Dashboard
                                    </Link>
                                  </li>
                                  {/* <li>
                                    <Link onClick={gotToTop} to="/empsettings">
                                      Settings
                                    </Link>
                                  </li> */}
                                  <li>
                                    <a onClick={logout} href="/home">
                                      Sign Out
                                    </a>
                                  </li>
                                </ul>
                              </>
                            );
                          } else if (user.id) {
                            return (
                              <>
                                <Link
                                  to=""
                                  class="login"
                                  style={{ color: "#2042e3" }}
                                >
                                  <i class="lni lni-lock-alt"></i> {headername}
                                </Link>
                                <ul class="sub-menu">
                                  {/* <li>
                                    <Link to="/picture">Profile Picture</Link>
                                  </li>
                                  <li>
                                    <Link to="/profile">My Profile</Link>
                                  </li> */}
                                  {/* <li>
                                    <Link onClick={gotToTop} to="/picture">
                                      My Resume
                                    </Link>
                                  </li> */}
                                  <li>
                                    <Link   onClick={scrollfun} to="/profile">
                                      Dashboard
                                    </Link>
                                  </li>
                                  {/* <li>
                                    <Link onClick={gotToTop} to="/settings">
                                      Settings
                                    </Link>
                                  </li> */}
                                  <li>
                                    <a onClick={logout} href="/home">
                                      Sign Out
                                    </a>
                                  </li>
                                </ul>
                              </>
                            );
                          } else {
                            return (
                              <>

                              
                              {/* <Link
                                to="/login"
                                class="login"
                                style={{ color: "#2042e3" }}
                              >
                                <i class="lni lni-lock-alt"></i> Login
                              </Link> */}

                                <Link
                                  to=""
                                  class="login"
                                  style={{ color: "#2042e3" }}
                                >
                                  <i class="lni lni-lock-alt"></i> Login
                                </Link>
                                <ul class="sub-menu">
                                  <li>
                                    <Link onClick={gotToTop} to="/login">
                                      Jobseeker
                                    </Link>
                                  </li>
                                  <li>
                                    <Link onClick={gotToTop} to="/emplogin">
                                      Employer 	
                                    </Link>
                                  </li>
                                  
                                </ul>
                              </>
                              
                            );
                          }
                        })()}
                      </li>
                    </ul>
                  </div>
                  {!user.id && (
                    <>

                    {/* <div class="button">
                      <Link onClick={gotToTop} to="/postJob" class="btn">
                        Post Job
                      </Link>
                    </div> */}
                    </>
                  )} 
                  {/* <button
                    onClick={setDarkMode}
                    style={{
                      paddingLeft: "25px",
                      border: "0px",
                      backgroundColor: "transparent",
                    }}
                  >
                    {isDarkMode ? (
                      <i class="fa fa-sun-o" style={{ fontSize: "17px" }}></i>
                    ) : (
                      <i class="fa fa-moon-o" style={{ fontSize: "17px" }}></i>
                    )}
                  </button> */}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
const mapStateToProps = (state) => {
  return { candidate: state.candidate, employee: state.employee };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { requestLogin, requestEmpLogin, userLogout, requestRecentlyJob, requestGetCandidate, requestGetEmp },
    
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Header);

import {
  RECEIVE_GET_RELATIONS
} from "./actions";

export default (state = {}, { type, data }) => {
  switch (type) {

    case RECEIVE_GET_RELATIONS:
      return {
        ...state,
        relationsMasterData: data,
      };


    default:
      return state;
  }
};


import React, { useEffect, useState } from "react";
import Topdashboard from "../Components/dashboardTop";





function MainTopComponent (props) {

    // const [userPhoto, setUserPhoto] = useState(process.env.REACT_APP_API_HOST + props.userimage);
    
    const userPhoto = process.env.REACT_APP_API_HOST + props.userimage

const [profileComplete, setProfileComplete] = useState(15);

// console.log( "child passing data >>>>>>>>>>>>>", userPhoto)





const gotoDashboard = React.useRef(null);

const scrollfun = () => {
    if (gotoDashboard.current) {
      const element = gotoDashboard.current;
  
      const offset = element.getBoundingClientRect().top + window.pageYOffset - 100;
   
      window.scrollTo({
        top: offset,
        behavior: 'smooth', 
      });
    
  }
  
};
useEffect(() => {
    scrollfun()
}, [])


  
function totalpersentage(value){
        
   }

return (
    <div ref={gotoDashboard}>
<Topdashboard 
 profileComplete={profileComplete}
 totalpersentage = {totalpersentage}
 userPhoto={userPhoto} 
 name = {props.username}
 lastname = {props.lastname}
 useraddress = {props.useraddress}
 useraddress2 = {props.useraddress2}
 pincode = {props.pincode}
 city = {props.city}
 state = {props.state}
 country = {props.country}
 phone = {props.phone}
 email = {props.email}
 experience = {props.experience}
 notice_period = {props.notice_period}
 jobtitle = {props.jobtitle}
 current_salary = {props.current_salary}
 
 />
</div>
)


}

export default MainTopComponent;
import { useEffect } from "react";

import WOW from "wowjs";

function JobCategorySection(props) {
  useEffect(() => {
    new WOW.WOW().init();
  }, []);


  const jobcategory = [
    {
      id: "1",
      jobcatname : "Project Manager",
      icon: <i class="fa fa-user" aria-hidden="true"></i>,
      salary: '25000 - 30000 INR',
    },

    {
      id: "2",
      jobcatname : "Artificial Intelligence (AI) Engineer",
      icon : <i class="fa fa-server" aria-hidden= "true"></i>,
      salary: '50000 - 60000 INR',
    },

    {
      id: "3",
      jobcatname : "Data Scientist",
      icon : <i class="fa fa-database" aria-hidden="true"></i>,
      salary: '50000 - 60000 INR',
    },
    {
      id: "4",
      jobcatname : "Machine Learning Engineer",
      icon : <i class="fa fa-pie-chart" aria-hidden="true"></i>,
      salary: '50000 - 60000 INR',
    },

    {
      id: "5",
      jobcatname : "Blockchain Developer",
      icon : <i class="fa fa-lock" aria-hidden="true"></i>,
      salary: '50000 - 60000 INR',
    },
    {
      id: "6",
      jobcatname : "Full Stack Software Developer",
      icon : <i class="fa fa-desktop" aria-hidden="true"></i>,
      salary: '50000 - 60000 INR',
    },

    {
      id: "7",
      jobcatname : "Management Consultant",
      icon : <i class="lni lni-fireworks"></i>,
      salary: '50000 - 60000 INR',
    },
    {
      id: "8",
      jobcatname : "Marketing Manager",
      icon : <i class="fa fa-users" aria-hidden="true"></i>,
      salary: '50000 - 60000 INR',
    },
    {
      id: "9",
      jobcatname : "Business Analyst",
      icon : <i class="fa fa-line-chart" aria-hidden="true"></i>,
      salary: '50000 - 60000 INR',
    },
    {
      id: "10",
      jobcatname : "Investment Banker",
      icon : <i class="fa fa-money" aria-hidden="true"></i>,
      salary: '50000 - 60000 INR',
    },
    // {
    //   id: "10",
    //   jobcatname : "Chartered Accountant",
    //   icon : <i class="fa fa-briefcase" aria-hidden="true"></i>,
    //   salary: '50000 - 60000',
    // },

  ]

  return (
   <>
<section class="job-category section common-spacing ">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section-title">
             
              <h2 class="wow fadeInUp" data-wow-delay=".4s">
                
               Employers <span class="headingHighLight"> are eager </span> to hire
              </h2>
             
            </div>
          </div>
        </div>

    
    <div className="row">
    {
              jobcategory.map((item) => {
                return (
                  <>
                  {/* start category Card */}
						<div class="col-12 col-lg-6 mb-15 mb-md-25 mb-lg-35" key={item.id}>
							<article class="popular-jobs-box">
								<div class="box-holder">
									<div class="job-info">
										<div class="img-holder">
											{/* <img src="images/company-logo01.jpg" width="78" height="78" alt="Image Description" /> */}
                      {item.icon}
										</div>
										<div class="textbox">
											<h3 class="h5">{item.jobcatname} </h3>
											<ul class="meta-list">
												{/* <li><span class="text">By WebTechnology</span></li> */}
												{/* <li><i class="icon-map-pin"></i><span class="text">Pekanbaru, Indonesia</span></li> */}
											</ul>
											<ul class="tags-list">
												<li><span class="tag">Remote</span></li>
												<li><span class="tag">Full Time</span></li>
												<li><span class="tag">Part Time</span></li>
											</ul>
										</div>
									</div>
									<footer class="jobs-foot">
										<strong class="amount">{item.salary} <span>/month</span></strong>
										{/* <a href="#0" class="button"><span class="btn small">Apply Now</span></a> */}
									</footer>
								</div>
							</article>
						</div>
            {/* end category Card */}
                  </>
                )
              })
            }
    </div>


      </div>
    </section>

</>

  
  );
}
export default JobCategorySection;

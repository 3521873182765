import Footer from "./footer";
import Header from "./header";
import ManageAccount from "./manageAccount";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  requestLogin,
  requestFormField,
  requestGetCandidate,
  requestCandidateProfile,
  requestCandidateLogo,
  requestCountry,
  requestState,
  requestCity,
} from "../Redux/actions";
import WOW from "wowjs";
import Swal from "sweetalert2";
import Breadcrumbs from "../Section/breadcrumbsSection";
import MainTopComponent from "../DashboardComponent/topdashboard";
import { BsCameraFill } from 'react-icons/bs';
import { AiTwotoneEdit } from 'react-icons/ai';

function Profile(props) {
  useEffect(() => {
    new WOW.WOW().init();
    // window.scrollTo(0, 0);
    localStorage.removeItem("link");
  }, []);

  const mystyle = {
    color: "#D10000",
    backgroundColor: "#FFD2D2",
    padding: "3px 10px",
    border: "1px solid red",
    borderRadius: "5px",
    marginTop: "5px",
  };

  const [languages, setLanguages] = useState([]);


  const [data, setData] = useState({});
  const [countryId, setcountryId] = useState(0);
  
  const [stateId, setstateId] = useState(0);
  const [cityId, setcityId] = useState(0);
  const [selectedskill, setselectedskill] = useState([]);

  const [cities, setcities] = useState([]);
  const [states, setstates] = useState([]);

  const [citiesp, setcitiesp] = useState([]);
  const [statesp, setstatesp] = useState([]);
  const [action, setAction] = useState([]);

  const [countries, setcountries] = useState([]);

  const [skill, setskill] = useState([]);
  const [career_level, setcareer_level] = useState([]);

  const [industry, setindustry] = useState([]);
  const [functional_area, setfunctional_area] = useState([]);
  const [currency, setcurrency] = useState([]);

  const [errorfirst_name, seterrorfirst_name] = useState("");
  const [errorlast_name, seterrorlast_name] = useState("");
  const [errorgender, seterrorgender] = useState("");
  const [errorcountry, seterrorcountry] = useState("");
  const [errorstate, seterrorstate] = useState("");
  const [errorcity, seterrorcity] = useState("");
  const [errorbirth_date, seterrorbirth_date] = useState("");
  const [errormarital_status, seterrormarital_status] = useState("");
  const [errorlanguages, seterrorlanguages] = useState("");
  const [errornationality, seterrornationality] = useState("");
  const [errornational_id_card, seterrornational_id_card] = useState("");
  const [errorexperience, seterrorexperience] = useState("");
  const [errorcareer_level, seterrorcareer_level] = useState("");
  const [errorindustry, seterrorindustry] = useState("");
  const [errorfunctional_area, seterrorfunctional_area] = useState("");
  const [errorcurrent_salary, seterrorcurrent_salary] = useState("");
  const [errorexpected_salary, seterrorexpected_salary] = useState("");
  const [errorsalary_currency, seterrorsalary_currency] = useState("");
  const [errorimmediate_available, seterrorimmediate_available] = useState("");
  const [erroraddress, seterroraddress] = useState("");
  const [errorfacebook_url, seterrorfacebook_url] = useState("");
  const [errortwitter_url, seterrortwitter_url] = useState("");
  const [errorlinkedin_url, seterrorlinkedin_url] = useState("");
  const [errorgoogle_plus_url, seterrorgoogle_plus_url] = useState("");
  const [errorpinterest_url, seterrorpinterest_url] = useState("");
  const [img, setimg] = useState("");
  const [selectedFile, setSelectedFile] = useState();

  const navigate = useNavigate();
  const [emp, setEmp] = useState({});

  useEffect(() => {
    let loginData = props.candidate.loginData;
    if (loginData !== undefined) {
      if (loginData?.data?.status == "success") {
        setEmp(loginData.data.data);
        props.requestGetCandidate({
          id: loginData.data.data.id,
          token: loginData.data.data.token,
        });
        props.requestFormField({
          token: loginData.data.data.token,
        });
      } else {
        localStorage.setItem("link", "/profile");
        navigate("/login");
      }
    } else {
      localStorage.setItem("link", "/profile");
      navigate("/login");
    }
  }, [props.candidate.loginData]);

  useEffect(() => {
    let getCandidateData = props.candidate.getCandidateData;
    if (getCandidateData !== undefined) {
      if (getCandidateData?.data?.status === "success") {
        setData(getCandidateData.data.data);
        setcountryId(getCandidateData.data.data.country);
        setcountryIdperm(getCandidateData.data.data.country_permanent);

        setTimeout(() => {
          setAction("correspondence");
          props.requestState({
            id: getCandidateData.data.data.country,
          });

          setstateId(getCandidateData.data.data.state);

          setTimeout(() => {
            setAction("permanent");
            props.requestState({
              id: getCandidateData.data.data.country_permanent,
            });
            setstateIdperm(getCandidateData.data.data.state_permanent);
          }, 1000);
        }, 1000);

        setTimeout(() => {
          setAction("correspondence");

          props.requestCity({
            id: getCandidateData.data.data.state,
          });
          setcityId(getCandidateData.data.data.city);

          setTimeout(() => {
            setAction("permanent");

            props.requestCity({
              id: getCandidateData.data.data.state_permanent,
            });
            setcityIdperm(getCandidateData.data.data.city_permanent);
          }, 1000);
        }, 1000);
      }
    }
  }, [props.candidate.getCandidateData]);

  useEffect(() => {
    let formfieldData = props.employee.formfieldData;
    if (formfieldData !== undefined) {
      if (formfieldData?.data?.status === "success") {
        setindustry(formfieldData.data.data.industries);
        setLanguages(formfieldData.data.data.languages);
        setskill(formfieldData.data.data.skills);
        setcareer_level(formfieldData.data.data.career_levels);
        setfunctional_area(formfieldData.data.data.functional_areas);
        setcurrency(formfieldData.data.data.currencies);
      }
    }
  }, [props.employee.formfieldData]);

  function onChangeData(e) {

     if (e.target.type === "select-multiple") 
     {
      
    console.log(e.target.type)
    let value = Array.from(e.target.selectedOptions, option => option.value);    
    console.log("e===",value);    

    setData((data) => ({
      ...data,
      [e.target.name]: value.toString(),
    }));


     }
    else if (e.target.type === "radio") 
    {
      setData((data) => ({
        ...data,
        [e.target.name]: parseInt(e.target.value),
      }));
    } else {
      setData((data) => ({
        ...data,
        [e.target.name]: e.target.value,
      }));
    }
  }

  useEffect(() => {
    console.log("profile", data);
  }, [data]);

  function onChangeSkill(e) {
    selectedskill.push(e.target.value);
  }

  useEffect(() => {
    props.requestCountry();
  }, []);

  useEffect(() => {
    let countryData = props.candidate.countryData;
    if (countryData !== undefined) {
      if (countryData?.data?.status == "success") {
        setcountries(countryData.data.data.countries);
      }
    }
  }, [props.candidate.countryData]);



  useEffect(() => {
    let stateData = props.candidate.stateData;
    if (stateData !== undefined) {
      if (stateData?.data?.status == "success") {
        if (action == "permanent") setstatesp(stateData.data.data.states);
        else if (action == "correspondence") {
          setstates(stateData.data.data.states);
        }
      }
    }
  }, [props.candidate.stateData]);

 

  useEffect(() => {
    let cityData = props.candidate.cityData;
    if (cityData !== undefined) {
      if (cityData?.data?.status == "success") {
        if (action == "permanent") setcitiesp(cityData.data.data.cities);
        else if (action == "correspondence")
          setcities(cityData.data.data.cities);
      }
    }
  }, [props.candidate.cityData]);

  function onChangeCity(e) {
    setAction("correspondence");
    setcityId(e.target.value);
  }

  function validatefirst_name() {
    let formIsValid = false;
    if (!data["first_name"]) {
      formIsValid = false;
      seterrorfirst_name("*Enter your first name.");
    } else if (typeof data["first_name"] === "undefined") {
      formIsValid = false;
      seterrorfirst_name("*Enter your first name.");
    } else if (!data["first_name"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
      formIsValid = false;
      seterrorfirst_name("*Please enter Alphabet characters only.");
    } else {
      formIsValid = true;
      seterrorfirst_name("");
    }
    return formIsValid;
  }
  function validatelast_name() {
    let formIsValid = false;
    if (!data["last_name"]) {
      formIsValid = false;
      seterrorlast_name("*Enter your last name.");
    } else if (typeof data["last_name"] === "undefined") {
      formIsValid = false;
      seterrorlast_name("*Enter your last name.");
    } else if (!data["last_name"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
      formIsValid = false;
      seterrorlast_name("*Please enter Alphabet characters only.");
    } else {
      formIsValid = true;
      seterrorlast_name("");
    }
    return formIsValid;
  }
  function validategender() {
    let formIsValid = false;
    if (typeof data["gender"] === "undefined") {
      formIsValid = false;
      seterrorgender("*Select your gender.");
    } else {
      formIsValid = true;
      seterrorgender("");
    }
    return formIsValid;
  }
  function validatecountry() {
    let formIsValid = false;
    if (countryId === "0") {
      formIsValid = false;
      seterrorcountry("*Select your country.");
    } else if (typeof countryId === "undefined") {
      formIsValid = false;
      seterrorcountry("*Select your country.");
    } else {
      formIsValid = true;
      seterrorcountry("");
    }
    return formIsValid;
  }
  function validatestate() {
    let formIsValid = false;
    if (stateId === "0") {
      formIsValid = false;
      seterrorstate("*Select your state.");
    } else if (typeof stateId === "undefined") {
      formIsValid = false;
      seterrorstate("*Select your state.");
    } else {
      formIsValid = true;
      seterrorstate("");
    }
    return formIsValid;
  }
  function validatecity() {
    let formIsValid = false;
    if (cityId === "0") {
      formIsValid = false;
      seterrorcity("*Select your city.");
    } else if (typeof cityId === "undefined") {
      formIsValid = false;
      seterrorcity("*Select your city.");
    } else {
      formIsValid = true;
      seterrorcity("");
    }
    return formIsValid;
  }
  function validatebirth_date() {
    let formIsValid = false;
    var Today = new Date();
    if (typeof data["birth_date"] !== "undefined") {
      if (new Date(data["birth_date"]).getTime() >= Today.getTime()) {
        formIsValid = false;
        seterrorbirth_date("*Please select proper date.");
      } else {
        formIsValid = true;
        seterrorbirth_date("");
      }
    } else {
      formIsValid = false;
      seterrorbirth_date("*Please select birth date.");
    }
    return formIsValid;
  }
  function validatemarital_status() {
    let formIsValid = false;
    if (typeof data["marital_status"] === "undefined") {
      formIsValid = false;
      seterrormarital_status("*Select your marital status.");
    } else {
      formIsValid = true;
      seterrormarital_status("");
    }
    return formIsValid;
  }
  function validatelanguages() {
    let formIsValid = false;
    if (!data["languages"]) {
      formIsValid = false;
      seterrorlanguages("*Enter your known languages.");
    } else if (typeof data["languages"] === "undefined") {
      formIsValid = false;
      seterrorlanguages("*Enter your known languages.");
    } else {
      formIsValid = true;
      seterrorlanguages("");
    }
    return formIsValid;
  }
  function validatenationality() {
    let formIsValid = false;
    if (!data["nationality"]) {
      formIsValid = false;
      seterrornationality("*Enter your nationality.");
    } else if (typeof data["nationality"] === "undefined") {
      formIsValid = false;
      seterrornationality("*Enter your nationality.");
    } else if (!data["nationality"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
      formIsValid = false;
      seterrornationality("*Please enter Alphabet characters only.");
    } else {
      formIsValid = true;
      seterrornationality("");
    }
    return formIsValid;
  }
  function validatenational_id_card() {
    let formIsValid = false;
    if (!data["national_id_card"]) {
      formIsValid = false;
      seterrornational_id_card("*Enter your national ID card.");
    } else if (typeof data["national_id_card"] === "undefined") {
      formIsValid = false;
      seterrornational_id_card("*Enter your national ID card.");
    } else if (!data["national_id_card"].match(/^[0-9\s]*$/)) {
      formIsValid = false;
      seterrornational_id_card("*Please enter digits only.");
    } else {
      formIsValid = true;
      seterrornational_id_card("");
    }
    return formIsValid;
  }
  function validateexperience() {
    let formIsValid = false;
    if (typeof data["experience"] !== "undefined") {
      if (data["experience"] < 0) {
        formIsValid = false;
        seterrorexperience("*Please enter positive experience.");
      } else {
        formIsValid = true;
        seterrorexperience("");
      }
    } else {
      formIsValid = true;
      seterrorexperience("");
    }
    return formIsValid;
  }

  function validatecareer_level() {
    let formIsValid = false;
    if (data["career_level"] === "0") {
      formIsValid = false;
      seterrorcareer_level("*Select your career level.");
    } else if (typeof data["career_level"] === "undefined") {
      formIsValid = false;
      seterrorcareer_level("*Select your career level.");
    } else {
      formIsValid = true;
      seterrorcareer_level("");
    }
    return formIsValid;
  }
  function validateindustry() {
    let formIsValid = false;
    if (data["industry"] === "0") {
      formIsValid = false;
      seterrorindustry("*Select your industry.");
    } else if (typeof data["industry"] === "undefined") {
      formIsValid = false;
      seterrorindustry("*Select your industry.");
    } else {
      formIsValid = true;
      seterrorindustry("");
    }
    return formIsValid;
  }
  function validatefunctional_area() {
    let formIsValid = false;
    if (data["functional_area"] === "0") {
      formIsValid = false;
      seterrorfunctional_area("*Select your functional area.");
    } else if (typeof data["functional_area"] === "undefined") {
      formIsValid = false;
      seterrorfunctional_area("*Select your functional area.");
    } else {
      formIsValid = true;
      seterrorfunctional_area("");
    }
    return formIsValid;
  }
  function validatecurrent_salary() {
    let formIsValid = false;
    if (typeof data["current_salary"] !== "undefined") {
      if (data["current_salary"] < 0) {
        formIsValid = false;
        seterrorcurrent_salary("*Please enter positive current salary.");
      } else {
        formIsValid = true;
        seterrorcurrent_salary("");
      }
    } else {
      formIsValid = false;
      seterrorcurrent_salary("*Please enter current salary.");
    }
    return formIsValid;
  }
  function validateexpected_salary() {
    let formIsValid = false;
    if (typeof data["expected_salary"] !== "undefined") {
      if (data["expected_salary"] < 0) {
        formIsValid = false;
        seterrorexpected_salary("*Please enter positive expected salary.");
      } else {
        formIsValid = true;
        seterrorexpected_salary("");
      }
    } else {
      formIsValid = false;
      seterrorexpected_salary("*Please enter expected salary.");
    }
    return formIsValid;
  }
  function validatesalary_currency() {
    let formIsValid = false;
    if (data["salary_currency"] === "0") {
      formIsValid = false;
      seterrorsalary_currency("*Select your salary currency.");
    } else if (typeof data["salary_currency"] === "undefined") {
      formIsValid = false;
      seterrorsalary_currency("*Select your salary currency.");
    } else {
      formIsValid = true;
      seterrorsalary_currency("");
    }
    return formIsValid;
  }
  function validateimmediate_available() {
    let formIsValid = false;
    if (typeof data["immediate_available"] === "undefined") {
      formIsValid = false;
      seterrorimmediate_available("*Select your availablity.");
    } else {
      formIsValid = true;
      seterrorimmediate_available("");
    }
    return formIsValid;
  }
  function validateaddress() {
    let formIsValid = false;
    if (!data["address"]) {
      formIsValid = false;
      seterroraddress("*Enter your address.");
    } else if (typeof data["address"] === "undefined") {
      formIsValid = false;
      seterroraddress("*Enter your address.");
    } else {
      formIsValid = true;
      seterroraddress("");
    }
    return formIsValid;
  }
  function validatefacebook_url() {
    let formIsValid = false;
    if (data["facebook_url"]) {
      if (
        !data["facebook_url"].match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        )
      ) {
        formIsValid = false;
        seterrorfacebook_url("*Please enter valid facebook URL.");
      } else {
        formIsValid = true;
        seterrorfacebook_url("");
      }
    }
    return formIsValid;
  }
  function validatetwitter_url() {
    let formIsValid = false;
    if (data["twitter_url"]) {
      if (
        !data["twitter_url"].match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        )
      ) {
        formIsValid = false;
        seterrortwitter_url("*Please enter valid twitter URL.");
      } else {
        formIsValid = true;
        seterrortwitter_url("");
      }
    }
    return formIsValid;
  }
  function validatelinkedin_url() {
    let formIsValid = false;
    if (data["linkedin_url"]) {
      if (
        !data["linkedin_url"].match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        )
      ) {
        formIsValid = false;
        seterrorlinkedin_url("*Please enter valid linkedin URL.");
      } else {
        formIsValid = true;
        seterrorlinkedin_url("");
      }
    }
    return formIsValid;
  }
  function validategoogle_plus_url() {
    let formIsValid = false;
    if (data["google_plus_url"]) {
      if (
        !data["google_plus_url"].match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        )
      ) {
        formIsValid = false;
        seterrorgoogle_plus_url("*Please enter valid google plus URL.");
      } else {
        formIsValid = true;
        seterrorgoogle_plus_url("");
      }
    }
    return formIsValid;
  }
  function validatepinterest_url() {
    let formIsValid = false;
    if (data["pinterest_url"]) {
      if (
        !data["pinterest_url"].match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        )
      ) {
        formIsValid = false;
        seterrorpinterest_url("*Please enter valid pintrest URL.");
      } else {
        formIsValid = true;
        seterrorpinterest_url("");
      }
    }
    return formIsValid;
  }

  function validateForm() {
    let first_name = validatefirst_name();
    let last_name = validatelast_name();
    let gender = validategender();
    let country = validatecountry();
    let state = validatestate();
    let city = validatecity();
    let birth_date = validatebirth_date();
    let marital_status = validatemarital_status();
    let languages = validatelanguages();
    let nationality = validatenationality();
    let national_id_card = validatenational_id_card();
    let experience = validateexperience();
    let career_level = validatecareer_level();
    let industry = validateindustry();
    let functional_area = validatefunctional_area();
    let current_salary = validatecurrent_salary();
    let expected_salary = validateexpected_salary();
    let salary_currency = validatesalary_currency();
    let immediate_available = validateimmediate_available();
    let address = validateaddress();
    let facebook_url = validatefacebook_url();
    let twitter_url = validatetwitter_url();
    let linkedin_url = validatelinkedin_url();
    let google_plus_url = validategoogle_plus_url();
    let pinterest_url = validatepinterest_url();

    // console.log("first_name>>",first_name);
    // console.log("last_name>>",last_name);
    // console.log("gender>>",gender);
    // console.log("country>>",country);
    // console.log("state>>",state);
    // console.log("city>>",city);
    // console.log("birth_date>>",birth_date);
    // console.log("marital_status>>",marital_status);
    // console.log("languages>>",languages);
    // console.log("nationality>>",nationality);
    // console.log("national_id_card>>",national_id_card);
    // console.log("experience>>",experience);
    // console.log("career_level>>",career_level);
    // console.log("industry>>",industry);
    // console.log("functional_area>>",functional_area);
    // console.log("current_salary>>",current_salary);
    // console.log("expected_salary>>",expected_salary);
    // console.log("salary_currency>>",salary_currency);
    // console.log("immediate_available>>",immediate_available);
    // console.log("address>>",address);
    // console.log("facebook_url>>",facebook_url);
    // console.log("twitter_url>>",twitter_url);
    // console.log("linkedin_url>>",linkedin_url);
    // console.log("google_plus_url>>",google_plus_url);
    // console.log("pinterest_url>>",pinterest_url);


    let valid =
      first_name &&
      last_name &&
      gender &&
      country &&
      state &&
      city &&
      birth_date &&
      marital_status &&
      languages &&
      nationality &&
      national_id_card &&
      experience &&
      career_level &&
      industry &&
      functional_area &&
      current_salary &&
      expected_salary &&
      salary_currency &&
      immediate_available &&
      address &&
      facebook_url &&
      twitter_url &&
      linkedin_url
    return valid;
  }

  // function submitForm(e) {

  //   console.log("data===",data);
  //   e.preventDefault();
  //   if (validateForm()) {
  //     alert(data.first_name + "," + data.last_name)
  //     props.requestCandidateProfile({
  //       id: emp.id,
  //       token: emp.token,
  //       data: {
  //         first_name: data.first_name,
  //         last_name: data.last_name,
  //         gender: parseInt(data.gender),
  //         notice_period: data.notice_period.toString(),
  //         employment_type: data.employment_type.toString(),
  //         country: countryId,
  //         state: stateId,
  //         city: cityId,
  //         country_permanent: countryIdperm,
  //         state_permanent: stateIdperm,
  //         city_permanent: cityIdperm,
  //         pincode_permanent: data.pincode_permanent,
  //         pincode: data.pincode,
  //         address_permanent_one: data.address_permanent_one,
  //         address_permanent_two: data.address_permanent_two,
  //         address2: data.address2,
  //         birth_date: data.birth_date,
  //         marital_status: data.marital_status,
  //         skill: selectedskill,
  //         languages: data.languages,
  //         nationality: data.nationality,
  //         national_id_card: data.national_id_card,
  //         experience: data.experience,
  //         career_level: data.career_level,
  //         industry: data.industry,
  //         functional_area: data.functional_area,
  //         current_salary: data.current_salary,
  //         expected_salary: data.expected_salary,
  //         salary_currency: data.salary_currency,
  //         immediate_available: data.immediate_available,
  //         address: data.address,
  //         facebook_url: data.facebook_url,
  //         twitter_url: data.twitter_url,
  //         linkedin_url: data.linkedin_url,
  //         google_plus_url: data.google_plus_url,
  //         pinterest_url: data.pinterest_url,
  //       },
  //     });
  //   }
  // }


  const submitFormUploadImage = () => {
  
    const formData = new FormData();
    formData.append("profile", selectedFile);

    props.requestCandidateLogo({
      id: emp.id,
      data: formData,
      token: emp.token,
    });
  }
  // const onSelectFile = (e) => {
  //   setimg(URL.createObjectURL(e.target.files[0]));
  //   if (!e.target.files || e.target.files.length === 0) {
  //     setSelectedFile(undefined);
  //   } else {
  //     setSelectedFile(e.target.files[0]);
  //   }
  // };

  const onSelectFile = (e) => {
    const selectedFile = e.target.files[0];
  
    if (selectedFile) {
      const acceptedFormats = ["image/jpeg", "image/png"];
  
      if (acceptedFormats.includes(selectedFile.type)) {
        // The selected file is a PNG or JPG image, so proceed.
        setimg(URL.createObjectURL(selectedFile));
        setSelectedFile(selectedFile);
      } else {
        // The selected file is not a PNG or JPG image, so you can display an error message or take appropriate action.
        console.error("Invalid file format. Please select a PNG or JPG image.");
        setSelectedFile(undefined);
      }
    }
  };
  


  function submitForm(e) {

    console.log("data===",data);
   e.preventDefault();

  //  const formData = new FormData();
  //  formData.append("profile", selectedFile);

  //  props.requestCandidateLogo({
  //    id: emp.id,
  //    data: formData,
  //    token: emp.token,
  //  });

  // console.log(data)

   if (validateForm()) {
      // alert(data.first_name + "," + data.last_name)

     props.requestCandidateProfile({
       id: emp.id,
       token: emp.token,
       data: {
         first_name: data.first_name,
         last_name: data.last_name,
         gender: parseInt(data.gender),
         notice_period: data.notice_period.toString(),
         employment_type: data.employment_type.toString(),
         country: countryId,
         state: stateId,
         city: cityId,
         country_permanent: countryIdperm,
         state_permanent: stateIdperm,
         city_permanent: cityIdperm,
         pincode_permanent: data.pincode_permanent,
         pincode: data.pincode,
         address_permanent_one: data.address_permanent_one,
         address_permanent_two: data.address_permanent_two,
         address2: data.address2,
         birth_date: data.birth_date,
         marital_status: data.marital_status,
         skill: selectedskill,
         languages: data.languages,
         nationality: data.nationality,
         national_id_card: data.national_id_card,
         experience: data.experience,
         career_level: data.career_level,
         industry: data.industry,
         functional_area: data.functional_area,
         current_salary: data.current_salary,
         expected_salary: data.expected_salary,
         salary_currency: data.salary_currency,
         immediate_available: 1,
         address: data.address,
         facebook_url: data.facebook_url,
         twitter_url: data.twitter_url,
         linkedin_url: data.linkedin_url,
         google_plus_url: " ",
         pinterest_url: " ",
       },
     });

     submitFormUploadImage()
   }
 }


  useEffect(() => {
    let candidateprofile = props.candidate.candidateProfileData;
    if (candidateprofile !== undefined) {
      if (candidateprofile?.data?.status == "success") {
        // Swal.fire("Good job!", "Profile updated Successfully.", "success");
        props.candidate.candidateProfileData = undefined;
        props.requestGetCandidate({
          id: emp.id,
          token: emp.token,
        });
        navigate("/addResumeForm");
      } else {
        Swal.fire("Error!", `${candidateprofile?.data?.message}`, "error");
        props.candidate.candidateProfileData = undefined;
        props.requestGetCandidate({
          id: emp.id,
          token: emp.token,
        });
      }
    }
  }, [props.candidate.candidateProfileData]);



  useEffect(() => {
    let getCandidateData = props.candidate.getCandidateData;
    if (getCandidateData !== undefined) {
      if (getCandidateData?.data?.status == "success") {
        setData(getCandidateData.data.data);
        if (getCandidateData.data.data.profile) {
          setimg(
            process.env.REACT_APP_API_HOST + getCandidateData.data.data.profile
          );
        } else {
       
        }
      }
    }
  }, [props.candidate.getCandidateData]);


  //My updated code for Validation here

  const [countryIdperm, setcountryIdperm] = useState(0);
  const [stateIdperm, setstateIdperm] = useState(0);
  const [cityIdperm, setcityIdperm] = useState(0);

  const [erroraddresspermone, setErroraddresspermone] = useState("");

  function validatePermaddressOne() {
    let formIsValid = false;
    if (!data["address_permanent_one"]) {
      formIsValid = false;
      setErroraddresspermone("*Enter your address.");
    } else if (typeof data["address_permanent_one"] === "undefined") {
      formIsValid = false;
      setErroraddresspermone("*Enter your address.");
    } else {
      formIsValid = true;
      setErroraddresspermone("");
    }
    return formIsValid;
  }

  const [erroraddresspermtwo, setErroraddresspermtwo] = useState("");

  function validatePermaddressTwo() {
    let formIsValid = false;
    if (!data["address_permanent_two"]) {
      formIsValid = false;
      setErroraddresspermtwo("*Enter your address.");
    } else if (typeof data["address_permanent_two"] === "undefined") {
      formIsValid = false;
      setErroraddresspermtwo("*Enter your address.");
    } else {
      formIsValid = true;
      setErroraddresspermtwo("");
    }
    return formIsValid;
  }

  const [errorcountryperm, setErrorcountryperm] = useState("");

  function validatecountryPerma() {
    let formIsValid = false;
    if (countryIdperm === "0") {
      formIsValid = false;
      setErrorcountryperm("*Select your country.");
    } else if (typeof countryIdperm === "undefined") {
      formIsValid = false;
      setErrorcountryperm("*Select your country.");
    } else {
      formIsValid = true;
      setErrorcountryperm("");
    }

    return formIsValid;
  }

  const [errorstateperm, setErrorstateperm] = useState("");

  function validatestatePerma() {
    let formIsValid = false;
    if (stateIdperm === "0") {
      formIsValid = false;
      setErrorstateperm("*Enter your state");
    } else if (typeof cityIdperm === "undefined") {
      formIsValid = false;
      setErrorstateperm("*Enter your state.");
    } else {
      formIsValid = true;
      setErrorstateperm("");
    }
    return formIsValid;
  }

  const [errorcityperm, setErrorcityperm] = useState("");

  function validatecityPerma() {
    let formIsValid = false;
    if (cityIdperm === "0") {
      formIsValid = false;
      setErrorcityperm("*Enter your city");
    } else if (typeof cityIdperm === "undefined") {
      formIsValid = false;
      setErrorcityperm("*Enter your city");
    } else {
      formIsValid = true;
      setErrorcityperm("");
    }
    return formIsValid;
  }

  const [errorpincodeperm, setErrorpincodeperm] = useState("");

  function validatepincodePerma() {
    let formIsValid = false;
    if (!data["pincode_permanent"]) {
      formIsValid = false;
      setErrorpincodeperm("*Enter your pincode");
    } else if (typeof data["pincode_permanent"] === "undefined") {
      formIsValid = false;
      setErrorpincodeperm("*Enter your pincode");
    } else {
      formIsValid = true;
      setErrorpincodeperm("");
    }
    return formIsValid;
  }

  const [errorpincode, setErrorpincode] = useState("");

  function validatepincode() {
    let formIsValid = false;
    if (!data["pincode"]) {
      formIsValid = false;
      setErrorpincode("*Enter your pincode");
    } else if (typeof data["pincode"] === "undefined") {
      formIsValid = false;
      setErrorpincode("*Enter your pincode");
    } else {
      formIsValid = true;
      setErrorpincode("");
    }
    return formIsValid;
  }

  function onChangeCountryPerm(e) {
    setAction("permanent");
    console.log("e.target.value", e.target.value);
    setcountryIdperm(e.target.value);
    console.log("onchange country change value ", countryIdperm );
    props.requestState({
      id: e.target.value,
    });
  }

  
  function onChangeCountry(e) {
    setAction("correspondence");
    setcountryId(e.target.value);
    console.log("onchange country correspondence " , countryId);
    props.requestState({
      id: e.target.value,
    });
  }

  // correspondence address state change value 
  function onChangeState(e) {
    setAction("correspondence");
    setstateId(e.target.value);
    console.log("onchange correspondence address" , stateId);
    props.requestCity({
      id: e.target.value,
    });
  }
  // correspondence address state change value 


// permanent address state change value 
  function onChangeStatePerm(e) {
    setAction("permanent");
    setstateIdperm(e.target.value);
    console.log("onchange permanent state" , stateIdperm);
    props.requestCity({
      id: e.target.value,
    });
  }
  // permanent address state change value 

  function onChangeCityPerm(e) {
    setAction("permanent");
    setcityIdperm(e.target.value);
  }


  //on Checked Same as permanent Address 
  const handleSameAs = (e) => {
    var checked = e.target.checked;
    if (checked) {
      setData({
        ...data,
        address: data["address_permanent_one"],
        address2: data["address_permanent_two"],
        pincode: data["pincode_permanent"],
      });

      setcountryId(countryIdperm);
      console.log(countryIdperm, "onCheck ===Country==== get value of permanent address");
      setstateId(stateIdperm);
      console.log(stateIdperm, "onCheck ==State== get value of permanent address");
      setcityId(cityIdperm);

    } else {

      setData({
        ...data,
        address: [],
        address2: [],
        pincode: [],
      });

      setcountryId(0);
      setstateId(0);
      setcityId(0);
    }
  };
   //on Checked Same as permanent Address 
  



  return (
    <>
      <Header />
      <Breadcrumbs title="Profile" />
      <div class="resume section common-spacing">
        <div class="container">

        <MainTopComponent 
      username = {data.first_name}
      lastname = {data.last_name}
      userimage = {data.profile}
      useraddress = {data.address}
      useraddress2 = {data.address2}
      phone = {data.phone}
      email = {data.email}
      experience = {data.experience}
      notice_period = {data.notice_period}
      pincode = {data.pincode}
      city = {data.city_name}
      state = {data.state_name}
      country = {data.country_name}
      jobtitle = {data.designation}
      current_salary = {data.current_salary}
     
      />

          <div class="resume-inner">
            <div class="row">
              <ManageAccount name="profile" from="submenu" />

              <div class="col-lg-8 col-12">
                <div class="inner-content">
                  <div class="job-post ">
                    <div class="row">
                      <div
                        class="job-information"
                        style={{ border: "0px", padding: "0px 10px" }}
                      >
                        <form class="forms-sample" onSubmit={submitForm}>
                          <div class="row">
                            <h3 class="title">Create Resume</h3>


                          <div className="col-12">
                         

                          <div class="form-group">
                               
                                <div>
                                    <div className="empPicture"><img src={img}/>
                                    <div className="cameraicon">
                                    {
                                      img ? (
                                        <label for="file-input" className="mb-0"><AiTwotoneEdit/></label>
                                      ):  <label for="file-input" className="mb-0"><BsCameraFill/></label>
                                        
                                    }
                              
                                     </div>
                                    </div>

                                    <div>
                                    <input
                                      id="file-input"
                                      type="file"
                                      accept="image/png, image/jpeg, image/jpg, image/avif, image/webp"
                                      style={{ display: "none" }}
                                      name="profile"
                                      onChange={onSelectFile}
                                    />
                                 </div>

                                 </div>

                                </div>

                          </div>

                     





                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>First Name*</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="first_name"
                                  value={data.first_name}
                                  onBlur={validatefirst_name}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {errorfirst_name && (
                                  <div style={mystyle}>{errorfirst_name}</div>
                                )}
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Last Name*</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="last_name"
                                  value={data.last_name}
                                  onBlur={validatelast_name}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {errorlast_name && (
                                  <div style={mystyle}>{errorlast_name}</div>
                                )}
                              </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label for="gender" class="label">
                              Gender
                            </label>
                            <select
                              class="select"
                              name="gender"
                              value={data.gender}
                              onBlur={validategender}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Gender</option>
                              <option value="1">Male</option>
                              <option value="2">Female</option>
                              <option value="3">Any</option>
                            </select>
                          </div>
                          {errorgender && (
                                  <div style={mystyle}>{errorgender}</div>
                                )}
                        </div>

                        <div class="col-lg-6 col-md-6">
                          <div class="form-group">
                            <label>Notice Period*</label>
                            <select
                              class="select"
                              name="notice_period"
                               value={data.notice_period}
                              // onBlur={validatePeriod}
                              onChange={onChangeData}
                            >
                              <option value="0">Select Notice Period</option>
                              <option value="1">Immediate</option>
                              <option value="2">1 Month</option>
                              <option value="3">1.5 Months</option>
                              <option value="4">2 Months</option>
                              <option value="5">2.5 Months</option>
                              <option value="6">3 Months</option>
                              <option value="7">3.5 Months</option>
                              <option value="8">4 Months</option>
                              <option value="9">4.5 Months</option>
                              <option value="10">5 Months</option>
                              <option value="11">5.5 Months</option>
                              <option value="12">6 Months</option>
                            </select>
                          </div>
                        </div>
                          
                           
                            <div class="col-lg-4 col-md-4" style={{display:"none"}}>
                              <div style={{ color: "black" }}>
                                <label for="gender" class="label">
                                  Immediate available
                                </label>

                                <br />
                                <div class="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    style={{ margin: "0px" }}
                                    id="yes"
                                    name="immediate_available"
                                    value="1"
                                    onBlur={validateimmediate_available}
                                    checked={data.immediate_available === 1}
                                    onChange={onChangeData}
                                  />
                                  <label class="form-check-label">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    style={{ margin: "0px" }}
                                    id="no"
                                    name="immediate_available"
                                    value="0"
                                    onBlur={validateimmediate_available}
                                    checked={data.immediate_available === 0}
                                    onChange={onChangeData}
                                  />
                                  <label class="form-check-label">No</label>
                                </div>
                                {errorimmediate_available && (
                                  <div style={mystyle}>
                                    {errorimmediate_available}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                          <label for="gender" class="label">
                            Employment Type
                          </label>
                          <div
                            style={{ color: "black" }}
                            class="employment-type"
                          >
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                style={{ margin: "0px" }}
                                // id="genderMale"
                                name="employment_type"
                                value="1"
                                // onBlur={validateGender}
                                onChange={onChangeData}
                                checked={parseInt(data.employment_type) === 1}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio1"
                              >
                                Work from home
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                style={{ margin: "0px" }}
                                // id="genderFemale"
                                name="employment_type"
                                 value="2"
                                // onBlur={validateGender}
                                onChange={onChangeData}
                                checked={parseInt(data.employment_type) === 2}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio2"
                              >
                                Permanent
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                style={{ margin: "0px" }}
                                // id="genderAny"
                                name="employment_type"
                                value="3"
                                // onBlur={validateGender}
                                onChange={onChangeData}
                                checked={parseInt(data.employment_type) === 3}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio3"
                              >
                                Contractual
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                style={{ margin: "0px" }}
                                // id="genderAny"
                                name="employment_type"
                                value="4"
                                // onBlur={validateGender}
                                onChange={onChangeData}
                                checked={parseInt(data.employment_type) === 4}
                              />
                              <label
                                class="form-check-label"
                                for="inlineRadio3"
                              >
                                Internship
                              </label>
                            </div>
                            {/* {errorGender && (
                              <div style={mystyle}>{errorGender}</div>
                            )} */}
                          </div>
                        </div>


                  

                      

                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>
                                  <br />
                                  Date of Birth
                                </label>
                                <input
                                  class="form-control"
                                  type="date"
                                  name="birth_date"
                                  value={data.birth_date}
                                  onBlur={validatebirth_date}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {errorbirth_date && (
                                  <div style={mystyle}>{errorbirth_date}</div>
                                )}
                              </div>
                            </div>
                            <h3 class="title">Detail Information</h3>
                            <div style={{'display':'none'}} class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Skill*</label>
                                <select
                                  class="select"
                                  name="skill"
                                  // value={selectedskill}
                                  onChange={onChangeSkill}
                                  multiple
                                >
                                  {skill.map((option) => (
                                    <option value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Languages</label>
                                <select
                                  multiple
                                  class="select-languages"
                                  name="languages"
                                  value={data.languages}
                                  // onBlur={validatecareer_level}
                                   onChange={onChangeData}
                                >
                                  <option value="0">Select Language</option>
                                  {languages.map((option) => (
                                    <option  value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                                {errorcareer_level && (
                                  <div style={mystyle}>{errorcareer_level}</div>
                                )}
                              </div>
                            </div>



                            <div class="col-lg-6 col-md-6" style={{'display':'none'}}>
                              <div class="form-group">
                                <label>Languages</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="languages22"
                                  // value={data.languages}
                                  // onBlur={validatelanguages}
                                  // onChange={onChangeData}
                                  // placeholder=""
                                />
                                {/* {errorlanguages && (
                                  <div style={mystyle}>{errorlanguages}</div>
                                )} */}
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Nationality</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="nationality"
                                  value={data.nationality}
                                  onBlur={validatenationality}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {errornationality && (
                                  <div style={mystyle}>{errornationality}</div>
                                )}
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Aadhar card number</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="national_id_card"
                                  value={data.national_id_card}
                                  onBlur={validatenational_id_card}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {errornational_id_card && (
                                  <div style={mystyle}>
                                    {errornational_id_card}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Total Experience in Years</label>
                                <input
                                  class="form-control"
                                  type="number"
                                  name="experience"
                                  value={data.experience}
                                  onBlur={validateexperience}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {errorexperience && (
                                  <div style={mystyle}>{errorexperience}</div>
                                )}
                              </div>
                            </div>
                            <h3 class="title">Industry Information</h3>
                          
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Career Level</label>
                                <select
                                  class="select"
                                  name="career_level"
                                  value={data.career_level}
                                  onBlur={validatecareer_level}
                                  onChange={onChangeData}
                                >
                                  <option value="0">Select Industry</option>
                                  {career_level.map((option) => (
                                    <option value={option.id}>
                                      {option.level}
                                    </option>
                                  ))}
                                </select>
                                {errorcareer_level && (
                                  <div style={mystyle}>{errorcareer_level}</div>
                                )}
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Industry</label>
                                <select
                                  class="select"
                                  name="industry"
                                  value={data.industry}
                                  onBlur={validateindustry}
                                  onChange={onChangeData}
                                >
                                  <option value="0">Select Industry</option>
                                  {industry.map((option) => (
                                    <option value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                                {errorindustry && (
                                  <div style={mystyle}>{errorindustry}</div>
                                )}
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Functional Area</label>
                                <select
                                  class="select"
                                  name="functional_area"
                                  value={data.functional_area}
                                  onBlur={validatefunctional_area}
                                  onChange={onChangeData}
                                >
                                  <option value="0">
                                    Select Ownership Type
                                  </option>
                                  {functional_area.map((option) => (
                                    <option value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                                {errorfunctional_area && (
                                  <div style={mystyle}>
                                    {errorfunctional_area}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Current CTC</label>
                                <input
                                  class="form-control"
                                  type="number"
                                  name="current_salary"
                                  value={data.current_salary}
                                  onBlur={validatecurrent_salary}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {errorcurrent_salary && (
                                  <div style={mystyle}>
                                    {errorcurrent_salary}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                              <div class="form-group">
                                <label>Expected Salary</label>
                                <input
                                  class="form-control"
                                  type="number"
                                  name="expected_salary"
                                  value={data.expected_salary}
                                  onBlur={validateexpected_salary}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {errorexpected_salary && (
                                  <div style={mystyle}>
                                    {errorexpected_salary}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4">
                              <div class="form-group">
                                <label>Salary Currency</label>
                                <select
                                  class="select"
                                  name="salary_currency"
                                  value={data.salary_currency}
                                  onBlur={validatesalary_currency}
                                  onChange={onChangeData}
                                >
                                  <option value="0">Select Industry</option>
                                  {currency.map((option) => (
                                    <option value={option.id}>
                                      {option.currency_name}
                                    </option>
                                  ))}
                                </select>
                                {errorsalary_currency && (
                                  <div style={mystyle}>
                                    {errorsalary_currency}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div class="col-lg-4 col-md-4">
                              <div style={{ color: "black" }}>
                                <label for="marital_status" class="label">
                                  Marital Status
                                </label>
                                <div className="d-flex">
                                <div class="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    style={{ margin: "0px" }}
                                    id="married"
                                    name="marital_status"
                                    value="1"
                                    onBlur={validatemarital_status}
                                    checked={data.marital_status === 1}
                                    onChange={onChangeData}
                                  />
                                  <label class="form-check-label">
                                    Married
                                  </label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    style={{ margin: "0px" }}
                                    id="unmarried"
                                    name="marital_status"
                                    value="0"
                                    onBlur={validatemarital_status}
                                    checked={data.marital_status === 0}
                                    onChange={onChangeData}
                                  />
                                  <label class="form-check-label">
                                    Unmarried
                                  </label>
                                </div>
                                </div>

                                {errormarital_status && (
                                  <div style={mystyle}>
                                    {errormarital_status}
                                  </div>
                                )}
                              </div>

                            </div>

                          {/* Start Permanent Address */}
                            <h3 class="title">Permanent Address</h3>

                            <div class="col-lg-12 col-md-12">
                              <div class="form-group">
                                <label>Address Line One</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="address_permanent_one"
                                  value={data.address_permanent_one}
                                  onBlur={validatePermaddressOne}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {erroraddresspermone && (
                                  <div style={mystyle}>
                                    {erroraddresspermone}
                                  </div>
                                )}
                              </div>
                            </div>
                          
                            <div class="col-lg-12 col-md-12">
                              <div class="form-group">
                                <label>Address Line Two</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="address_permanent_two"
                                  value={data.address_permanent_two}
                                  onBlur={validatePermaddressTwo}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {erroraddresspermtwo && (
                                  <div style={mystyle}>
                                    {erroraddresspermtwo}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Country</label>
                                <select
                                  class="select"
                                  name="country_permanent"
                                  value={countryIdperm}
                                  onBlur={validatecountryPerma}
                                  onChange={onChangeCountryPerm}
                                >
                                  <option value="0">Select Country</option>
                                  {countries.map((option) => (
                                    <option value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                                {errorcountryperm && (
                                  <div style={mystyle}>{errorcountryperm}</div>
                                )}
                              </div>
                            </div>
                      {/* Permanent State   */}
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>State</label>
                                <select
                                  class="select"
                                  name="state_permanent"
                                  value={stateIdperm}
                                  onBlur={validatestatePerma}
                                  onChange={onChangeStatePerm}
                                >
                                  <option value="0">Select State</option>
                                  {statesp.map((option) => (
                                    <option value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                                {errorstateperm && (
                                  <div style={mystyle}>{errorstateperm}</div>
                                )}
                              </div>
                            </div>
                              {/* End  Permanent State   */}
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>City</label>
                                <select
                                  class="select"
                                  name="city_permanent"
                                  value={cityIdperm}
                                  onBlur={validatecityPerma}
                                  onChange={onChangeCityPerm}
                                >
                                  <option value="0">Select City</option>
                                  {citiesp.map((option) => (
                                    <option value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                                {errorcityperm && (
                                  <div style={mystyle}>{errorcityperm}</div>
                                )}
                              </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Pincode</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="pincode_permanent"
                                  value={data.pincode_permanent}
                                  onBlur={validatepincodePerma}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {errorpincodeperm && (
                                  <div style={mystyle}>{errorpincodeperm}</div>
                                )}
                              </div>
                            </div>
                              {/* End Permanent Address */}

                            <div class="sameas">
                              <h3 class="title">
                              Correspondence Address
                              </h3>
                              <div class="address">
                                <input type="checkbox" onClick={handleSameAs} />
                                Same as permanent Address
                              </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                              <div class="form-group">
                                <label>Address Line One</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="address"
                                  value={data.address}
                                  onBlur={validateaddress}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {erroraddress && (
                                  <div style={mystyle}>{erroraddress}</div>
                                )}
                              </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                              <div class="form-group">
                                <label>Address Line Two</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="address2"
                                  value={data.address2}
                                  onBlur={validateaddress}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {erroraddress && (
                                  <div style={mystyle}>{erroraddress}</div>
                                )}
                              </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Country</label>
                                <select
                                  class="select"
                                  name="country"
                                  value={countryId}
                                  onBlur={validatecountry}
                                  onChange={onChangeCountry}
                                >
                                  <option value="0">Select Country</option>
                                  {countries.map((option) => (
                                    <option value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                                {errorcountry && (
                                  <div style={mystyle}>{errorcountry}</div>
                                )}
                              </div>
                            </div>
                            {/*State Correspondence Address */}
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>State</label>
                                <select
                                  class="select"
                                  name="state"
                                  value={stateId}
                                  onBlur={validatestate}
                                  onChange={onChangeState}
                                >
                                  <option value="0">Select State</option>
                                  {states.map((option) => (
                                    <option value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                                {errorstate && (
                                  <div style={mystyle}>{errorstate}</div>
                                )}
                              </div>
                            </div>
                              {/* End  State Correspondence Address*/}
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>City</label>
                                <select
                                  class="select"
                                  name="city"
                                  value={cityId}
                                  onBlur={validatecity}
                                  onChange={onChangeCity}
                                >
                                  <option value="0">Select City</option>
                                  {cities.map((option) => (
                                    <option value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                                </select>
                                {errorcity && (
                                  <div style={mystyle}>{errorcity}</div>
                                )}
                              </div>
                            </div>
                          
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Pincode</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="pincode"
                                  value={data.pincode}
                                  onBlur={validatepincode}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {errorpincode && (
                                  <div style={mystyle}>{errorpincode}</div>
                                )}
                              </div>
                            </div>

                            <h3 class="title">Social Links</h3>
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Linkedin URL</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="linkedin_url"
                                  value={data.linkedin_url}
                                  onBlur={validatelinkedin_url}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {errorlinkedin_url && (
                                  <div style={mystyle}>{errorlinkedin_url}</div>
                                )}
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Facebook URL</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="facebook_url"
                                  value={data.facebook_url}
                                  onBlur={validatefacebook_url}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {errorfacebook_url && (
                                  <div style={mystyle}>{errorfacebook_url}</div>
                                )}
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <div class="form-group">
                                <label>Twitter URL</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="twitter_url"
                                  value={data.twitter_url}
                                  onBlur={validatetwitter_url}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {errortwitter_url && (
                                  <div style={mystyle}>{errortwitter_url}</div>
                                )}
                              </div>
                            </div>
                            
                            <div
                              class="col-lg-6 col-md-6"
                              style={{ display: "none" }}
                            >
                              <div class="form-group">
                                <label>Google Plus URL</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="google_plus_url"
                                  value={data.google_plus_url}
                                  onBlur={validategoogle_plus_url}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {errorgoogle_plus_url && (
                                  <div style={mystyle}>
                                    {errorgoogle_plus_url}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              class="col-lg-6 col-md-6"
                              style={{ display: "none" }}
                            >
                              <div class="form-group">
                                <label>Pintrest URL</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  name="pinterest_url"
                                  value={data.pinterest_url}
                                  onBlur={validatepinterest_url}
                                  onChange={onChangeData}
                                  placeholder=""
                                />
                                {errorpinterest_url && (
                                  <div style={mystyle}>
                                    {errorpinterest_url}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div class="button">
                          <button type="submit" class="btn">Save & Next
                          </button></div>


                          {/* <button
                            type="submit"
                            class="btn btn-primary me-2"
                            style={{
                              color: "white",
                              width: "150px",
                              height: "50px",
                            }}
                          >
                            Save
                          </button> */}



                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return { employee: state.employee, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestLogin,
      requestFormField,
      requestCandidateProfile,
      requestCandidateLogo,
      requestCountry,
      requestState,
      requestCity,
      requestGetCandidate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

import React from 'react'
import { useEffect } from 'react';

import WOW from "wowjs";

const Testimonials = () => {

    useEffect(() => {
        new WOW.WOW().init();
      }, []);



  return (
    <>
    

<section className='common-spacing '>
<div className="container-fluid">

<div class="section-title">
  <h2 class="" >
    Our <span class="headingHighLight"> Testimonials</span> </h2>
    </div>

     <div className="row">
        <div class="col-lg-8 col-12 testimonials two">
                
                  <div class=" testimonial-inner-head"
                   >
                    <div class=" testimonial-inner">
                      <div class="testimonial-slider">
                        <div
                          id="carouselExampleControls"
                          class="carousel slide"
                          data-ride="carousel"
                        >
                          <div class="carousel-inner">
                            <div class="carousel-item active">
                              <div class="single-testimonial">
                                <div class="quote">
                                  <i class="lni lni-quotation"></i>
                                </div>
                                <p>
                                  " Your application system is fantastic, job hunting can be very frustrating and you are by far the most friendly and professional recruitment business I have worked with."{" "}
                                </p>
                                <div class="bottom">
                                  <div class="clien-image">
                                    <img
                                      src= {process.env.PUBLIC_URL + "/assets/images/testimonial/testi1.jpg" } 
                                      alt="#"
                                    />
                                  </div>
                                  <h4 class="name">
                                  Krishna{" "}
                                    {/* <span>CEO - Graygrids</span> */}
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div class="carousel-item">
                              <div class="single-testimonial">
                                <div class="quote">
                                  <i class="lni lni-quotation"></i>
                                </div>
                                <p>
                                  "I found your application system really easy-to-use, while still allowing me to include all the information I needed to."{" "}
                                </p>
                                <div class="bottom">
                                  <div class="clien-image">
                                    <img
                                      src= {process.env.PUBLIC_URL +  "/assets/images/testimonial/testi2.jpg"} 
                                      alt="#"
                                    />
                                  </div>
                                  <h4 class="name">
                                  Piyush{" "}
                                    {/* <span>CEO - Wpthemes Grid</span> */}
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div class="carousel-item">
                              <div class="single-testimonial">
                                <div class="quote">
                                  <i class="lni lni-quotation"></i>
                                </div>
                                <p>
                                  "Having communications at all levels of the application process was a very much welcome surprise compared to other companies I have been through, two thumbs up from me!"{" "}
                                </p>
                                <div class="bottom">
                                  <div class="clien-image">
                                    <img
                                      src= {process.env.PUBLIC_URL + "/assets/images/testimonial/testi3.jpg"} 
                                      alt="#"
                                    />
                                  </div>
                                  <h4 class="name">
                                  Vaishnavi
                                    {/* <span>CEO - Tredex</span> */}
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div class="carousel-item">
                              <div class="single-testimonial">
                                <div class="quote">
                                  <i class="lni lni-quotation"></i>
                                </div>
                                <p>
                                  " Seriously impressed with the level of support the team at Job Portal were able to provide myself when I was having technical issues applying for roles, very patient and friendly when it came to solving my problem."{" "}
                                </p>
                                <div class="bottom">
                                  <div class="clien-image">
                                    <img
                                      src= {process.env.PUBLIC_URL + "/assets/images/testimonial/testi4.jpg"} 
                                      alt="#"
                                    />
                                  </div>
                                  <h4 class="name">
                                  Aditya
                                    {/* <span>CEO - Dream App</span> */}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                          <a
                            class="carousel-control-prev"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="prev"
                           
                          >
                            <span
                              class="fa fa-long-arrow-left"
                              aria-hidden="true"
                             
                            ></span>
                            <span class="sr-only">Previous</span>
                          </a>
                          <a
                            class="carousel-control-next"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="next"
                            
                          >
                            <span
                              class="fa fa-long-arrow-right"
                              aria-hidden="true"
                             
                            ></span>
                            <span class="sr-only">Next</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-4 col-12 testimonidal-man'>
                  <div className="bg-mantestimonial">
                  <img src= {process.env.PUBLIC_URL + "/assets/images/testimonial/back-mask3.png" }  alt="" />   
                  </div>
                 <div>
                 <img src= {process.env.PUBLIC_URL + "/assets/images/testimonial/man1.png" }  alt="" />
                 </div>
                </div>
  </div>


</div>
</section>


    </>
  )
}

export default Testimonials
import Footer from "../Components/footer";
import Header from "../Components/header";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  requestEmpLogin,
  requestGetInterview,
  requestInterviewApprove,
  requestInterviewReject,
} from "../Redux/actions";
import WOW from "wowjs";
import ManageAccount from "./manageAccount";
import profile1 from "../images/profile.png";
import Swal from "sweetalert2";
import Breadcrumbs from "../Section/breadcrumbsSection";
import Chart from 'chart.js/auto';

// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";

// Chart.register(
//   CategoryScale,
//   ChartDataLabels,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

function JobseekerInterviewReports(props) {
  useEffect(() => {
    new WOW.WOW().init();
    window.scrollTo(0, 400);
    localStorage.removeItem("link");
  }, []);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [emp, setEmp] = useState({});

  useEffect(() => {
    let empLoginData = props.employee.empLoginData;
    if (empLoginData !== undefined) {
      if (empLoginData?.data?.status == "success") {
        setEmp(empLoginData.data.data);
        props.requestGetInterview({
          token: empLoginData.data.data.token,
        });
      } else {
        localStorage.setItem("link", "/interviews");
        navigate("/emplogin");
      }
    } else {
      localStorage.setItem("link", "/interviews");
      navigate("/emplogin");
    }
  }, [props.employee.empLoginData]);

  useEffect(() => {
    let getInterviewData = props.employee.getInterviewData;
    if (getInterviewData !== undefined) {
      if (getInterviewData?.data?.status === "success") {
        setData(getInterviewData.data.data.interview);
      }
    }
  }, [props.employee.getInterviewData]);

  function approveCandidate(applied_id, interview_id) {
    props.requestInterviewApprove({
      token: emp.token,
      data: {
        interview_id: interview_id,
        applied_id: applied_id,
      },
    });
  }
  useEffect(() => {
    let interviewApproveData = props.employee.interviewApproveData;
    if (interviewApproveData !== undefined) {
      if (interviewApproveData.data.status == "success") {
        Swal.fire("Good job!", "Candidate Approved successfully.", "success");
        props.employee.interviewApproveData = undefined;
        props.requestGetInterview({
          token: emp.token,
        });
      } else {
        Swal.fire("Error!", `${interviewApproveData.data.message}`, "error");
        props.employee.interviewApproveData = undefined;
      }
    }
  }, [props.employee.interviewApproveData]);

  function rejectCandidate(applied_id, interview_id) {
    props.requestInterviewReject({
      token: emp.token,
      data: {
        interview_id: interview_id,
        applied_id: applied_id,
      },
    });
  }
  useEffect(() => {
    let interviewRejectData = props.employee.interviewRejectData;
    if (interviewRejectData !== undefined) {
      if (interviewRejectData.data.status == "success") {
        Swal.fire("Good job!", "Candidate Rejected successfully.", "success");
        props.employee.interviewRejectData = undefined;
        props.requestGetInterview({
          token: emp.token,
        });
      } else {
        Swal.fire("Error!", `${interviewRejectData.data.message}`, "error");
        props.employee.interviewRejectData = undefined;
      }
    }
  }, [props.employee.interviewRejectData]);

  const datachart = {
    labels: ["Experience", "Portfolio & Skills", "HR Screening Score","HR Interview Score","Technical Interview Score","Second Interview Score","Final Interview Score"],
   
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: "3",
        data: [55, 42, 40,38,30,29,20],
        // you can set indiviual colors for each bar
        backgroundColor: [
          '#0081C9',
          '#5BC0F8',
          '#86E5FF',
           '#8DCBE6',
           '#9DF1DF',
           '#E3F6FF',
           '#FFF2F2'          
        ],
        borderWidth: 0,
      },
    ],
  };


  
 const options = {
  indexAxis: 'y',
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      grid: {
        display: false
      }
    }
  },
  elements: {
    bar: {
      borderWidth: 3,
    },
  },
  responsive: true,
  plugins: {
    datalabels: {
      display: true,
      color: "black",
      formatter: Math.round,
      anchor: "end",
      offset: -20,
      align: "start"
    },
    legend: {
      display:false,
       position: 'right' ,
    },
    title: {
      display: true,
      text: 'Job-seeker Interview report ',
    },
  },
};


  return (
    <>
      <Header />
      <Breadcrumbs title="Manage Interviews" />
      <div class="manage-jobs section common-spacing">
        <div class="container">
          <div class="alerts-inner">
            <div class="row">
              <ManageAccount name="jobseekerinterviewreports" from="submenu" />
              <div class="col-lg-8 col-12">
                <div class="job-items">
                <Bar options={options} data={datachart} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return { employee: state.employee };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestEmpLogin,
      requestGetInterview,
      requestInterviewApprove,
      requestInterviewReject,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobseekerInterviewReports);

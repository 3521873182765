import Footer from "../Components/footer";
import Header from "../Components/header";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RWebShare } from "react-web-share";
import "../Admin/style.css";
import { bindActionCreators } from "redux";
import { requestAdminCandidateDetails } from "../Redux/actions";

import WOW from "wowjs";
import ManageAccount from "./manageAccount";
import profile1 from "../images/profile.png";
import Swal from "sweetalert2";
import Breadcrumbs from "../Section/breadcrumbsSection";

import image from "../images/profile.png";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; 
import progresstype from "../Components/progressData";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";


function ShowCandidate(props) {
  const params = useParams();

  console.log("brj==", params);

  const [candidates, setcandidates] = useState([]);
  const [pagination, setpagination] = useState({});
  const navigate = useNavigate();
  const [pages, setpages] = useState([]);
  const [page_size, setpage_size] = useState(0);
  const [data, setdata] = useState({});
  const [img, setimg] = useState("");
  const [resume, setresume] = useState("");

  const[JobseekerProgress, setJobseekerProgress ] = useState(progresstype);


  useEffect(() => {
    console.log('progresstype >>> ', progresstype)
    props.requestAdminCandidateDetails({
      id: params.id,
    });
  }, []);

  useEffect(() => {
    let candidateDeatilsData = props.data.candidateDeatilsData;
    if (candidateDeatilsData !== undefined) {
      setdata(candidateDeatilsData?.data?.data[0]);
      if (candidateDeatilsData.data.data[0].profile) {
        setimg(
          process.env.REACT_APP_API_HOST +
            candidateDeatilsData.data.data[0].profile
        );
      } else {
        setimg(image);
      }

      setresume(
        process.env.REACT_APP_API_HOST +
          candidateDeatilsData.data.data[0].resume
      );
    }
  }, [props.data.candidateDeatilsData]);

  function printPage() {
    var printContents =
      "<br/><center><h3>Candidate Details</h3></center>" +
      document.getElementById("printme").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }



const chartDataObj = (itemObj={}) => {

  let rlabels = Object.keys(itemObj.childData)
  let rDAta = []
  rlabels.map(it=>{
    rDAta.push(itemObj.childData[it])
  })

    return {
      labels: rlabels,
      datasets: [
        {
          label: "3",
          data: rDAta,
         
          backgroundColor: [
            '#0081C9',
            '#5BC0F8',
            '#86E5FF',
             '#8DCBE6',
             '#9DF1DF',
             '#E3F6FF',
             '#FFF2F2'          
          ],
          borderWidth: 0,
        },
      ],
    };
  }


  



  const options = {
    indexAxis: 'y',
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    },
    elements: {
      bar: {
        borderWidth: 3,
      },
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        formatter: Math.round,
        anchor: "end",
        offset: -20,
        align: "start"
      },
      legend: {
        display:false,
         position: 'right' ,
      },
      title: {
        display: true,
        // text: 'Job Seeker (Pre-Employment Assessment)',
      },
    },
  };



 console.log("jobseeker detail >>>>>>>>>>>>", data)

  return (
    <>
      <Header />
      <Breadcrumbs title="Manage Interviews" />
      <div class="manage-jobs section">
        <div class="container">
          <div class="alerts-inner">
            <div class="row">
              <ManageAccount name="Candidates" />

              <div class="col-lg-8 col-12">

                <div className="EmployerInner-content">
                
                <div className="backbtn mb-3 button ">
                <button onClick={() => navigate(-1)} className="btn small secondary-btn">Go Back</button>
                </div>


                {/* <div class="d-sm-flex justify-content-between align-items-start">
                  <div class="btn-wrapper">
                    <RWebShare
                      data={{
                        text: "Job Portal",
                        url: `http://localhost:3000/admin/viewCandidate/${params.id}`,
                        title: "Job Portal",
                      }}
                    >
                      <p class="btn btn-otline-dark align-items-center">
                        <i class="icon-share"></i> Share
                      </p>
                    </RWebShare>
                    <button onClick={printPage} class="btn btn-otline-dark">
                      <i class="icon-printer"></i> Print
                    </button>
                  </div>
                  <div>
                    <a href={data.facebook_url} target="_blank">
                      <button
                        class="btn btn-primary btn-md text-white mb-0 me-0"
                        type="button"
                        style={{ padding: "6px 8px" }}
                      >
                        <i class="fa fa-facebook"></i>
                      </button>
                    </a>
                    <a href={data.twitter_url} target="_blank">
                      <button
                        class="btn btn-primary btn-md text-white mb-0 me-0"
                        type="button"
                        style={{
                          padding: "6px 5px",
                          marginLeft: "5px",
                        }}
                      >
                        <i class="fa fa-twitter"></i>
                      </button>
                    </a>
                    <a href={data.linkedin_url} target="_blank">
                      <button
                        class="btn btn-primary btn-md text-white mb-0 me-0"
                        type="button"
                        style={{
                          padding: "6px 6px",
                          marginLeft: "5px",
                        }}
                      >
                        <i class="fa fa-linkedin"></i>
                      </button>
                    </a>
                    <a href={data.google_plus_url} target="_blank">
                      <button
                        class="btn btn-primary btn-md text-white mb-0 me-0"
                        type="button"
                        style={{
                          padding: "6px 2px",
                          marginLeft: "5px",
                        }}
                      >
                        <i class="fa fa-google-plus"></i>
                      </button>
                    </a>
                    <a href={data.pinterest_url} target="_blank">
                      <button
                        class="btn btn-primary btn-md text-white mb-0 me-0"
                        type="button"
                        style={{
                          padding: "6px 7px",
                          marginLeft: "5px",
                        }}
                      >
                        <i class="fa fa-pinterest-p"></i>
                      </button>
                    </a>
                  </div>
                </div> */}

                <div className="d-flex justify-content-between JobSeekerDetail">
                  <div>
                  <div className="d-flex jobSeeker-imgName">
                    <div>
                    <img src={img} alt="" height="60" width="60" />
                    </div>
                    <div className="ps-2">
                      <h4>{data.first_name} {data.last_name}</h4>
                      <p>{data.designation} </p>
                      
                    </div>
                  </div>
                  </div>

                  <div>
                  
                  <p >
                    <b>Phone: </b>
                    <a href={`callto:${data.phone}`}>{data.phone}</a>
                  </p>
                  <p>
                    <b>Email: </b>
                    <a href={`mailto:${data.email}`}>{data.email}</a>
                  </p>
                  <p>
                    <b>Location: </b>
                     {data.address},{" "}
                        {data.city_name}, {data.state_name}, {data.country_name}
                      </p> 
          
                  </div>

    
                </div>


                  <hr />
                 

                  {/* {data.resume ? (
                    <iframe src={resume} width="1000" height="1100" />
                  ) : (
                    <p>Resume is not Uploaded.</p>
                  )} */}


    <div className="jobseekerScoreDetail">
   <div className=" ">
   {
      progresstype?.map((item)=> {

  return (
            <>           
<div className=" cardScore  py-0 px-2">

    <div>
    <div className='additional-progressbar' style={{ width: '100px', height: '100px', position: 'relative' , }}>  
    <h5 className='text-center userProgress mt-2' style={{ whiteSpace: "nowrap" }}>{item.name} </h5>     
        <CircularProgressbar  className="multiple-cricularbar thick-stroke"
          value= {item.percentage}
          text={`${item.percentage}%`}
          strokeWidth={12}
          styles={buildStyles({
            textSize: '22px',
            textColor: '#000',
            pathColor: item.pathcolor,
            trailColor: item.trailcolor,
            
          })}
        /> 
</div>
    </div>


<div className=""><Bar options={options} data={chartDataObj(item)} />
<div className="text-right viewMoreScore mb-2" ><Link to = ''>View More</Link> </div>

</div>

    </div>

    
</>
        )
    })
}
   </div>
    </div>

               
{/* <div className="JobseekerScore-Detail">
  <div className="singleScoreDetailCard row justify-content-between">
   <div className="col-4">
   <h3>Professional Skills</h3>
    <div className="circleScore">
    {
      progresstype?.map((item)=> {

  return (
            <>           
<div className="col px-2">
    <div className='additional-progressbar' style={{ width: '50px', height: '50px', position: 'relative' , margin: "0 auto"}}>       
        <CircularProgressbar  className="multiple-cricularbar thick-stroke"
          value= {item.percentage}
          text={`${item.percentage}%`}
          strokeWidth={12}
          styles={buildStyles({
            textSize: '22px',
            textColor: '#000',
            pathColor: item.pathcolor,
            trailColor: item.trailcolor,
            
          })}
        /> 
</div>
<h5 className='text-center userProgress mt-2'>{item.name} </h5>
    </div>
    <Bar options={options} data={chartDataObj(item)} />
</>
        )
    })
}
    </div>
   </div>

  </div>
</div> */}


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return { employee: state.employee, data: state.data };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestAdminCandidateDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShowCandidate);

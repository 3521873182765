import React, { useCallback, useState , useEffect} from 'react';
import { useDropzone } from 'react-dropzone';

const ResumeUpload = ({ onFileUpload , onFileDelete }) => {

  const [uploadedFile, setUploadedFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    onFileUpload(file);
    setUploadedFile(file);
  }, [onFileUpload]);


  

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="row">
      <div className="col-12 pe-0">
        {/* <p className='mb-1'>If you already have a resume, you can upload it here</p> */}
        {/* <h4 className='mb-1 p-16 '>Upload Resume</h4> */}
        <p>If you already have a resume, you can upload it here</p>
        <div {...getRootProps()} className="resumeupload dropzone">
          <input {...getInputProps()} />
          {uploadedFile ? (
            <div className="row">
              <div className="col-6">
                <p className='mb-0 p-14'>{uploadedFile.name}</p>
              </div>
              <div className="col-6">
                
                <div className=''>
                  <p className='mb-0 font-14 '>Support format: Pdf</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-6">
                <p className='mb-0 p-14 '>Upload your resume</p>
              </div>
              <div className="col-6">
                <div class="button  mb-2">
                  <button type="submit" class="btn small">Upload resume</button>
                </div>
                <div className=''>
                  <p className='mb-0  font-14'>Support format: Pdf</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResumeUpload;

import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { BsCameraVideo } from 'react-icons/bs';

function VideoUpload({ onVideoUpload }) {
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    // Check if the uploaded file is a video
    if (file && file.type.startsWith('video/')) {
      setUploadedVideo(file);
      onVideoUpload(file);
      setAlertMessage('');
    } else {
      setAlertMessage('Please upload a valid video file (MP4, M4V, etc.).');
    }
  }, [onVideoUpload]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'video/mp4,video/x-m4v,video/*',
    multiple: false,
  });

  return (
    <>
      <div className="partner-component upperline">
        <div className="row">
          <div className="col-2  w-auto">
            <div className="partner-icon">
              <BsCameraVideo/>
            </div>
          </div>
          <div className="col-10 ps-0">
            <div className="partner-description">
              <p>
                A Personality Score is valuable as it provides insights into your behavioural traits, helping employers assess cultural fit and teamwork. It complements qualifications and skills, aiding in the selection process. A good Personality Score enhances your job prospects and aligns your candidature with roles that suit your temperament.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-2 w-auto px-4"> </div>
        <div className="col-10">
          <div {...getRootProps()} className="video-upload">
            <input {...getInputProps()} accept="video/mp4,video/x-m4v,video/*" capture="user" />
            {uploadedVideo ? (
              <p>Video uploaded: {uploadedVideo.name}</p>
            ) : (
              <>
                <div className="row">
                  <div className="col-6">
                    <p className='mb-0 p-14'>Upload your video pitch</p>
                  </div>
                  <div className="col-6">
                    <div className="button  mb-1">
                      <button type="submit" className="btn small">Upload Video</button>
                    </div>
                    <div className=''>
                      <p className='mb-0'>Support format: MP4, AVI Upload  Maxi duration 2 min</p>
                    </div>
                  </div>
                </div>
                {alertMessage && <p style={{ color: 'red' }}>{alertMessage}</p>}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoUpload;
